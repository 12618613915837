import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { backend_url } from "../../server";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";

import styles from "../../styles/styles";
import { gitAllShipings } from "../../redux/actions/shipings";
function Shiping() {
  const { shipings, isLoading } = useSelector((state) => state.shipings);

  const { currentColor, currentDir } = useStateContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(gitAllShipings());
  }, []);

  const columns = [
    {
      field: "shipingNumber",
      headerName: <span className="h1ar text-sm">{t("Id")}</span>,
      minWidth: 150,
      flex: 0.7,
    },

    {
      field: "EnglishTitle",
      headerName: <span className="h1ar text-sm">{t("English Title")}</span>,
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "ArabicTitle",
      headerName: <span className="h1ar text-sm">{t("Arabic Title")}</span>,
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "amount",
      headerName: <span className="h1ar text-sm">{t("amount")}</span>,
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: <span className="h1ar text-sm">{t("Edit")}</span>,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Link to={`/AdminEditShiping/${params.id}`}>
              <Button style={{ background: "#ff8842" }}>
                <AiOutlineEdit style={{ color: "#ffffff" }} size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  const row = [];

  shipings &&
    shipings.forEach((item) => {
      row.push({
        shipingNumber: item.shipingNumber.toString(),
        EnglishTitle: item.titleEn,
        ArabicTitle: item.titleAr,
        amount: item.shipingAmount,
        id: item.shipingNumber,
      });
    });
  const theme = createTheme({
    direction: currentDir,
  });
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <frameElement className="flex-col w-full items-center px-8 pt-14">
          <div className="flex items-center mx-8 mt-4 "></div>
          <br />{" "}
          <ThemeProvider theme={theme}>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
              <DataGrid
                rows={row}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                initialState={{
                  ...row.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </ThemeProvider>
        </frameElement>
      )}
    </>
  );
}

export default Shiping;
