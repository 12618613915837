import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";

import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import { backend_url } from "../../server";
import { AiOutlineEye } from "react-icons/ai";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
const Selleractivedata = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [activedata, setActivedata] = useState();
  const [activedataStatus,setActivedataStatus] = useState('pending');
  const {currentColor,currentDir}=useStateContext();
  useEffect(() => {
    axios
      .get(`${server}/activedata/get-all-active-data`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.activedatas);
      
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);
 
  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName: "activedata Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Shop Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "shopId",
      headerName: "Shop Id",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "CommercialRegister",
      headerName: "CommercialRegister",
      type:"file",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName: "status",
      type: "text",
      minWidth: 80,
      flex: 0.5,
      renderCell: (params) => {
        const statusColor = params.value === "pending" ? "#ef1d2d" : "#1def68";
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
        };
        return (
          <span>
          <span style={statusStyle}></span>
          <span style={{ fontWeight: "bold" }}>{params.value}</span>
        </span>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Request given at",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },

    {
      field: "Preview",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/activedata/${params.id}`}>
              <Button className="rounded-full" 
              style={{background:currentColor,
        
                
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  
  ];

  const handleSubmit = async () => {
    await axios
      .put(`${server}/activedata/update-sellerstatus-request/${activedata.id}`,{
        sellerId:activedata.shop.id,
      },{withCredentials: true})
      .then((res) => {
        toast.success("Withdraw request updated successfully!");
        setData(res.data.activedata);
        setOpen(false);
      });
  };

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        shopId: item.shop._id,
        name: item.shop.name,
        CommercialRegister:`${backend_url}${item.pdf}`,
        status: item.status,
        createdAt: item.createdAt?.slice(0, 10),
      });
    });
  return (
    <div className="w-full flex items-center pt-5 justify-center">
<frameElement className="flex-col w-full items-center px-8 pt-14">
    <ThemeProvider theme={theme}>

<div  className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider></frameElement>
      {open && (
        <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
          <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h1 className="text-[25px] text-center font-Poppins">
            update shop state
            </h1>
            <br />
            <select
              name=""
              id=""
              onChange={(e) =>setActivedataStatus(e.target.value)}
              className="w-[200px] h-[35px] border rounded"
            >
              <option value={activedataStatus}>{activedata.status}</option>
              <option value={activedataStatus}>active</option>
            </select>
            <button
              type="submit"
              className={`block ${styles.button} text-white !h-[42px] mt-4 text-[18px]`}
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Selleractivedata;
