import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { backend_url, server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {loadSeller} from "../../redux/actions/user"
import { useStateContext } from "../../contexts/ContextProvider";
import Spinner from "../Charts/Spinner";

const OrderDetails = () => {
  const [isClickedButton, setIsClickedButton] = useState(false);
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { currentColor } = useStateContext();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    
    dispatch(getAllOrdersOfShop(seller._id));
    const data = orders && orders.find((item) => item.orderNumber == id);
  }, [status,seller]);

  const data = orders && orders.find((item) => item.orderNumber == id);
const orderId =data?._id
  const orderUpdateHandler = async (e) => {
    setIsClickedButton(true);
    await axios
      .put(
        `${server}/order/update-order-status/${orderId}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(<span className="h1ar text-sm">{t("Order status updated success")}</span>);
        navigate("/dashboard-orders");
        setIsClickedButton(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsClickedButton(false);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/order-refund-success/${orderId}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Order updated!");
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const InvoiceHandelr=()=>{
    navigate(`/Invoice/${orderId}`);
  }

  return (
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 h0ar text-[25px]">{t("Order Details")}</h1>
        </div>
        <Link to="/dashboard-orders">
          <div
            className={`${styles.button} text-[#fff] font-[600] !h-[45px] text-[18px]`}
            style={{ background: currentColor }}
          >
            {t("Order List")}
          </div>
        </Link>
      </div>

      <div className="w-full flex-wrap items-center lg:justify-between pt-6">
        <h5 className="text-sm par text-gray-800 ">
          {t("Order ID")}: <span>{data?.orderNumber}</span>
        </h5>
        <br />
        <h5 className="text-sm par text-gray-800 ">
          {t("Placed on")}: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* order items */}
      <br />
      <br />
      {data &&
        data?.cart.map((item, index) => (
          <div className="w-full flex flex-col items-center mb-5">
            <div className="w-full flex flex-row items-center mb-5">
              <img
                src={`${backend_url}/${item.images[0]}`}
                alt=""
                className="w-[80x] mx-4 h-[80px]"
              />
              <div className="flex-col ml-3">
                <h5 className="h1ar text-md">{item.name}</h5>
                <h5 className="text-md h2ar text-gray-600">
                  {item.discountPrice} {t("SAR")} * ({item.qty})
                </h5>
              </div>
            </div>
            <div className="flex-col by-2  800px:w-[50%] w-full rounded-md p-5 m-5">
              <h1 className="h1ar mx-4 text-md rtl:text-right ltr:text-left text-gray-900 dark:text-gray-200">
                {t("Colors")}
              </h1>
              <div className="mt-1 grid grid-cols-3 gap-1 md:grid-cols-3 md:gap-[5px] lg:grid-cols-4 800px:gap-2 lg:gap-2 xl:grid-cols-4 xl:gap-4 mb-1">
                {item.selectedColors?.map((color, index) => (
                  <div
                    key={index}
                    className="items-center justify-center align-center p-x2 flex-col relative"
                  >
                    <div
                      className="px-0.5 items-center border border-dashed py-1 rounded-lg"
                      style={{
                        overflow: "hidden",
                        height: "fit-content",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <h1 className="h2ar text-sm text-gray-600 dark:text-gray-200 text-center">
                        {color}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
              <h1 className="h1ar mx-4 text-md rtl:text-right ltr:text-left text-gray-900 dark:text-gray-200">
                {t("Sizes")}
              </h1>
              <div className="mt-1 grid grid-cols-3 gap-1 md:grid-cols-3 md:gap-[5px] lg:grid-cols-4 800px:gap-2 lg:gap-2 xl:grid-cols-4 xl:gap-4 mb-1">
                {item.selectedSizes?.map((size, index) => (
                  <div
                    key={index}
                    className="items-center justify-center align-center p-x2 flex-col relative"
                  >
                    <div
                      className="px-0.5 items-center border border-dashed py-1 rounded-lg"
                      style={{
                        overflow: "hidden",
                        height: "fit-content",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <h1 className="h2ar text-sm text-gray-600 dark:text-gray-200 text-center">
                        {size}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      <div className="border-t w-full ">
        <h5 className="pt-3 text-xl h1ar  ">
          {t("Total Price")}:{" "}
          <span className="text-md h2ar  ">
            {data?.totalPrice.toFixed(2)} {t("SAR")}
          </span>
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full 800px:flex items-center">
        <div className="w-full 800px:w-[60%]">
          <h4
            className="pt-3  text-2xl h1ar "
            style={{
              color: currentColor,
            }}
          >
            {t("Shipping Infrmation")}:
          </h4>

          <h4 className="pt-3 h1ar text-xl">
            {t("Country")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.shippingAddress.country}
            </span>
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("City")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.shippingAddress.city}
            </span>
          </h4>

          <h4 className="pt-3 h1ar text-xl">
            {" "}
            {t("Phone Number")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.user?.phoneNumber}
            </span>
          </h4>
                    <h4 className="pt-3 h1ar text-xl">
            {t("Street Name")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {
                " " +
                data?.shippingAddress.street}
            </span>{" "}
          </h4>
                              <h4 className="pt-3 h1ar text-xl">
            {t("Building name/number")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {
                " " +
                data?.shippingAddress.building}
            </span>{" "}
          </h4>
                              <h4 className="pt-3 h1ar text-xl">
            {t("Nearest place")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {
                " " +
                data?.shippingAddress.nearestTeacher}
            </span>{" "}
          </h4>
                              <h4 className="pt-3 h1ar text-xl">
            {t("delivery instructions")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {
                " " +
                t(data?.shippingAddress.addressType)}
            </span>{" "}
          </h4>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4
            className="pt-3  text-2xl h1ar "
            style={{
              color: currentColor,
            }}
          >
            {t("Payment Info")}:
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {" "}
            {t("Payment Type")}:{" "}
            <span className="text-md h2ar text-gray-800 ">
              {t(data?.paymentInfo?.type)}
            </span>
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("Status")}:{" "}
            <span className="text-md text-green-500 h2ar  ">
              {data?.paymentInfo?.status ? (
                t(data?.paymentInfo?.status)
              ) : (
                <span className="text-red-500"> {t("Not Paid")}</span>
              )}
            </span>
          </h4>
        </div>
      </div>
      <br />
      <br />
      <h4
        className="pt-3  text-2xl h1ar "
        style={{
          color: currentColor,
        }}
      >
        {t("Order Status")}:
      </h4>
      {data?.status !== "Processing refund" &&
        data?.status !== "Refund Success" && (
          <select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className="w-[200px] mt-2 border h-[35px] h2ar rounded-[5px]"
          >
            {[
              "Processing",
              "Transferred to delivery partner",
            ]
              .slice(
                [
                  "Processing",
                  "Transferred to delivery partner",
                ].indexOf(data?.status)
              )
              .map((option, index) => (
                <option className="h2ar" value={option} key={index}>
                  {t(option)}
                </option>
              ))}
          </select>
        )}
      {data?.status === "Processing refund" ||
      data?.status === "Refund Success" ? (
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
        >
          {["Processing refund", "Refund Success"]
            .slice(
              ["Processing refund", "Refund Success"].indexOf(data?.status)
            )
            .map((option, index) => (
              <option value={option} key={index}>
                {option}
              </option>
            ))}
        </select>
      ) : null}
      {isClickedButton ? (
        <div className="mt-5 mx-3 text-[#fff] font-[600] !h-[45px] text-[18px">
          <Spinner word={t("Update Status")} />
        </div>
      ) : (
        <button
          className={`${styles.Spinner} mt-5  text-[#fff] mx-3 font-[600] !h-[45px] text-[18px]`}
          onClick={
            data?.status !== "Processing refund"
              ? orderUpdateHandler
              : refundOrderUpdateHandler
          }
          style={{ background: currentColor }}
        >
          {t("Update Status")}
        </button>
      )}
              <button
          className={`${styles.Spinner} mt-5  text-[#fff] mx-3 font-[600] !h-[45px] text-[18px]`}
          onClick={
            InvoiceHandelr
          }
          style={{ background: currentColor }}
        >
          {t("Invoice")}
        </button>
    </div>
  );
};

export default OrderDetails;
