import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  isLoading: true,
};
export const withdrawReducer = createReducer(initialState, {
  getWithdrawDetailsRequest: (state) => {
    state.isLoading = true;
  },
  getWithdrawDetailsSuccess: (state, action) => {
    state.isLoading = false;
    state.withdraw = action.payload;
  },
  getWithdrawDetailsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});

