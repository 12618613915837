import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingc: false,
  isLoadingCreate: false,
};
export const categoriesReducer = createReducer(initialState, {
  CategoriesCreateRequest: (state) => {
    state.isLoadingCreate = true;
  },
  CategoreisCreateSuccess: (state, action) => {
    state.isLoadingCreate = false;
    state.categorie = action.payload;
    state.successcreate = true;
  },
  CategoreisCreateFail: (state, action) => {
    state.isLoadingCreate = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  categorieCreateCleare: (state) => {
    state.successcreate = false;
    state.errorcreate = false;
    state.isLoadingCreate = false;
  },
  deleteCategoreiRequest: (state) => {
    state.isLoading = true;
  },
  deleteCategoreiSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.successdelete = true;
  },
  deleteCategoreiFailed: (state, action) => {
    state.isLoading = false;
    state.errordelete = action.payload;
    state.successdelete = false;
  },
  getAllCategoriesRequest: (state) => {
    state.isLoading = true;
  },
  getAllCategoriesSuccess: (state, action) => {
    state.isLoading = false;
    state.categories = action.payload;
  },
  getAllCategoriesFailed: (state, action) => {
    state.isLoading = false;
  },
  getCategorieRequest: (state) => {
    state.isLoadingc = true;
  },
  getCategorieSuccess: (state, action) => {
    state.isLoadingc = false;
    state.categorie = action.payload;
  },
  getCategorieFailed: (state, action) => {
    state.isLoadingc = false;
  },
});
