import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect,useRef,useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteEvent, getAllEventsShop } from "../../redux/actions/event";
import {useReactToPrint} from "react-to-print";
import {CSVLink} from "react-csv";
import { useStateContext } from "../../contexts/ContextProvider";
import Loader from "../Layout/Loader";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const AllEvents = () => {
const [isDeleting, setIsDeleting] = useState(false);
const { success, error } = useSelector((state) => state.events);
  const { events, isLoading } = useSelector((state) => state.events);
  const { seller } = useSelector((state) => state.seller);
  const componentPDF =useRef();
  const {currentColor,currentDir}=useStateContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEventsShop(seller._id));
  }, [dispatch]);
 
  const theme = createTheme({
    direction:currentDir
    });
useEffect(() => {



    if (success) {

      window.location.reload();

    }

  }, [dispatch, error, success]);
  const handleDelete = async (id) => {

  if (isDeleting) {

    return; // إذا تم الضغط بالفعل على زر الحذف، لا تفعل أي شيء

  }



  try {

    setIsDeleting(true); // تعيين حالة الحذف إلى true



    await dispatch(deleteEvent(id));
dispatch(getAllEventsShop(seller._id));

  } catch (error) {

    // معالجة الخطأ هنا

  } finally {

    setIsDeleting(false); // تعيين حالة الحذف إلى false بعد الانتهاء من الحذف

  }

};

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: "Price",
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: "Stock",
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: "Sold out",
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      type: "number",
      sortable: false,
      
   renderCell: (params) => {
        const d = params.row.id;
       console.log(d)
        return (
          <>
            <Link to={`/product/${d}?isEvent=true`}>
            <Button className="rounded-full" 
              style={{background:currentColor,
        
                
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
          <Button style={{background:"#ff0000",
        }} onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete style={{color:"#ffffff"}} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const genratePDF=useReactToPrint({
    content:()=>componentPDF.current,
  documentTitle:"AllEvents",
  onAfterPrint:()=>alert("Data saved in PDF")
  
  });

  const row = [];

  events &&
  events.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price:"SAR"+ item.discountPrice,
        Stock: item.stock,
        sold: item.sold_out,
      });
    });


  return (
    <>
      {isLoading ? (
        <Loader />
      ) :  (<frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className="flex items-center mx-8 mt-4 ">
        <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
        onClick={genratePDF}
        style={{background:currentColor}}
        > 
PDF
        </button>
        <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
         style={{background:currentColor}}
        >
       {events?(
        <CSVLink
        data={events}
       
        >
Exel
        </CSVLink>):(null)}
        </div>
        </div>
        <br />
        <ThemeProvider theme={theme}>

<div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider></frameElement>
      )}
    </>
  );
};

export default AllEvents;
