import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import {  AiOutlineEye } from "react-icons/ai";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";

const AllRefundOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const {currentColor,currentDir}=useStateContext();
  const dispatch = useDispatch();
const{t}=useTranslation()
  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const refundOrders = orders && orders.filter((item) => item.status === "Processing refund"  || item.status === "Refund Success");
 
  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: t("Status"),
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor = params.value === "Delivered" ? "#1def68" : "#ff8842" && params.value === "Processing"?"#ef1d2d":"#ff8842" ;
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
          marginRight:6,
          marginLeft:6
        };
        return (
          <span>
          <span style={statusStyle}></span>
          <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
        </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: t("Preview"),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/order/${params.id}`}>
            <Button className="rounded-full" 
              style={{background:currentColor,
        
                
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  refundOrders &&
  refundOrders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.length,
        total: "SAR" + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2">
        <ThemeProvider theme={theme}>

<div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <DataGrid
            rows={row}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            initialState={{
              ...row.initialState,
              pagination: { paginationModel: { pageSize:10 } },
            }}
            pageSizeOptions={[10, 20, 50]}
             />
        
        </div></ThemeProvider></div></frameElement>
      )}
    </>
  );
};

export default AllRefundOrders;

