import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllUsers } from "../../redux/actions/user";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete } from "react-icons/ai";
import { Button } from "@material-ui/core";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { backend_url, server } from "../../server";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {CSVLink} from "react-csv";
import {useReactToPrint} from "react-to-print";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
const AllUsers = () => {
  const dispatch = useDispatch();
  const {t}=useTranslation();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const componentPDF =useRef();
  const {currentColor,currentDir}=useStateContext();
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
    .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
    .then((res) => {
      toast.success(res.data.message);
    });

  dispatch(getAllUsers());
  };
 
  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName: t("User ID"), minWidth: 150, flex: 0.7 },
{
  field: "image",
  headerName: t("Image"),
  minWidth: 150,
  flex: 0.7,
  renderCell: (params) => {
    return (
  <div
      style={{
        borderRadius:"50%",
        padding:"10px",
        width: "65px",
       overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
        height:"65px"}}
      
      >
      <img
          src={params.value}
          alt="User Image"
          style={{
            height: "100%",
            width: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
            borderRadius: "50%",
          }}
        />
      </div>
    );
  },
},
    {
      field: "name",
      headerName: t("Name"),
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: t("Email"),
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "role",
      headerName: t("User Role"),
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "joinedAt",
      headerName: t("joined At"),
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: t("Delete User"),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button  style={{background:"#ff0000",
        }} onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete  style={{color:"#fff",
        }}  size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  if (users) {
    users.forEach((item) => {
      row.push({
        id: item._id,
        image:`${backend_url}${item.avatar}`,
        name: item.name,
        email: item.email,
        role: item.role,
        joinedAt: item.createdAt ? item.createdAt.slice(0, 10) : '',
      });
    });
  }

  const genratePDF=useReactToPrint({
    content:()=>componentPDF.current,
      documentTitle:"AllUsers",
      onAfterPrint:()=>alert("Data saved in PDF")  });

  return (
    <>
    <frameElement className="flex-col w-full items-center px-8 pt-14">
  <div className="flex items-center mx-8 mt-4 ">
  <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
  onClick={genratePDF}
  style={{background:currentColor}}
  > 
PDF
  </button>
  <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
   style={{background:currentColor}}
  >
    {users?(
  <CSVLink
  data={users}>
Exel
  </CSVLink>):(null)}
  </div>
  </div>
  <br />
  <ThemeProvider theme={theme}>

<div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider>
    {open && (
  <div className="w-full fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen">
    <div className="w-[95%] 800px:w-[40%] min-h-[20vh] bg-white rounded-lg shadow p-5">
      <div className="w-full flex justify-end cursor-pointer">
        <RxCross1 size={25} onClick={() => setOpen(false)} />
      </div>
      <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
        Are you sure you wanna delete this user?
      </h3>
      <div className="w-full flex items-center justify-center">
        <div
          className={`${styles.button} bg-green-500  text-white text-[18px] !h-[42px] mr-4`}
          onClick={() => setOpen(false)}
        >
          cancel
        </div>
        <div
          className={`${styles.button} bg-red-500 text-white text-[18px] !h-[42px] ml-4`}
          onClick={() =>  setOpen(false) || handleDelete(userId)}
        >
          confirm
        </div>
      </div>
    </div>
  </div>
)}

    
    
    </frameElement>
</>
);
    }

export default AllUsers;