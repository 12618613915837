import React, { useState, useEffect } from "react";
import Slider from "./slider/Slider";
import styles from "../../styles/styles";
import MobilSlider from "./slider/MobilSlider";

const Hero = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // تحديث عرض الشاشة عندما يتغير حجم الشاشة
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // إضافة مستمع لحجم الشاشة
    window.addEventListener("resize", handleResize);

    // إزالة المستمع عندما يتم تفكيك المكون
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    
    <div className={`relative  min-h-[30vh] 800px:min-h-[80vh] w-full bg-no-repeat ${styles.noramlFlex}`}>
    
      {windowWidth >= 800 ? <Slider /> : <MobilSlider/>
      }
    </div>
  );
};

export default Hero;