import React, { useEffect, useState } from "react";
import styles from "../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { backend_url, server } from "../server";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../contexts/ContextProvider";
import ThemeSettingsButton from "../components/ThemeSettingsButton";


const AdminActiveDataDetails = () => {
const [data,setData]=useState()
  const {currentColor}=useStateContext();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
const{t}=useTranslation()
const { id } = useParams();

  useEffect(() => {
    axios
      .get(`${server}/activedata/get-active-data/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.activedata);
      
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);
console.log(data)

  const shopUpdateHandler = async (e) => {
  
    await axios
      .put(
        `${server}/activedata/update-activedata-status/${id}`,
        {
          status:status
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(res.message);
     
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };
  const pdfFile=data?.pdf
  const showpdf=()=>{
  
window.open(`${backend_url}/${pdfFile}`)
  }
  console.log(data?.status);


  return (<><ThemeSettingsButton/>
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
        <div className="flex flex-col items-center mt-6 -mx-2">
        <img className="object-cover w-24 h-24 mx-2 rounded-full" src={`${backend_url}${data?.shop.avatar}`} alt="avatar"/>
        <h4 className="mx-2 mt-2 font-medium text-gray-800 dark:text-gray-200">{data?.shop.name}</h4>
        <p className="mx-2 mt-1 text-sm font-medium text-gray-600 dark:text-gray-400">{data?.shop.email}</p>
    </div>
        </div>
        <Link to="/admin-Seller-active">
          <div
            className={`${styles.button} text-[#fff] font-[600] !h-[45px] text-[18px]`}
            style={{background:currentColor}}
          >
           {t("Seller List")}
          </div>
        </Link>
      </div>

      <div className="w-full flex items-center justify-between pt-6">
        <h5 className="text-[#00000084]">
          {t("active order")}: <span>#{data?._id?.slice(0, 8)}</span>
        </h5>
        <h5 className="text-[#00000084] rtl:h1ar">
          {t("Placed on")}: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* order items */}
 

      <div className="border-t w-full text-right">
        <h5 className="pt-3 text-[18px] rtl:h1ar  ">
 
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full 800px:flex items-center">

        <div className="flex gap-5 flex-wrap lg:flex-nowrap w-full ">
          <div className="flex gap-2 flex-wrap lg:flex-nowrap">
            
            <h3 className="text-[22px] font-bold text-gray-400 pb-2">{t("Peronal id")}:</h3>
          
          <h4 className="text-[22px] dark:text-gray-400 text-bold">{`${data?.personalId}`}</h4>
          </div>
        

          <div className="flex gap-2 flex-wrap lg:flex-nowrap">
        <h3 className=" text-[22px] font-bold text-gray-400 pb-2">{t("Commercial Register")}:</h3>
        <h4 className="text-[22px] dark:text-gray-400 text-bold">{`${data?.pdf}`}</h4>
          <div
            className={`w-[150px] rtl:h1ar flex items-center justify-center rounded-xl cursor-pointer text-[#fff] font-[600] !h-[45px] text-[18px]`}
            style={{background:currentColor}}
            onClick={showpdf}
          >
           {t("Download pdf file")}
          </div>
          </div>
        </div>
        
      </div>
      <br />
      <br />
      <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 text-[20px] h0ar ">{t("Shop info")}:</h4>
          <h4 className="h1ar">
            {t("Status")}:{" "}
       {  t(data?.shop.status) }
          </h4>
        </div>
      <h4 className="pt-3 text-[20px] font-[600] rtl:h0ar ">{t("Update Shope Status")}:</h4>
   
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="w-[200px] mt-2 border h-[35px] rounded-[5px]"
        >
          {[
            "pending",
            "active",
          
          ]
            .slice(
              [
                "pending",
                "active",
              ].indexOf(data?.status)
            )
            .map((option, index) => (
              <option value={option} key={index}>
                {t(option)}
              </option>
            ))}
        </select>
 
    

      <div
        className={`${styles.button} mt-5  text-[#fff] font-[600] !h-[45px] text-[18px]`}
        onClick={data?.status !== " " ? shopUpdateHandler :null}
        style={{background:currentColor}}
      >
        {t("Update Status")}
      </div>
    </div></>
  );
};

export default AdminActiveDataDetails;
