import React, { useState, useEffect } from "react";
import "./Slider.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllHeroImage } from "../../../redux/actions/heroImage";
import { backend_url } from "../../../server";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import { useStateContext } from "../../../contexts/ContextProvider";
const Slider = () => {
  const { t } = useTranslation();
  const { currentDir } = useStateContext();
  const { heroImages, isLoading } = useSelector((state) => state.heroImages);
  const [currentSlid, setCurrentSlid] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllHeroImage());
  }, []);
  console.log("heroImaages", heroImages);
  const sliderLength = heroImages?.length;
  const autoScroll = true;
  let slideInterval;
  const intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlid(currentSlid === sliderLength - 1 ? 0 : currentSlid + 1);
  };
  const prevSlide = () => {
    setCurrentSlid(currentSlid === 0 ? sliderLength - 1 : currentSlid - 1);
  };

  useEffect(() => {
    setCurrentSlid(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlid, intervalTime]);

  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <div clasName="Slider">
          <AiOutlineArrowRight className="next arrow" onClick={nextSlide} />
          <AiOutlineArrowLeft className="prev arrow " onClick={prevSlide} />
          {heroImages &&
            heroImages.map((slide, index) => {
              const { image, titleAr, titleEn, descAr, descEn } = slide;
              return (
                <div
                  key={index}
                  className={index === currentSlid ? "slide current" : "slide"}
                >
                  {index === currentSlid && (
                    <>
                      <img
                        src={`${backend_url}${image}`}
                        className="h-full w-full"
                        alt="slid"
                      />
                      <div className="content ">
                        <span className="span1"></span>
                        <span className="span2"></span>
                        <span className="span3"></span>
                        <span className="span4"></span>
                        <h2 className="lg:text-6xl text-xl h2ar">
                          {currentDir == "ltr" ? titleEn : titleAr}
                        </h2>
                        <p className="par text-xl">
                          {currentDir == "ltr" ? descEn : descAr}
                        </p>
                        <hr />
                        <button
                          className=" --btn --btn-primary px-4 py-2 h1ar "
                          onClick={() => {
                            navigate("/shop");
                          }}
                        >
                          {t("shopnow")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};
export default Slider;
