import React, { useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { brandingData } from "../../../static/data";
import styles from "../../../styles/styles";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import { useSelector, useDispatch } from "react-redux";
import { backend_url } from "../../../server";
import { getAllSellersForHome } from "../../../redux/actions/sellers";
import { useStateContext } from "../../../contexts/ContextProvider";
import Loader from "../../Layout/Loader";
const Categories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { categories, isLoading } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const { sellers } = useSelector((state) => state.seller);
  const handleScrollLeft = () => {
    scrollContainerRef.current.scrollLeft -= 200; // تغيير قيمة السكرول لليسار
  };
  const { currentDir } = useStateContext();
  console.log(sellers);
  const handleScrollRight = () => {
    scrollContainerRef.current.scrollLeft += 200; // تغيير قيمة السكرول لليمين
  };
  useEffect(() => {
    dispatch(getAllSellersForHome());
  }, [dispatch]);
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={`${styles.section} hidden sm:block`}>
            <div
              className={
                "branding my-12 flex justify-between w-full dark:shadow-gray-200 shadow-sm bg-white p-5 rounded-md"
              }
            >
              {brandingData &&
                brandingData.map((i, index) => (
                  <div className="flex items-start" key={index}>
                    <img src={i.icon} alt="" />
                    <div className="px-3">
                      <h3 className="font-bold text-sm md:text-base h1ar">
                        {t(i.title)}
                      </h3>
                      <p className="text-xs md:text-sm par">
                        {t(i.Description)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex dark:bg-main-dark-bg flex-col bg-main-bg m-auto p-auto">
            <h1 className="flex py-5 text-center font-bold text-xl 800px:text-4xl px-3 text-gray-800 dark:text-gray-400">
              {t("Categories")}
            </h1>
            <div
              className="flex overflow-x-scroll pb-5 hide-scroll-bar"
              ref={scrollContainerRef}
            >
              <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 items-center ">
                {categories &&
                  categories.map((i) => {
                    const handleSubmit = (i) => {
                      navigate(`/category-products?category=${i.title}`);
                    };
                    return (
                      <div className="inline-block px-3">
                        <div
                          class="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out"
                          key={i._id}
                          onClick={() => handleSubmit(i)}
                        >
                          <h5
                            className={
                              "text-[18px] leading-[1.3] mb-4 h1ar text-center"
                            }
                          >
                            {currentDir == "ltr" ? i.titleEn : i.titleAr}
                          </h5>
                          <img
                            src={`${backend_url}${i.image}`}
                            className="w-full object-cover rounded-md"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}

                {/* زر اليسار */}

                {window.innerWidth >= 800 && (
                  <button
                    className="absolute  left-4 transform -translate-y-1/2  --btn-primary rounded-full  p-2"
                    onClick={handleScrollLeft}
                  >
                    <IoIosArrowBack />
                  </button>
                )}
                {window.innerWidth >= 800 && (
                  <button
                    className="absolute  right-4 transform -translate-y-[50%] --btn-primary  rounded-full p-2"
                    onClick={handleScrollRight}
                  >
                    <IoIosArrowForward />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Categories;
