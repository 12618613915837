import React, { useEffect,useRef } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order"
import {CSVLink} from "react-csv";
import {useReactToPrint} from "react-to-print";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
const AdminDashboardOrders = () => {
  const dispatch = useDispatch();
  const componentPDF =useRef();
  const {currentColor,currentDir}=useStateContext();
  const{t}=useTranslation()
  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, []);
 
  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName:  (<span className="h1ar text-sm">{t("Order ID")}</span>), minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName:  (<span className="h1ar text-sm">{t("Status")}</span>),
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor = params.value === "Delivered" ? "#1def68" : "#ff8842" && params.value === "Processing"?"#ef1d2d":"#ff8842" ;
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
          marginRight:6,
          marginLeft:6
        };
        return (
          <span>
          <span style={statusStyle}></span>
          <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
        </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName:  (<span className="h1ar text-sm">{t("Items Qty")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName:  (<span className="h1ar text-sm">{t("Total")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
        field: "createdAt",
        headerName:  (<span className="h1ar text-sm">{t("Order Date")}</span>),
        type: "number",
        minWidth: 130,
        flex: 0.8,
      },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      row.push({
        id: item.orderNumber,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + " RAS",
        status: item?.status,
        createdAt: item?.createdAt.slice(0,10),
      });
    });

    const genratePDF=useReactToPrint({
        content:()=>componentPDF.current,
          documentTitle:"AllOrders",
          onAfterPrint:()=>alert("Data saved in PDF")  });

  return (
   
     

    <>
    <frameElement className="flex-col w-full items-center px-8 pt-14">
  <div className="flex items-center mx-8 mt-4 ">
  <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
  onClick={genratePDF}
  style={{background:currentColor}}
  > 
PDF
  </button>
  <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
   style={{background:currentColor}}
  >
    {adminOrders?(
  <CSVLink
  data={adminOrders}>
Exel
  </CSVLink>):(null)}
  </div>
  </div>
  <br />
  <ThemeProvider theme={theme}>

<div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider></frameElement>
</>
  );
};

export default AdminDashboardOrders;
