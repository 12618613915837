import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from 'react-icons/md';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { backend_url, server } from "../../server";
import { Buttoon } from "../Charts"; 
import axios from "axios";
import { useStateContext } from '../../contexts/ContextProvider'; 
import { getAllProductsShop } from "../../redux/actions/product";
import { BsCurrencyDollar, BsShield } from "react-icons/bs";
import { FiCreditCard } from "react-icons/fi";

const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: 'My Profile',
    desc: 'Account Settings',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    
          
  
  },
  {
    icon: <BsShield />,
    title: 'My Inbox',
    desc: 'Messages & Emails',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
  },
  {
    icon: <FiCreditCard />,
    title: 'My Tasks',
    desc: 'To-do and Daily Tasks',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
  }
]

const AdminProfile = ({ isOwner }) => {
  const { user } = useSelector((state) => state.user);
  const [data,setData] = useState({});
  const {products} = useSelector((state) => state.products);
  const [isLoading,setIsLoading] = useState(false);
  const {id} = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(id));
    setIsLoading(true);
    axios.get(`${server}/shop/get-shop-info/${id}`).then((res) => {
     setData(res.data.shop);
     setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    })
  }, [])
  
  const { currentColor } = useStateContext();

  const logoutHandler = async () => {
    axios.get(`${server}/user/logout`,{
      withCredentials: true,
    });
    window.location.reload();
  };

  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200">Admin Profile</p>
        <Buttoon
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={`${backend_url}${user.avatar}`}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200">{`${user.name}`}</p>
          <p className="text-gray-500 text-sm dark:text-gray-400"> {`${user.phoneNumber}`} </p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400"> {`${user.email}`} </p>
        </div>
      </div>
      <div>
    
           <Link to={`/profile`}>
          <div className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
          
            <button
              type="button"
              style={{ color: '#03C9D7', backgroundColor:'#E5FAFB' }}
              className=" text-xl rounded-lg p-3 hover:bg-light-gray"
            >
            <BsCurrencyDollar />
            </button>
           
            <div>
              <p className="font-semibold dark:text-gray-200 ">My PROFIL</p>
              <p className="text-gray-500 text-sm dark:text-gray-400"> MORE ABOUTE MY PROFIL</p>
            </div>
          </div>
          </Link>
       
      </div>

      <div>
    
   

   <div>
    
 

</div>

</div>

      <div 
      onClick={logoutHandler}
      className="mt-5">
        <Buttoon
          color="white"
          bgColor={currentColor}
          text="Logout"
          borderRadius="10px"
          width="full"
        />
      </div>
    </div>

  );
};

export default AdminProfile;
