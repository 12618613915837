import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { useEffect } from "react";

import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

const PaymentCash = () => {
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const{t}=useTranslation();
  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);
console.log(orderData)
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "SAR",
              value: orderData?.totalPrice,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
   
  };



//fron


  


 

  const cashOnDeliveryHandler = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
       
        "Content-Type": "application/json",
       
      },
    };

    order.paymentInfo = {
      type: "Cash On Delivery",
    };

    await axios
    .post(`${server}/order/create-order`, order, config)
    .then((res) => {
      setOpen(false);
      navigate("/order/success");
      toast.success(<span className="par">{t("The order was created successfully")}</span>);
      localStorage.setItem("cartItems", JSON.stringify([]));
      localStorage.setItem("latestOrder", JSON.stringify([]));
      window.location.reload();
    });
  };

  return (
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
           
            createOrder={createOrder}
          
          
            cashOnDeliveryHandler={cashOnDeliveryHandler}
          />
        </div>
        <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
          <CartData orderData={orderData} />
        </div>
      </div>
    </div>
  );
};

const PaymentInfo = ({
  user,
  open,
  setOpen,
  onApprove,
  createOrder,
  cashOnDeliveryHandler,


}) => {

  const [select, setSelect] = useState(1);
  const{t}=useTranslation();
 
  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] p-5 pb-8 rounded-2xl shadow-lg ">
   
   
        <div className="flex-col w-full pb-5 border-b mb-2">
       
          
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            {t("Cash on Delivery")}
          </h4><br />
          <h4 className="text-[18px] pl-2 font-[600] text-[#000000b1]">
            {t("An additional fee of 17 SAR will be added to the shipping charges.")}
          </h4><br />
      
      


     
          <div className="w-full flex">
            <form className="w-full" onSubmit={cashOnDeliveryHandler}>
              <input
                type="submit"
                value={t("Confirm")}
                className={`${styles.button} !bg-[#f63b60] text-[#fff] h-[45px] rounded-[5px] cursor-pointer text-[18px] font-[600]`}
              />
            </form>
          </div>
    
      </div>
    </div>
  );
};

const CartData = ({ orderData }) => {
  const{t}=useTranslation();
  const shipping = orderData?.shipping?.toFixed(2);
  return (
    <div className="w-full bg-[#fff] rounded-lg shadow-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] h2ar text-[#000000a4]">{t("subtotal")}:</h3>
        <h5 className="text-[18px] font-[600]">{t("SAR")} {" "}{orderData?.subTotalPrice}</h5>
      </div>
      <br />
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] h2ar text-[#000000a4]">{t("shipping")}:</h3>
        <h5 className="text-[18px] font-[600]">{t("SAR")} {shipping}</h5>
      </div>
      <br />
      <div className="flex justify-between border-b  pb-3">
        <h3 className="text-[16px] font-[400] h2ar text-[#000000a4]">{t("Discount")}:</h3>
        <h5 className="text-[18px] h2ar font-[600]">{orderData?.discountPrice? t("SAR")+" " + orderData.discountPrice : "-"}</h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3">
      {t("SAR")} {" "}{orderData?.totalPrice}
      </h5>
      <br />
    </div>
  );
};


export default PaymentCash;