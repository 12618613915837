import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  isCategoryLoding: true,
  isSPLoading: false,
};

export const productReducer = createReducer(initialState, {
  productCreateRequest: (state) => {
    state.isLoading = true;
  },
  productCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.successcreate = true;
  },
  productCreateFail: (state, action) => {
    state.isLoading = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  productCreateClear: (state) => {
    state.successcreate = false;
    state.errorcreate = false;
  },
  // get product of shop

  getProductforShopRequest: (state) => {
    state.isSPLoading = true;
  },

  getProductforShopSuccess: (state, action) => {
    state.isSPLoading = false;

    state.shopProduct = action.payload;
  },

  getProductforShopFailed: (state, action) => {
    state.isSPLoading = false;

    state.error = action.payload;
  },

  //category

  categoryProductsRequest: (state) => {
    state.isCategoryLoding = true;
  },

  categoryProductsSuccess: (state, action) => {
    state.isCategoryLoding = false;

    state.categoryProducts = action.payload;

    state.totalPages = action.totalPages;
  },

  categoryProductsFailed: (state, action) => {
    state.isCategoryLoding = false;

    state.error = action.payload;
  },

  // get all products of shop
  getAllProductsShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllProductsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload;
  },
  getAllProductsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // delete product of a shop
  deleteProductRequest: (state) => {
    state.isLoading = true;
  },
  deleteProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.deleteSuccess = true;
  },
  deleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.deleteError = action.payload;
  },

  // delete product of a shop
  AdmindeleteProductRequest: (state) => {
    state.isLoading = true;
  },
  AdmindeleteProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.deleteSuccess = true;
  },
  AdmindeleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.deleteError = action.payload;
  },

  // get all products
  getAllProductsRequest: (state) => {
    state.isLoading = true;
  },
  getAllProductsSuccess: (state, action) => {
    state.isLoading = false;
    state.allProducts = action.payload;
  },
  getAllProductsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  // fillter
  filterProductsRequest: (state) => {
    state.isLoading = true;
  },
  filterProductsSuccess: (state, action) => {
    state.isLoading = false;
    state.filteredProducts = action.payload;
    state.totalPages = action.totalPages;
  },
  filterProductsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // best selling
  bestSillngRequest: (state) => {
    state.isLoading = true;
  },

  bestSillngSuccess: (state, action) => {
    state.isLoading = false;

    state.bestSellingProducts = action.payload;

    state.totalPages = action.totalPages;
  },

  bestSillngFailed: (state, action) => {
    state.isLoading = false;

    state.error = action.payload;
  },
  //featured products
  featuredproductsRequest: (state) => {
    state.isLoading = true;
  },

  featuredproductsSuccess: (state, action) => {
    state.isLoading = false;

    state.featuredProducts = action.payload;

    state.totalPages = action.totalPages;
  },

  featuredproductsFailed: (state, action) => {
    state.isLoading = false;

    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
