import React, { useState } from "react";
import {
  AiFillHeart,
  AiOutlineEye,
  AiOutlineHeart,
} from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";

import { Link } from "react-router-dom";
import { backend_url } from "../../../server";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import { useTranslation } from "react-i18next";
import { BsCartCheckFill } from "react-icons/bs";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { FaShoppingCart } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import Bottun from "../../Charts/Bottun"
const ProductCard = ({ data,isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const {t}=useTranslation(); 
useEffect(() => {
    if (cart && cart.find((i) => i._id === data._id)) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [cart]);
  
  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error(<span className="h1ar text-sm">{t("Item already in cart!")}</span>);
    } else {
      if (data.stock < 1) {
        toast.error(<span className="h1ar text-sm">{t("Product stock limited!")}</span>);
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success(<span className="h1ar text-sm">{t("Item added to cart successfully!")}</span>);
        setClicked(true);
      }
    }
  };

  return (

    <>
 <div className="w-full center   overflow-hidden bg-white rounded-lg shadow-lg dark:bg-secondary-dark-bg">
<div className=" relative">
 <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
 <img src={`${backend_url}${data.images && data.images[0]}`}  alt="" className="object-cover w-full h-48" />
</Link>

<div className="p-1 800px:p-2">
                  {window.innerWidth < 800 && (
        <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <h4 className=" text-sm dark:text-gray-200 h1ar">
            {data.name.length > 15 ? data.name.slice(0, 15) + "..." : data.name}
          </h4>
 </Link>)}
           {window.innerWidth > 800 && (
                   <Link to={`${isEvent === true ? `/product/${data._id}?isEvent=true` : `/product/${data._id}`}`}>
          <h4 className=" text-sm dark:text-gray-200 h1ar">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>
 </Link>
           
           )}
          <div className="flex py-1">
          <Ratings rating={data?.ratings} />
          </div>

          <div className=" flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
                {data.originalPrice === 0
                  ? data.originalPrice
                  : data.discountPrice}
                {t("SAR")}
              </h5>
              <h4 className={`${styles.price}`}>
 {data.originalPrice ? data.originalPrice + " " + t("SAR") : null}
              </h4>
            </div>
            
          </div>
{window.innerWidth >= 800 && (
    <button
      className={`cart-button${clicked ? " clicked" : ""}`}
      onClick={() => addToCartHandler(data._id)}
    >
      <span className="fas fa-shopping-cart"><FiShoppingCart /></span>
      <span className="fas fa-box"><FaBox/></span>

      <span className={`${clicked ? "added" : ""}`}>
        {clicked ? t("Added") : ""}
      </span>
      <span className="add-to-cart">
        {clicked ? "" : t("Add to cart")}
      </span>
    </button>)}
    
    </div>
        {/* side options */}
             <div className="dark:text-gray-200 text-gray-600">
                       {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute left-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : ""}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer  absolute left-2 top-5"
              color={click ? "red" : ""}
              onClick={() => addToWishlistHandler(data)}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer   absolute left-2 top-14"
            onClick={() => setOpen(!open)}
            
            title="Quick view"
          />
          {window.innerWidth < 800 && (
        <div className="cursor-pointer   absolute left-2 top-24">
    <button
      className={`cart-button-mobile${clicked ? " clicked" : ""}`}
      onClick={() => addToCartHandler(data._id)}
    >
      <span className="fas fa-shopping-cart"><FiShoppingCart /></span>
      <span className="fas fa-box"><FaBox/></span>
      <span className={`${clicked ? "added" : ""}`}>
        {clicked ? <BsCartCheckFill />: ""}
      </span>
      <span className="add-to-cart">
        {clicked ? "" : <FiShoppingCart />}
      </span>
    </button></div>)}
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
          </div>
          </div>  
      </div>
    </>
  );
};

export default ProductCard;
