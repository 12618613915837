import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { createevent } from "../../redux/actions/event";
import Spinner from "../Charts/Spinner";
import { useTranslation } from "react-i18next";
import { BsImages } from "react-icons/bs";

import { IoCloseSharp } from "react-icons/io5";
import { useStateContext } from "../../contexts/ContextProvider";
const CreateEvent = () => {
  const [imageShow, setImageShow] = useState([]);
  const { seller } = useSelector((state) => state.seller);
  const { t } = useTranslation();
  const [isSpinner, setIsSpinner] = useState(false);
  const { CreateSuccess, CreateError } = useSelector((state) => state.events);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentColor, currentDir } = useStateContext();
  const [images, setImages] = useState([]);
  const [sku, setSku] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const inmageHandle = (e) => {
    const files = e.target.files;

    const length = files.length;

    if (length > 0) {
      setImages([...images, ...files]);
      let imageUrl = [];

      for (let i = 0; i < length; i++) {
        imageUrl.push({ url: URL.createObjectURL(files[i]) });
      }
      setImageShow([...imageShow, ...imageUrl]);
    }
  };
  const changeImage = (img, index) => {
    if (img) {
      let tempUrl = imageShow;
      let tempImages = images;

      tempImages[index] = img;
      tempUrl[index] = { url: URL.createObjectURL(img) };
      setImageShow([...tempUrl]);
      setImages([...tempImages]);
    }
  };

  const removeImage = (i) => {
    const filterImage = images.filter((img, index) => index !== i);

    const filterImageUrl = imageShow.filter((img, index) => index !== i);
    setImages(filterImage);
    setImageShow(filterImageUrl);
  };

  const handleStartDateChange = (e) => {
    const startDate = new Date(e.target.value);
    const minEndDate = new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000);
    setStartDate(startDate);
    setEndDate(null);
    document.getElementById("end-date").min = minEndDate
      .toISOString()
      .slice(0, 10);
  };

  const handleEndDateChange = (e) => {
    const endDate = new Date(e.target.value);
    setEndDate(endDate);
  };

  const today = new Date().toISOString().slice(0, 10);

  const minEndDate = startDate
    ? new Date(startDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10)
    : "";

  useEffect(() => {
    if (CreateError) {
      toast.error(CreateError);
      setIsSpinner(false);
    }
    if (CreateSuccess) {
      toast.success(
        <span className="h1ar text-sm">{t("Event created successfully!")}</span>
      );
      setIsSpinner(false);
    }
  }, [CreateError, CreateSuccess]);

  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSpinner(true);
    const newForm = new FormData();

    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("sku", sku);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    newForm.append("start_Date", startDate.toISOString());
    newForm.append("Finish_Date", endDate.toISOString());
    dispatch(createevent(newForm));
  };
  const { categories } = useSelector((state) => state.categories);
  return (
    <div className="w-[90%] lg:w-[50%] m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h5 className="text-[30px] font-Poppins h1ar text-center">
        {t("Create Event")}
      </h5>
      {/* create event form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Name")} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder={t("Enter your event product name...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Event Description")} <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400  h2ar outline-none sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder={t("Enter your event product description...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Category")} <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px]  rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category" className="h1ar">
              {t("Choose a category")}
            </option>
            {categories &&
              categories.map((i) => (
                <option value={i.titleEn} key={i.titleEn} className="h2ar">
                  {currentDir == "ltr" ? i.titleEn : i.titleAr}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("sku")}</label>
          <span className="text-red-500">*</span>

          <input
            type="number"
            name="sku"
            value={sku}
            className="mt-2 h2ar ltr:valid: appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
            onChange={(e) => {
              setSku(e.target.value);
            }}
            placeholder={t("Write a sku of your Product")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Tags")}</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder={t("Enter your event product tags...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Original Price")}</label>
          <span className="text-gray-500 h2ar text-[10px] ">
            {" "}
            {t("It will be added")}
            <span className="text-red-500 h2ar text-[20px] ">
              {originalPrice ? originalPrice * 0.2 : " "}
            </span>{" "}
            {t("20% added to the price of the product as store fees")}{" "}
          </span>
          <span className="text-gray-500 h2ar text-[10px] ">
            {t(
              "In addition to that, there is a shipping fee of 10 SAR for the order"
            )}{" "}
          </span>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none h2ar sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder={t("Enter your event product price...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Price (After Discount)")}{" "}
            <span className="text-red-500">*</span>
          </label>{" "}
          <span className="text-gray-500 h2ar text-[10px] ">
            {" "}
            {t("It will be added")}{" "}
            <span className="text-red-500 h2ar text-[20px] ">
              {discountPrice ? discountPrice * 0.2 : " "}
            </span>{" "}
            {t("20% added to the price of the product as store fees")}{" "}
          </span>{" "}
          <span className="text-gray-500 h2ar text-[10px] ">
            {t(
              "In addition to that, there is a shipping fee of 10 SAR for the order"
            )}{" "}
          </span>
          <input
            type="number"
            name="price"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder={t("Enter your event product price with discount...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Product Stock")} <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="price"
            value={stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 h2ar focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder={t("Enter your event product stock...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Event Start Date")} <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="price"
            id="start-date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={handleStartDateChange}
            min={today}
            placeholder={t("Enter your event product start date...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Event End Date")} <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            name="price"
            id="end-date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 h2ar focus:outline-none h2ar sm:text-sm"
            onChange={handleEndDateChange}
            min={minEndDate}
            placeholder={t("Enter your event end date......")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Uploade Images")}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />

          <div className="grid lg:grid-cols-4 grid-cols-2 md:grid-cols-3 sm:grid-cols-2 sm:gap-4 md:gap-4 xs:gap-4 gap-3 w-full text-[#d0d2d6] mb-4">
            {imageShow.map((img, i) => (
              <div className="h-[180px] relative">
                <label htmlFor={i}>
                  <img
                    className="w-full h-full  rounded-lg border"
                    src={img.url}
                    alt=""
                    style={{
                      borderColor: currentColor,
                    }}
                  />
                </label>
                <input
                  onChange={(e) => changeImage(e.target.files[0], i)}
                  type="file"
                  id={i}
                  className="hidden"
                />
                <span
                  onClick={() => removeImage(i)}
                  className="p-2 z-10 cursor-pointer hover:shadow-lg hover text-white absolute top-1 right-1 rounded-full"
                  style={{ backgroundColor: currentColor }}
                >
                  <IoCloseSharp />
                </span>
              </div>
            ))}
            <label
              className="flex mt-2 justify-center items-center flex-col h-[180px] cursor-pointer border rounded-lg border-dashed hover:border-indigo-500 w-full text-[#d0d2d6]"
              htmlFor="image"
              style={{
                borderColor: currentColor,
              }}
            >
              <span>
                <BsImages size={40} color={currentColor} />
              </span>
              <span
                className="par text-md"
                style={{
                  color: currentColor,
                }}
              >
                {t("select image")}
              </span>
            </label>
            <input
              multiple
              onChange={inmageHandle}
              className="hidden"
              type="file"
              id="image"
            />
          </div>

          <br />
          <div className="w-full flex justify-center">
            {isSpinner ? (
              <Spinner word={t("Create Event")} />
            ) : (
              <button
                type="submit"
                className="text-white focus:ring-4 font-medium rounded-xl text-md px-5 py-2.5 h1ar text-center me-2 inline-flex items-center"
                style={{
                  backgroundColor: currentColor,
                  focusRing: currentColor,
                }}
              >
                {t("Create Event")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateEvent;
