import React from 'react'
import CheckoutSteps from '../components/Checkout/CheckoutSteps'
import Footer from '../components/Layout/Footer'
import Header from '../components/Layout/Header'
import PaymentCash from "../components/Payment/PaymentCash";

const PaymentCashOnDelevary = () => {
  return (
    <div className='w-full min-h-screen bg-[#f6f9fc]'>
       <Header />
       <br />
       <br />
       <CheckoutSteps active={2} />
       <PaymentCash />
       <br />
       <br />
       <Footer />
    </div>
  )
}

export default PaymentCashOnDelevary