import React from 'react'
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useStateContext } from "../../contexts/ContextProvider";
import {Link} from "react-router-dom"
import ThemeSettingsButton from '../../components/ThemeSettingsButton';

function Sellerdeactive() {

  const {t}=useTranslation();
const {currentColor}=useStateContext();
 toast.error(t("Your shop is not Active"));

 
  
  return (<><ThemeSettingsButton/>
    <div className=' text-center '>
    <h1 className='pt-[150px] pb-4'>{t("Your shop is not Active")}</h1>
<Link to="/active-shop-data" className="px-2 py-2 text-bold text-white rounded-lg rtl:h1ar"
style={{background:currentColor
}}
>
{t("go to active")}
</Link>
    </div></>
  )
}

export default Sellerdeactive