import axios from "axios";
import { server } from "../../server";

// get all orders of user
export const getAllOrdersOfUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllOrdersUserRequest",
    });

    const { data } = await axios.get(
      `${server}/order/get-all-orders/${userId}`
    );

    dispatch({
      type: "getAllOrdersUserSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersUserFailed",
      payload: error.response.data.message,
    });
  }
};

// get all orders of seller
export const getAllOrdersOfShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllOrdersShopRequest",
    });

    const { data } = await axios.get(
      `${server}/order/get-seller-all-orders/${shopId}`
    );

    dispatch({
      type: "getAllOrdersShopSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "getAllOrdersShopFailed",
      payload: error.response.data.message,
    });
  }
};
// get  orders type  of seller
export const getOrdersTypesOfShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: "getOrdersTypesShopRequest",
    });

    const { data } = await axios.get(
      `${server}/order/get-seller-orders-types/${shopId}`
    );

    dispatch({
      type: "getOrdersTypesShopSuccess",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "getOrdersTypesShopFailed",
      payload: error.response.data.message,
    });
  }
};

export const getOrderOfShop = (orderId) => async (dispatch) => {
  try {
    dispatch({
      type: "getOrderOfShopRequest",
    });

    const { data } = await axios.get(
      `${server}/order/get-order/${orderId}`
    );

    dispatch({
      type: "getAOrderOfShopSuccess",
      payload: data.order,
    });
  } catch (error) {
    dispatch({
      type: "getOrderOfShopFailed",
      payload: error.response.data.message,
    });
  }
};

// get all orders of Admin
export const getAllOrdersOfAdmin = () => async (dispatch) => {
  try {
    dispatch({
      type: "adminAllOrdersRequest",
    });

    const { data } = await axios.get(`${server}/order/admin-all-orders`, {
      withCredentials: true,
    });

    dispatch({
      type: "adminAllOrdersSuccess",
      payload: data.orders,
    });
  } catch (error) {
    dispatch({
      type: "adminAllOrdersFailed",
      payload: error.response.data.message,
    });
  }
};

