import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTocart } from "../../../redux/actions/cart";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useTranslation } from "react-i18next";
import { MdOutlineCancel } from "react-icons/md";
import { Buttoon } from "../../Charts";
import axios from "axios";
import { backend_url, server } from "../../../server";
import Ratings from "../../Products/Ratings";
const ProductDetailsCard = ({ setOpen, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const navigate = useNavigate();
  //   const [select, setSelect] = useState(false);
  const {t}=useTranslation()
 
  const incrementCount = (data) => {
    if (data.stock - 1 < count) {
      toast.error("Product stock limited!");
    } else {
      setCount(count + 1);
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const totalReviewsLength =
  products &&
  products.reduce((acc, product) => acc + product.reviews.length, 0);

const totalRatings =
  products &&
  products.reduce(
    (acc, product) =>
      acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
    0
  );
  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < count) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };
  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };
  return (
    <div className="bg-[#fff] dark:bg-black">
      {data ? (
        <div className="fixed w-full h-screen top-0 left-0 bg-[#00000030] z-40 flex items-center justify-center">
          <div className="w-[90%] 800px:w-[60%] h-[90vh] overflow-y-scroll 800px:h-[75vh] bg-white dark:bg-black rounded-md shadow-sm relative p-4">
          <div className="flex w-full fixed "
            onClick={() => setOpen(false)}
          >
            <Buttoon
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          
          />
            </div>
          

            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <img
                  src={`${backend_url}${data.images && data.images[0]}`}
                  alt=""
                  className=" rounded-lg"
                />
            
          
          
              </div>

              <div className="w-full 800px:w-[50%] pt-5 pl-[5px] pr-[5px]">
                <h1 className={`${styles.productTitle} text-[20px]`}>
                  {data.name}
                </h1>
                <p>{data.description}</p>

                <div className="flex pt-3">
          
          <h5 className={`${styles.productDiscountPrice}`}>
            {data.originalPrice === 0
              ? data.originalPrice
              :data.discountPrice+" "+t("SAR") }
       
          </h5>
          <h4 className={`${styles.price}`}>
            {data.originalPrice ?data.originalPrice+" "+t("SAR"): null}
          </h4>
        </div>
               
        <div className="flex flex-wrap items-center mt-6 justify-between pr-3 space-y-4 border-t pt-4 sm:flex-row sm:space-y-0">
                  <div className="flex items-center border-1 mt-5 border-color rounded">
                    <p
                      className="p-2  dark:border-gray-600 border-color text-red-600 "
                      onClick={decrementCount}
                    >
                      <AiOutlineMinus />{" "}
                    </p>
                    <p className="py-2 px-5 border-r-1 border-l-1 text-xl border-color  dark:border-gray-600 text-green-600">
                      {" "}
                      {count}
                    </p>
                    <p
                      className="p-2  border-color dark:border-gray-600 text-green-600"
                      onClick={() => incrementCount(data)}
                    >
                      <AiOutlinePlus />
                    </p>
                  </div>
<br/>
                  <div class="mt-3 flex select-none flex-wrap items-center gap-1">
                    <label class="">
                     <button
  className="dark:text-gray-400 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar"
  onClick={click ? () => removeFromWishlistHandler(data) : () => addToWishlistHandler(data)}
>
  {t("Add to wishlist")}{" "}
  {click ? (
    <AiFillHeart
      className="cursor-pointer"
      color={click ? "red" : "#333"}
      title="Remove from wishlist"
    />
  ) : (
    <AiOutlineHeart
      className="cursor-pointer"
      color={click ? "red" : "#333"}
      title="Add to wishlist"
    />
  )}
</button>
                    </label>
                    <label class="">
                      <p
                        className=" dark:text-gray-400 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar"
                        onClick={() => addToCartHandler(data._id)}
                      >
                        {t("Add to card")}{" "}
                        <AiOutlineShoppingCart className="ml-1" />
                      </p>
                    </label>
                  </div>
<br/>
                  <div class="mt-10 flex flex-col items-center my-2 justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                  <div class="flex items-end">
                    <h1 class="text-3xl h1ar dark:text-gray-200 font-bold">
                      {data.originalPrice === 0
                        ? data.originalPrice * count
                        : data.discountPrice * count + " " + t("SAR")}
                    </h1>
                    <span className={`${styles.price}`}>
                      {" "}
                      {data.originalPrice
                        ? data.originalPrice * count + " " + t("SAR")
                        : null}
                    </span>
                  </div>
                  <label class="">
                    <input
                      type="radio"
                      name="type"
                      value="Groud"
                      class="peer sr-only"
                    />
                <p   onClick={handleMessageSubmit} 
                className="dark:text-gray-400 mx-5 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar">
                    
                {t("Send Message")}   <AiOutlineMessage className="ml-1" />
                    </p>
                  </label>
                
                </div>
            
                </div>
                <ul class="mt-8 space-y-2">
                  <li class="flex items-center text-left text-sm font-medium text-gray-600">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <img
                        src={`${backend_url}${data?.shop?.avatar}`}
                        alt=""
                        className="w-[50px] h-[50px] rounded-full ltr:mr-2 rtl:ml-2"
                      />
                    </Link>
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                  </li>
                  <p class="ml-2 text-sm h2ar font-medium text-gray-500">
                    {" "}
                    ({averageRating}/5) {t("Shop Ratings")}
                  </p>
                  <Ratings rating={averageRating} />
                </ul>
              </div>
            
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetailsCard;
