// add to cart
export const addTocart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "addToCart",
    payload: data,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};

// remove from cart
export const removeFromCart = (data) => async (dispatch, getState) => {
  dispatch({
    type: "removeFromCart",
    payload: data._id,
  });
  localStorage.removeItem("cartItems", JSON.stringify(getState().cart.cart));
  return data;
};
// reducers.js

export const updateCart = (data) => async (dispatch, getState) => {
  // قم بتنفيذ الإجراءات اللازمة لتحديث البيانات في Redux
  dispatch({
    type: "updateCart",
    payload: data,
  });

  // تحديث العناصر في التخزين المحلي
  localStorage.removeItem("cartItems", JSON.stringify(getState().cart.cart));
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cart));

  return data;
};
