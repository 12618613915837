import React, { useState, useRef } from "react";

import { toast } from "react-toastify";
import styles from "../../styles/styles";
import axios from "axios";

import { backend_url, server } from "../../server";

import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const { t } = useTranslation();

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    await axios

      .put(
        `${server}/user/update-user-password`,

        { oldPassword, newPassword, confirmPassword },

        { withCredentials: true }
      )

      .then((res) => {
        toast.success(
          <span className="h1ar text-sm">{t(res.data.success)}</span>
        );

        setOldPassword("");

        setNewPassword("");

        setConfirmPassword("");
      })

      .catch((error) => {
        toast.error(
          <span className="h1ar text-sm">{t(error.response.data.message)}</span>
        );
      });
  };

  return (
    <div className="w-full bg-main-bg dark:bg-main-dark-bg lg:pt-14 pt-7 px-5">
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba]  pb-2 h0ar  ">
          {t("Change Password")}
        </h1>

        <div className="w-full">
          <form
            aria-required
            onSubmit={passwordChangeHandler}
            className="flex flex-col items-center"
          >
            <div className="  w-[100%] 800px:w-[50%] mt-14">
              <label className="block pb-2  text-gray-600 h1ar">
                {t("Enter your old password")}
              </label>

              <input
                type="password"
                className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                required
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
            </div>

            <div className=" w-[100%] 800px:w-[50%] mt-5">
              <label className="block pb-2  text-gray-600 h1ar">
                {t("Enter your new password")}
              </label>

              <input
                type="password"
                className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>

            <div className=" w-[100%] 800px:w-[50%] mb-5 mt-5">
              <label className="block pb-2  text-gray-600 h1ar">
                {t("Enter your confirm password")}
              </label>

              <input
                type="password"
                className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div className="flex w-full items-center mt-5 justify-center">
                <button type="submit" className={`${styles.buttoon}`}>
                  {t("Update")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChangePassword;
