import React from "react";
import Address from "../../components/Profile/Address";
import Header from "../../components/Layout/Header";

function AddressPage() {
  return (
    <>
      <Header />

      <div className={` flex h-screen bg-main-bg dark:bg-main-dark-bg py-10`}>
        <Address />
      </div>
    </>
  );
}

export default AddressPage;
