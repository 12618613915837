import React, { useEffect, useState } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import {server} from "../../server"
import { loadSeller } from "../../redux/actions/user";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import { useTranslation } from 'react-i18next';
import ThemeSettingsButton from "../ThemeSettingsButton";
const Sellerupdatestate = () => {
 
  const { t} = useTranslation();
  const [activeFile, setActiveFile] = useState(null);

  const [activePersonalId, setActivePersonalId] = useState('');

  const handleFileChange = (e) => {
    setActiveFile(e.target.files[0]);
  };

  const handlePersonalIdChange = (e) => {
    setActivePersonalId(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const activedata ={
      activefile:activeFile,
      activepersonalid:activePersonalId
    }
    await axios
    .put(
      `${server}/shop/update-active-data`,
      {
        activedata,
      },
      { withCredentials: true }
    )
    .then((res) => {
      toast.success("Withdraw method added successfully!");
   
      toast.success('تم إرسال البيانات بنجاح');
    })  .catch((error) => {
      console.log(error.response.data.message);
      toast.error('حدث خطأ أثناء إرسال البيانات', error);
    });
};


      // تنفيذ أي إجراءات أخرى بعد النجاح

 

  

  return (<><ThemeSettingsButton/>
    <div className="w-full h-[90vh] p-8">
      <h1>صفحة التاجر</h1>
      <form  onSubmit={handleSubmit}>
        <div>
          <label htmlFor="activefile">الملف النشط (PDF)</label>
          <input type="file" id="activefile" onChange={handleFileChange} accept=".pdf" />
        </div>
        <div>
          <label className="rtl:h1ar font-[600]" htmlFor="activepersonalid">الهوية الشخصية النشطة</label>
          <input type="text" id="activepersonalid" value={activePersonalId} onChange={handlePersonalIdChange} />
        </div>
        <button type="submit">إرسال</button>
      </form>
    
    </div></>
  );
};  

export default Sellerupdatestate;