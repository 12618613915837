import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { sellerReducer } from "./reducers/seller";
import { productReducer } from "./reducers/product";
import { eventReducer } from "./reducers/event";
import { activedataReducer } from "./reducers/activedata";
import { cartReducer } from "./reducers/cart";
import { wishlistReducer } from "./reducers/wishlist";
import { orderReducer } from "./reducers/order";
import { dailySalesReducer } from "./reducers/dailySales";
import { adminReducer } from "./reducers/admin";
import { withdrawReducer } from "./reducers/withdraw";
import { heroImageReducer } from "./reducers/heroImage";
import { categoriesReducer } from "./reducers/categories";
import { shipingReducer } from "./reducers/shipings";

const Store = configureStore({
  reducer: {
    dailySale: dailySalesReducer,
    admin: adminReducer,
    user: userReducer,
    seller: sellerReducer,
    products: productReducer,
    events: eventReducer,
    cart: cartReducer,
    wishlist: wishlistReducer,
    activedata: activedataReducer,
    order: orderReducer,
    withdraw: withdrawReducer,
    heroImages: heroImageReducer,
    categories: categoriesReducer,
    shipings: shipingReducer,
  },
});

export default Store;
