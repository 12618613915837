import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const activedataReducer = createReducer(initialState, {
    uploadActiveData: (state) => {
    state.isLoading = true;
  },
  activedatasendSuccess: (state, action) => {
    state.isLoading = false;
    state.activedata = action.payload;
    state.success = true;
  },
  activedatasendFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },

   // get all orders of shop
   getAllActiveDataRequest: (state) => {
    state.isLoading = true;
  },
  getAllActiveDataSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllActiveDataFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
