import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import Spinner from "./Charts/Spinner";
import { RxCross1 } from "react-icons/rx";
import { BiQrScan } from "react-icons/bi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles/styles";
import { getAllOrdersOfUser } from "../redux/actions/order";
import { backend_url, server } from "../server";
import QrCodeScanner from "./QrCodeScanner"
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {useStateContext} from "../contexts/ContextProvider"
const UserOrderDetails = () => {
  const {currentDir}=useStateContext()
  const { orders } = useSelector((state) => state.order);
  const [qrCode, setQrCode] = useState("");
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [isClickedButton, setIsClickedButton] = useState(false);
  const [showQr,setShowQr]=useState(false)
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, [dispatch]);

  let data = orders && orders.find((item) => item._id == id);

  const reviewHandler = async (e) => {
    await axios
      .put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(
          <span className="h1ar text-sm">{t(res.data.message)}</span>
        );
        dispatch(getAllOrdersOfUser(user._id));
        setComment("");
        setRating(null);
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const refundHandler = async () => {
    
    await axios
      .put(`${server}/order/order-refund/${id}`, {
        status: "Processing refund",
      })
      .then((res) => {
        toast.success(
          <span className="h1ar text-sm">{t(res.data.message)}</span>
        );
        dispatch(getAllOrdersOfUser(user._id));
      })
      .catch((error) => {
        toast.error(
          <span className="h1ar text-sm">{t(error.response.data.message)}</span>
        );
      });
  };

  const shopPercint = data?.totalPrice * 0.2;
  const newTotalPrice = data?.totalPrice + shopPercint + 10;
  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data.cart.shopId + user._id;
      const userId = user._id;
      const sellerId = data.cart.shopId;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error(
        <span className="h1ar text-sm">
          {t("Please login to create a conversation")}
        </span>
      );
    }
  };
  
  
  
  const qrScanHandeler = async (result) => {
    
    setQrCode(result)
    setIsClickedButton(true);
    await axios
      .put(
        `${server}/order/update-user-order/${id}`,
        {
        qrCode,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(<span className="h1ar text-sm">{t("Order receipt confirmation  success")}</span>);
        dispatch(getAllOrdersOfUser(user._id));
        let data = orders && orders.find((item) => item._id == id);
        setIsClickedButton(false);
        setQrCode("")
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setIsClickedButton(false);
        setQrCode("")
      });
  };
   

  return (
    <div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <BsFillBagFill size={30} color="crimson" />
          <h1 className="pl-2 h0ar text-[25px]">{t("Order Details")}</h1>
        </div>
      </div>

      <div className="w-full flex-wrap items-center lg:justify-between pt-6">
        <h5 className="text-sm par text-gray-800 ">
          {t("Order ID")}: <span>{data?.orderNumber}</span>
        </h5>
        <br />
        <h5 className="text-sm par text-gray-800 ">
          {t("Placed on")}: <span>{data?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* order items */}
      <br />
      <br />
      {data &&
        data?.cart.map((item, index) => {
          return (
            <div className="w-full flex items-start mb-5">
              <img
                src={`${backend_url}/${item.images[0]}`}
                alt=""
                className="w-[80x] rounded-md h-[80px]"
              />
              <div className="w-full ltr:pl-3 rtl:pr-3">
                <h5 className=" h1ar text-md">{item.name}</h5>
                <h5 className="pl-3 text-md h2ar text-gray-600">
                  {item.discountPrice}
                  {t("SAR")} * {"("}
                  {item.qty}
                  {")"}
                </h5>
              </div>
              {!item.isReviewed && data?.status === "Delivered" ? (
                <button
                  className={`${styles.buttoon} h1ar text-[#fff]`}
                  onClick={() => setOpen(true) || setSelectedItem(item)}
                >
                  {t("Review")}
                </button>
              ) : null}
            </div>
          );
        })}

      {/* review popup */}
      {open && (
        <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="800px:w-[50%] h-min w-[90%] bg-[#fff] shadow rounded-md p-3">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                onClick={() => setOpen(false)}
                className="cursor-pointer"
              />
            </div>
            <h2 className="text-[30px] font-[500] h1ar font-Poppins text-center">
              {t("Give a Review")}
            </h2>
            <br />
            <div className="w-full flex">
              <img
                src={`${backend_url}/${selectedItem?.images[0]}`}
                alt=""
                className="w-[80px] h-[80px]"
              />
              <div>
                <div className="pl-3 h1ar text-[20px]">
                  {selectedItem?.name}
                </div>
                <h5 className="pl-3 text-md h2ar text-gray-600">
                  {selectedItem.discountPrice}
                  {t("SAR")} * {"("}
                  {selectedItem.qty}
                  {")"}
                </h5>
              </div>
            </div>

            <br />
            <br />

            {/* ratings */}
            <h5 className="pl-3 text-[20px] h1ar font-[500]">
              {t("Give a Rating")}
              <span className="text-red-500">*</span>
            </h5>
            <div className="flex w-full ml-2 pt-1">
              {[1, 2, 3, 4, 5].map((i) =>
                rating >= i ? (
                  <AiFillStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                ) : (
                  <AiOutlineStar
                    key={i}
                    className="mr-1 cursor-pointer"
                    color="rgb(246,186,0)"
                    size={25}
                    onClick={() => setRating(i)}
                  />
                )
              )}
            </div>
            <br />
            <div className="w-full ml-3">
              <label className="block text-[20px] h1ar font-[500]">
                {t("Write a comment")}
                <span className="mx-1 font-[400] h2ar text-[16px] text-[#00000052]">
                  {t("(optional)")}
                </span>
              </label>
              <textarea
                name="comment"
                id=""
                cols="20"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t(
                  "How was your product? write your expresion about it!"
                )}
                className="mt-2 w-[95%] border p-2 par outline-none"
              ></textarea>
            </div>
            <button
              className={`${styles.buttoon} text-white text-[20px] ml-3`}
              onClick={rating > 1 ? reviewHandler : null}
            >
              {t("Submit")}
            </button>
          </div>
        </div>
      )}

{/*    qr code    */}
         {  showQr && 
     (
     <QrCodeScanner
     setShowQr={setShowQr}
     qrScanHandeler={qrScanHandeler}
     />
     )}

      <div className="border-t w-full text-right">
        <h5 className="pt-3 text-xl h1ar  ">
          {t("Total Price")}:{" "}
          {data?.status === "Delivered" ? (
            <span className="text-md h2ar">
              {newTotalPrice} {t("SAR")}
            </span>
          ) : (
            <span className="text-md h2ar">
              {data?.totalPrice.toFixed(2)} {t("SAR")}
            </span>
          )}
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full 800px:flex items-center">
        <div className="w-full 800px:w-[60%]">
          <h4 className="pt-3  text-2xl text-mypink h1ar ">
            {t("Shipping Infrmation")}:
          </h4>

          <h4 className="pt-3 h1ar text-xl">
            {t("Country")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.shippingAddress.country}
            </span>
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("City")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.shippingAddress.city}
            </span>
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {" "}
            {t("Phone Number")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {data?.user?.phoneNumber}
            </span>
          </h4>

          <h4 className="pt-3 h1ar text-xl">
            {t("Street Name")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {" " + data?.shippingAddress.street}
            </span>{" "}
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("Building name/number")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {" " + data?.shippingAddress.building}
            </span>{" "}
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("Nearest place")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {" " + data?.shippingAddress.nearestTeacher}
            </span>{" "}
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("delivery instructions")}:{" "}
            <span className="text-md text-gray-800 h2ar  ">
              {" "}
              {" " + t(data?.shippingAddress.addressType)}
            </span>{" "}
          </h4>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 text-mypink text-2xl h1ar ">
            {t("Payment Info")}:
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {" "}
            {t("Payment Type")}:{" "}
            <span className="text-md h2ar text-gray-800 ">
              {t(data?.paymentInfo?.type)}
            </span>
          </h4>
          <h4 className="pt-3 h1ar text-xl">
            {t("Status")}:{" "}
            <span className="text-md text-green-500 h2ar  ">
              {data?.paymentInfo?.status ? (
                t(data?.paymentInfo?.status)
              ) : (
                <span className="text-red-500"> {t("Not Paid")}</span>
              )}
            </span>
          </h4>
          <br />
          {data?.status !== "Delivered"   && data?.paymentInfo?.type == "Tap" ? (
            <button
              className={`${styles.buttoon} text-white`}
              onClick={refundHandler}
            >
              {t("Give a Refund")}
            </button>
          ):(null)}
        </div>
      </div>
      <br />
            {isClickedButton && data?.status !== "Delivered"  ? (
        <div className="mt-5 mx-3 text-[#fff] font-[600] !h-[45px] text-[18px">
          <Spinner word={t("receipt confirmation")} />
        </div>
      ) : !isClickedButton && data?.status !== "Delivered"  ? (
   <button className={`${styles.buttoon} text-white flex items-center justify-center`} onClick={()=>setShowQr(true)}>    {t("receipt confirmation")}  <BiQrScan className={`${currentDir =="ltr"? "ml-1" : "mr-1"}`}/>
            </button>) :  (  
            <button className="border border-2 bg-green-100 border-dashed border-green-500 py-2 px-3 flex items-center rounded-2xl justify-center ">
            <h4 className="h1ar text-green-500 text-xl">
            {t("Receipt confirmed successfully")}
          </h4></button>)}
      <br />
      <br />

    </div>
  );
}
export default UserOrderDetails;
