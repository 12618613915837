import React from "react";
import AllUserRefundOrders from "../../components/Profile/AllUserRefundOrders";
import Header from "../../components/Layout/Header";

function AllUserRefundOrdersPage() {
  return (
    <>
      <Header />

      <div className={` flex h-screen bg-main-bg dark:bg-main-dark-bg py-10`}>
        <AllUserRefundOrders />
      </div>
    </>
  );
}

export default AllUserRefundOrdersPage;
