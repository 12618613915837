import BodyCare from "../Assests/images/BodyCare.png";
import cosmetics from "../Assests/images/cosmetic.png";
import Accesories from "../Assests/images/Accesories.jpg";
import shose from "../Assests/images/shos.jpg";
import whach from "../Assests/images/whach.png";
import gifts from "../Assests/images/gifts.png";
import image1 from "../Assests/images/image1.svg";
import image2 from "../Assests/images/image2.svg";
import image3 from "../Assests/images/image3.svg";
import image4 from "../Assests/images/image4.svg";
import { FaQuestionCircle } from "react-icons/fa";
import { FaBagShopping } from "react-icons/fa6";
import { FaShoppingBasket } from "react-icons/fa";
import { MdLocalOffer } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaHome } from "react-icons/fa";

export const COLORS = [
  {
    name: "black",
    code: "#000000",
  },
  {
    name: "white",
    code: "#ffffff",
  },
  {
    name: "red",
    code: "#FF0000",
  },
  {
    name: "maroon",
    code: "#800000",
  },
  {
    name: "Yellow",
    code: "#FFFF00",
  },
  {
    name: "Lime",
    code: "#00FF00",
  },
  {
    name: "Green",
    code: "#008000",
  },
  {
    name: "Aqua",
    code: "#00FFFF",
  },
  {
    name: "Teal",
    code: "#008080",
  },
  {
    name: "Blue",
    code: "#0000FF",
  },
  {
    name: "Fuchsia",
    code: "#FF00FF",
  },
  {
    name: "Purple",
    code: "#800080",
  },
  {
    name: "Gray",
    code: "#808080",
  },
  {
    name: "Silver",
    code: "#C0C0C0",
  },
  {
    name: "orang",
    code: "#FF5733 ",
  },
  {
    name: "Brown",
    code: " #964B00",
  },
];

export const navItems = [
  { title: "home", url: "/", icon: <FaHome /> },
  {
    title: "best-selling",
    url: "/best-selling",
    icon: <FaBagShopping />,
  },
  {
    title: "products",
    url: "/products",
    icon: <FaShoppingBasket />,
  },
  {
    title: "event",
    url: "/events",
    icon: <MdLocalOffer />,
  },
  {
    title: "FAQ",
    url: "/faq",
    icon: <FaQuestionCircle />,
  },
];

export const mobilnavItems = [
  { title: "home", url: "/", icon: <FaHome /> },
  {
    title: "products",
    url: "/products",
    icon: <FaShoppingBasket />,
  },
  {
    title: "event",
    url: "/events",
    icon: <MdLocalOffer />,
  },
  {
    title: "profile",
    url: "/Profile",
    icon: <CgProfile />,
  },
];
// branding data
export const brandingData = [
  {
    id: 1,
    title: "Fast Delivery",
    Description: "Fast and reliable delivery to any location",
    icon: image1,
  },
  {
    id: 2,
    title: "Daily surprises and offers",
    Description: "Save up to 25% off",
    icon: image2,
  },
  {
    id: 4,
    title: "Affortable Prices",
    Description: "Get Factory direct price",
    icon: image3,
  },
  {
    id: 5,
    title: "Secure Payments",
    Description: "100% protected payments",
    icon: image4,
  },
];

export const Cityes = [
  { id: 1, title: "الرياض" },
  { id: 2, title: "جده" },
  { id: 3, title: "المدينة المنورة" },
  { id: 4, title: "تبوك" },
  { id: 5, title: "الدمام" },
  { id: 6, title: "الأحساء" },
  { id: 7, title: "القطيف" },
  { id: 8, title: "خميس مشيط" },
  { id: 9, title: "المظيلف" },
  { id: 10, title: "الهفوف" },
  { id: 11, title: "المبرز" },
  { id: 12, title: "الطائف" },
  { id: 13, title: "نجران" },
  { id: 14, title: "حفر الباطن" },
  { id: 15, title: "الجبيل" },
  { id: 16, title: "ضباء" },
  { id: 17, title: "الخرج" },
  { id: 18, title: "الثقبة" },
  { id: 19, title: "ينبع البحر" },
  { id: 20, title: "الخبر" },
  { id: 21, title: "عرعر" },
  { id: 22, title: "الحوبة" },
  { id: 23, title: "عنيزة" },
  { id: 24, title: "سكاكا" },
  { id: 25, title: "جيزان" },
  { id: 26, title: "القريات" },
  { id: 27, title: "الظهران" },
  { id: 28, title: "الباحة" },
  { id: 29, title: "الزلفي" },
  { id: 30, title: "الرس" },
  { id: 31, title: "وادي الدواسر" },
  { id: 32, title: "بيشة" },
  { id: 33, title: "سيهات" },
  { id: 34, title: "شرورة" },
  { id: 35, title: "بحره" },
  { id: 36, title: "تاروت" },
  { id: 37, title: "الدوادمي" },
  { id: 38, title: "صبياء" },
  { id: 39, title: "بيش" },
  { id: 40, title: "الفريش" },
  { id: 41, title: "الحوطه" },
];

// product Data

export const footercompanyLinks = [];
