import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { backend_url, server } from "../../server";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useReactToPrint } from "react-to-print";
import { useStateContext } from "../../contexts/ContextProvider";
import { AdmindeleteProduct } from "../../redux/actions/product";
const AllProducts = () => {
  const componentPDF = useRef();
  const { currentColor, currentDir } = useStateContext();
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const { deleteSuccess, deleteError } = useSelector((state) => state.products);
  const getdata = () => {
    axios
      .get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        setData(res.data.products);
      });
  };
  useEffect(() => {
    getdata();
  }, []);

  useEffect(() => {
    if (deleteSuccess) {
      window.location.reload();
    }
    if (deleteError) {
      toast(deleteError);
    }
  }, [deleteError, deleteSuccess]);
  const handleDelete = async (id) => {
    try {
      setIsDeleting(true); // تعيين حالة الحذف إلى true
      dispatch(AdmindeleteProduct(id));
      getdata();
    } catch (error) {
      toast(error);
    } finally {
      setIsDeleting(false); // تعيين حالة الحذف إلى false بعد الانتهاء من الحذف
    }
  };
  const theme = createTheme({
    direction: currentDir,
  });
  const columns = [
    {
      field: "productNumber",
      headerName: <span className="h1ar text-sm">{t("Product Id")}</span>,
      minWidth: 150,
      flex: 0.7,
    },
    {
      field: "name",
      headerName: <span className="h1ar text-sm">{t("Name")}</span>,
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "image",
      headerName: <span className="h1ar text-sm">{t("Image")}</span>,
      minWidth: 150,
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div
            style={{
              borderRadius: "50%",
              padding: "10px",
              width: "65px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65px",
            }}
          >
            <img
              src={params.value}
              alt="Product Image"
              style={{
                height: "100%",
                width: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: <span className="h1ar text-sm">{t("Price")}</span>,
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: <span className="h1ar text-sm">{t("Stock")}</span>,
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: <span className="h1ar text-sm">{t("Sold out")}</span>,
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: <span className="h1ar text-sm">{t("Preview")}</span>,
      flex: 0.8,
      minWidth: 100,
      headerName: <span className="h1ar text-sm">{t("Preview")}</span>,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button
                className="rounded-full"
                style={{ background: currentColor }}
              >
                <AiOutlineEye style={{ color: "#fff" }} size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },

    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: <span className="h1ar text-sm">{t("Delete")}</span>,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              style={{ background: "#ff0000" }}
              onClick={() => handleDelete(params.id)}
            >
              <AiOutlineDelete style={{ color: "#ffffff" }} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        productNumber: item.productNumber,
        image: `${backend_url}${item.images && item.images[0]}`,
        name: item.name,
        price: t("SAR") + item.discountPrice,
        Stock: item.stock,
        sold: item?.sold_out,
        id: item._id,
      });
    });
  const genratePDF = useReactToPrint({
    content: () => componentPDF.current,

    documentTitle: "AllProducts",
    onAfterPrint: () => alert("Data saved in PDF"),
  });
  return (
    <>
      <frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className="flex items-center mx-8 mt-4 ">
          <button
            className=" rounded-md text-gray-100 py-1 px-3 mx-2"
            onClick={genratePDF}
            style={{ background: currentColor }}
          >
            PDF
          </button>
          <div
            className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
            style={{ background: currentColor }}
          >
            {data ? <CSVLink data={data}>Exel</CSVLink> : null}
          </div>
        </div>
        <br />
        <ThemeProvider theme={theme}>
          <div
            ref={componentPDF}
            className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl"
          >
            <DataGrid
              rows={row}
              columns={columns}
              disableSelectionOnClick
              autoHeight
              initialState={{
                ...row.initialState,
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pageSizeOptions={[10, 20, 50]}
            />
          </div>
        </ThemeProvider>
      </frameElement>
    </>
  );
};

export default AllProducts;
