import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsCheck } from 'react-icons/bs';
import { useTranslation } from "react-i18next";

import { themeColors } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { useSelector } from 'react-redux';

const ThemeSettings = () => {
  
  const {currentDir,currentLang,setLang, setColor, setMode, currentMode, currentColor, setThemeSettings,setDir, } = useStateContext();
  const { isSeller } = useSelector((state) => state.seller);
  const { t, i18n } = useTranslation();
  
  return (
    <div className="bg-half-transparent w-screen fixed nav-item shadow-md top-0 right-0">
    <div className="fixed bottom-20  lg:top-0 top-30 rounded-xl lg:rounded-none  sm:rounded-md ltr:right-0 lg:h-full 800px:h-full w-[50%] 800px:w-[20%] dark:text-gray-200  dark:bg-[#484B52] bg-white flex flex-col overflow-y-scroll  shadow-sm "style={{ zIndex: '200000' }} >
      <div className="flex justify-between items-center pt-2 lg:pt-4 pl-4 pr-4 pb-4 ml-4">
        <p className="font-semibold pt-5 text-lg">{t("Settings")}</p>
        <button
          type="button"
          onClick={() => setThemeSettings(false)}
          style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
          className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
        >
          <MdOutlineCancel />
        </button>

      </div>
      {isSeller ?(
      <div className="p-4 border-t-1 border-color ml-4">
        <p className="font-semibold text-xl ">{t("Theme Colors")}</p>
        <div className="flex flex-wrap lg:flex-nowrap gap-3">
          {themeColors.map((item, index) => (
            <div key={index} content={item.name} position="TopCenter">
              <div
                className="relative mt-2 cursor-pointer flex  gap-5 items-center"
                key={item.name}
              >
                <button
                  type="button"
                  className="h-10 w-10 rounded-full cursor-pointer"
                  style={{ backgroundColor: item.color }}
                  onClick={() => setColor(item.color)}
                >
                  <BsCheck className={`ml-2 rtl:mr-2 text-2xl text-white ${item.color === currentColor ? 'block' : 'hidden'}`} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>):(null)}
    </div>
  </div>
);
};

export default ThemeSettings;
