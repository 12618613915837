import React from "react";
import { backend_url } from "../../server";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
const {t}=useTranslation();
  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  }
  return (
    <div
      className={`w-full block bg-main dark:bg-black rounded-2xl justify-between shadow-lg ${
        active ? "unset" : "mb-12"
      } lg:flex p-2 justify-between`}
    >
      <div className="w-full p-4 lg:-w[50%] m-auto">
        <img className=" shadow-sm rounded-lg" src={`${backend_url}${data.images[0]}`} alt="" />
      </div>
      <div className="lg:ltr:pl-14 lg:rtl:pr-14 w-full lg:[w-50%] flex flex-col justify-center ">
        <h2 className={ ` ${styles.productTitle} h0ar`}>{data.name}</h2>
        <p className="h1ar dark:text-gray-200">{data.description}</p>
        <div className="flex py-2 justify-between">
        <div className="flex">
          
          <h5 className={`${styles.productDiscountPrice}`}>
            {data.originalPrice === 0
              ? data.originalPrice
              :data.discountPrice+" "+t("SAR")}
       
          </h5>
          <h4 className={`${styles.price}`}>
       {data.originalPrice ? data.originalPrice + " " + t("SAR") : null}
          </h4>
        </div>
          <span className="pr-3 font-[400] text-[17px] text-[#44a55e]">
            {data.stock} {t("Stock")}
          </span>
        </div>
        <CountDown data={data} />
        <br />
        <div className="flex items-center">
          <Link to={`/product/${data._id}?isEvent=true`}>
            <div className={`${styles.buttoon} text-[#fff] h1ar ltf:mr-5 rtl:ml-5 `}>{t("See Details")}</div>
          </Link>
          <div className={`${styles.buttoon} text-[#fff] ml-5 h1ar`} onClick={() => addToCartHandler(data)}>{t("Add to cart")}</div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
