
import React from 'react'

import { useStateContext } from "../contexts/ContextProvider";
function ToggleLanguages() {
  
const {currentDir,currentLang,setLang,setDir } = useStateContext();
const themLangHandeler= () =>{
  
if (currentDir === 'rtl'){
setDir('ltr')
}
else if (currentDir === 'ltr'){
setDir('rtl')}
;}
return (<>
<div
onClick={themLangHandeler}
className="text-gray-900 bg-white border border-gray-300 outline-none hover:bg-gray-100 ring-4  ring-gray-200 font-medium rounded-lg 800px:text-md text-sm px-0.5 py-2 me-2 mb-2 dark:bg-black dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:border-gray-600 dark:ring-gray-700">
{currentDir === 'rtl'?(<span>AR🇸🇦</span>):(<span>EN🇬🇧</span>)}
</div>

</>                                                                                             
)}
export default ToggleLanguages
