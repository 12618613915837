import React, { useEffect, useState } from "react";
import axios from "axios";
import { FiSettings, FiShoppingBag } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Header from "../Layout/Header";
import logo from "../../Assests/images/Revo One.svg";
import { getAllProductsShop } from "../../redux/actions/product";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";
import Loader from "../Layout/Loader";
import { backend_url, server } from "../../server";
import Ratings from "../Products/Ratings";
import { getAllEventsShop } from "../../redux/actions/event";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
const ShopProfileData = ({ isOwner }) => {
  const { products } = useSelector((state) => state.products);
  const { events } = useSelector((state) => state.events);
  const { currentColor } = useStateContext();
  const [data, setData] = useState({});
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatch(getAllProductsShop(id));
    dispatch(getAllEventsShop(id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    setIsLoading(true);
    axios
      .get(`${server}/shop/get-shop-info/${id}`)
      .then((res) => {
        setData(res.data.shop);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
  const [active, setActive] = useState(1);

  const allReviews =
    products && products.map((product) => product.reviews).flat();
  const logoutHandler = async () => {
    try {
      await axios.get(`${server}/shop/logout`, {
        withCredentials: true,
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const averageRating = totalRatings / totalReviewsLength || 0;
  return (
    <>
      {!isOwner && <Header />}
      <div className="w-full bg-main-bg dark:bg-main-dark-bg">
        <div className={`${styles.section} bg-main-bg dark:bg-main-dark-bg`}>
          {isOwner && (
            <nav class="flex ">
              <ol role="list" class="flex items-center">
                <li class="text-left">
                  <div class="-m-1 pt-2">
                    <Link
                      to="/"
                      style={{ color: currentColor }}
                      class="rounded-md h2ar text-xl  p-1  font-medium focus:shadow hover:text-gray-800"
                    >
                      {" "}
                      {t("Home")}{" "}
                    </Link>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center pt-2">
                    <span
                      class="mx-2 h2ar text-xl  "
                      style={{ color: currentColor }}
                    >
                      {" "}
                      /
                    </span>
                    <div class="-m-1">
                      <Link
                        to="/dashboard"
                        class="rounded-md h2ar text-xl  p-1  font-medium focus:shadow hover:text-gray-800"
                        style={{ color: currentColor }}
                      >
                        {" "}
                        {t("Dashboard")}{" "}
                      </Link>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          )}
          <div className="flex w-full items-center justify-between ">
            <div className="w-full flex">
              <div className="border-b border-gray-300">
                <nav className="flex gap-4">
                  <h5
                    className={`${
                      active === 1
                        ? " border-b-2   border-gray-900 py-3 text-lg h1ar font-medium hover:border-gray-400 hover:text-gray-800"
                        : "inline-flex items-center  border-b-2 border-transparent h1ar py-4 text-md font-medium dark:text-gray-400 text-gray-600"
                    } cursor-pointer `}
                    onClick={() => setActive(1)}
                    style={active === 1 ? { color: currentColor } : null}
                  >
                    {t("Shop Details")}
                  </h5>

                  <h5
                    className={`${
                      active === 2
                        ? " border-b-2  border-gray-900 py-3 text-lg h1ar font-medium hover:border-gray-400 hover:text-gray-800"
                        : "inline-flex items-center  border-b-2 border-transparent h1ar py-4 dark:text-gray-400 text-md font-medium text-gray-600"
                    } cursor-pointer `}
                    onClick={() => setActive(2)}
                    style={active === 2 ? { color: currentColor } : null}
                  >
                    {t("Shop Products")}
                  </h5>

                  <h5
                    className={`${
                      active === 3
                        ? " border-b-2   border-gray-900 py-4 text-lg h1ar font-medium hover:border-gray-400 hover:text-gray-800"
                        : "inline-flex items-center  border-b-2 dark:text-gray-400 border-transparent h1ar py-4 text-md font-medium text-gray-600"
                    } cursor-pointer `}
                    style={active === 3 ? { color: currentColor } : null}
                    onClick={() => setActive(3)}
                  >
                    {t("Running Events")}
                  </h5>
                  <h5
                    className={`${
                      active === 4
                        ? " border-b-2   border-gray-900 py-4 text-lg h1ar font-medium hover:border-gray-400 hover:text-gray-800"
                        : "inline-flex items-center  border-b-2 dark:text-gray-400 border-transparent h1ar py-4 text-md font-medium text-gray-600"
                    } cursor-pointer `}
                    style={active === 4 ? { color: currentColor } : null}
                    onClick={() => setActive(4)}
                  >
                    {t("Shop Reviews")}
                  </h5>
                </nav>
              </div>
            </div>
          </div>

          <br />
          {active === 1 &&
            (isLoading ? (
              <Loader />
            ) : (
              <aside className="flex flex-col w-full h-screen bg-main-bg dark:bg-main-dark-bg px-4 overflow-y-auto ">
                <div className="flex flex-col items-center mt-3 -mx-2">
                  <img
                    className="object-cover w-24 h-24 mx-2 rounded-full"
                    src={`${backend_url}${data.avatar}`}
                    alt="avatar"
                  />
                  <h4 className="mx-2 dark:text-gray-200 mt-2 font-medium text-gray-800">
                    {data.name}
                  </h4>
                  <p className="mx-2 mt-1 dark:text-gray-200 text-sm font-medium text-gray-600 ">
                    {data.email}
                  </p>
                </div>

                <div className="flex-col  justify-between flex-1 mt-6">
                  <nav className="flex flex-wrap">
                    <div
                      className="flex items-center px-5 py-2 mt-3 text-gray-600 transition-colors duration-300 transform rounded-lg  "
                      href="#"
                    >
                      <span
                        style={{ color: currentColor }}
                        class="font-medium h1ar"
                      >
                        {t("Address")}:
                      </span>
                      <span class="mx-4 dark:text-gray-400 font-medium">
                        {data.address}
                      </span>
                    </div>

                    <div
                      className="flex items-center px-5 py-2 mt-3 text-gray-600 transition-colors duration-300 transform rounded-lg  "
                      href="#"
                    >
                      <span
                        style={{ color: currentColor }}
                        class="font-medium h1ar"
                      >
                        {t("Phone Number")}:
                      </span>
                      <span class="mx-4 dark:text-gray-400 font-medium">
                        {data.phoneNumber}
                      </span>
                    </div>

                    <div class="flex items-center px-5 py-2 mt-3 text-gray-600 transition-colors duration-300 transform rounded-lg  ">
                      <span
                        style={{ color: currentColor }}
                        class="font-medium h1ar"
                      >
                        {t("Total Products:")}
                      </span>
                      <span class="mx-4 dark:text-gray-400 font-medium">
                        {products && products.length}
                      </span>
                    </div>
                    <div className="flex items-center px-5 py-2 mt-3 text-gray-600 transition-colors duration-300 transform rounded-lg   ">
                      <span
                        style={{ color: currentColor }}
                        class="font-medium h1ar"
                      >
                        {t("Shop Ratings:")}
                      </span>
                      <span class="mx-4 dark:text-gray-400 font-medium">
                        {averageRating}/5
                      </span>
                    </div>

                    <div className="flex items-center px-5 py-2 mt-3 text-gray-600 transition-colors duration-300 transform rounded-lg   ">
                      <span
                        style={{ color: currentColor }}
                        class="font-medium h1ar"
                      >
                        {t("Joined On:")}
                      </span>
                      <span class="mx-4 dark:text-gray-400 font-medium">
                        {data?.createdAt?.slice(0, 10)}
                      </span>
                    </div>
                  </nav>
                  {isOwner && (
                    <div>
                      <Link to="/dashboard">
                        {" "}
                        <button
                          className="flex items-center px-4 w-[180px] py-2 mt-10 cursor-pointer text-gray-200 transition-colors duration-300 transform rounded-lg "
                          style={{ backgroundColor: currentColor }}
                        >
                          <FiShoppingBag />
                          <span className="mx-4 font-medium  h1ar">
                            {t("Dashboard")}
                          </span>
                        </button>
                      </Link>

                      <Link to="/settings">
                        {" "}
                        <button
                          className="flex w-[180px] items-center px-4 py-2 mt-10 cursor-pointer text-gray-200 transition-colors duration-300 transform rounded-lg "
                          style={{ backgroundColor: currentColor }}
                        >
                          <FiSettings />
                          <span className="mx-4 font-medium  h1ar">
                            {t("Settings")}
                          </span>
                        </button>
                      </Link>
                      <div onClick={logoutHandler}>
                        {" "}
                        <button
                          className="flex w-[180px] items-center px-4 py-2 mt-10 cursor-pointer text-gray-200 transition-colors duration-300 transform rounded-lg "
                          style={{ backgroundColor: currentColor }}
                        >
                          <span className="mx-4 font-medium  h1ar">
                            {t("Log Out")}
                          </span>
                        </button>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </aside>
            ))}

          {active === 2 && (
            <div className=" bg-main-bg h-screen  overflow-y-scroll dark:bg-main-dark-bg   mb-12 border-0">
              <div className="grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
                {products &&
                  products.map((i, index) => (
                    <ProductCard data={i} key={index} isShop={true} />
                  ))}
              </div>
            </div>
          )}

          {active === 3 && (
            <div className="w-full h-screen  bg-main-bg dark:bg-main-dark-bg">
              <div className="grid grid-cols-2 gap-2  md:grid-cols-2 md:gap-[25px] lg:grid-cols-3 lg:gap-[25px] xl:grid-cols-4 xl:gap-[20px] mb-12 border-0">
                {events &&
                  events.map((i, index) => (
                    <ProductCard
                      data={i}
                      key={index}
                      isShop={true}
                      isEvent={true}
                    />
                  ))}
              </div>
              {events && events.length === 0 && (
                <h5 className="w-full text-center py-5 h0ar text-red-600 dark:text-gray-200 text-[18px]">
                  {t("No Events have for this shop!")}
                </h5>
              )}
            </div>
          )}

          {active === 4 && (
            <div className="w-full bg-main-bg h-screen   overflow-y-scroll  dark:bg-main-dark-bg">
              {allReviews &&
                allReviews.map((item, index) => (
                  <div className="w-full flex my-4">
                    <img
                      src={`${backend_url}/${item.user.avatar}`}
                      className="w-[50px] h-[50px] rounded-full"
                      alt=""
                    />
                    <div className="pl-2">
                      <div className="flex w-full items-center">
                        <h1 className="font-[600] dark:text-gray-200 pr-2">
                          {item.user.name}
                        </h1>
                        <Ratings rating={item.rating} />
                      </div>
                      <p className="font-[400] dark:text-gray-400 text-[#000000a7]">
                        {item?.comment}
                      </p>
                    </div>
                  </div>
                ))}
              {allReviews && allReviews.length === 0 && (
                <h5 className="w-full text-center dark:text-gray-200 py-5 text-[18px]">
                  {t("No Reviews have for this shop!")}
                </h5>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShopProfileData;
