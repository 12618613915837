import React, { useEffect, useState } from "react";

import { AiOutlineArrowRight } from "react-icons/ai";

import { getdailySalesOfShop } from "../../redux/actions/dailySales";

import { Link } from "react-router-dom";

import { MdBorderClear, MdOutlineLocalOffer } from "react-icons/md";

import { useDispatch, useSelector } from "react-redux";

import { getAllOrdersOfShop } from "../../redux/actions/order";

import { getAllProductsShop } from "../../redux/actions/product";

import { Button } from "@material-ui/core";

import { DataGrid } from "@mui/x-data-grid";

import DailySalesChart from "../chart/DailySalesChart";

import OrdersTypesCharts from "../chart/OrdersTypesCharts";

import { useStateContext } from "../../contexts/ContextProvider";

import Loader from "../Layout/Loader";

import { CiMoneyBill } from "react-icons/ci";

import { BsBoxSeam } from "react-icons/bs";

import { Buttoon } from "../Charts";

import { useTranslation } from "react-i18next";

import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "@mui/material/styles";

const DashboardHero = () => {
  const dispatch = useDispatch();

  const { orders } = useSelector((state) => state.order);

  const { dailySales, isLoading } = useSelector((state) => state.dailySale);

  const { events } = useSelector((state) => state.events);

  const { seller } = useSelector((state) => state.seller);

  const { products } = useSelector((state) => state.products);

  const { currentColor, currentDir } = useStateContext();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));

    dispatch(getdailySalesOfShop(seller._id));

    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);

  const availableBalance = seller?.availableBalance.toFixed(2);

  const theme = createTheme({
    direction: currentDir,
  });

  const columns = [
    {
      field: "id",
      headerName: <span className="h1ar text-sm ">{t("Order ID")}</span>,
      minWidth: 150,
      flex: 0.7,
    },

    {
      field: "status",

      headerName: <span className="h1ar text-sm ">{t("Status")}</span>,

      minWidth: 130,

      flex: 0.7,

      renderCell: (params) => {
        const statusColor =
          params.value === "Delivered"
            ? "#1def68"
            : "#ff8842" && params.value === "Processing"
            ? "#ef1d2d"
            : "#ff8842";

        const statusStyle = {
          display: "inline-block",

          width: "12px",

          height: "12px",

          borderRadius: "50%",

          backgroundColor: statusColor,

          marginRight: "5px",

          fontWeight: "bold",

          marginRight: 6,

          marginLeft: 6,
        };

        return (
          <span>
            <span style={statusStyle}></span>

            <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
          </span>
        );
      },
    },

    {
      field: "itemsQty",

      headerName: <span className="h1ar text-sm ">{t("Items Qty")}</span>,

      type: "number",

      minWidth: 130,

      flex: 0.7,
    },

    {
      field: "total",

      headerName: <span className="h1ar text-sm ">{t("Total")}</span>,

      type: "number",

      minWidth: 130,

      flex: 0.8,
    },

    {
      field: " ",

      flex: 1,

      minWidth: 150,

      headerName: "",

      type: "number",

      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Link to={`/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item.orderNumber,
        itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
        total: t("RAS") + item.totalPrice,
        status: item.status,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full items-center px-8 pt-14">
          <div className="flex flex-wrap lg:flex-nowrap justify-center ">
            <div className="bg-white dark:text-gray-600 dark:bg-white  h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  {" "}
                  <CiMoneyBill size={30} />{" "}
                </div>

                <br />

                <div>
                  <h3 className={"font-bold h0ar text-gray-400"}>
                    {t("Account Balance")}
                  </h3>

                  <h5 className="text-2xl h1ar">
                    {t("SAR")}
                    {availableBalance}
                  </h5>
                </div>
              </div>

              {/*<div className=""> </div>*/}

              <Link to="/dashboard-withdraw-money">
                <div className="mt-6">
                  <button
                    className="h0ar text-white px-3 py-3 rounded-xl "
                    color="white"
                    style={{ background: currentColor }}
                    borderRadius="10px"
                  >
                    {" "}
                    {t("Withdraw Money")}
                  </button>
                </div>
              </Link>
            </div>

            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <MdBorderClear />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {orders && orders.length}
                  </span>

                  <Link to="/dashboard-orders">
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {t("View Orders")}{" "}
                    </span>
                  </Link>
                </p>{" "}
                <p className="text-sm text-gray-400 par mt-1">
                  {t("All Orders")}
                </p>
              </div>

              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{
                    color: "rgb(255, 244, 229)",

                    backgroundColor: "rgb(254, 201, 15)",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <BsBoxSeam />
                </button>

                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {products && products.length}
                  </span>

                  <Link to="/dashboard-products">
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {t("View Products")}
                    </span>
                  </Link>
                </p>

                <p className="text-sm text-gray-400 par mt-1">
                  {t("All Products")}
                </p>
              </div>

              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{
                    color: "rgb(228, 106, 118)",

                    backgroundColor: "rgb(255, 244, 229)",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <MdOutlineLocalOffer />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {events && events.length}
                  </span>

                  <Link to="/dashboard-events">
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {t("View Events")}
                    </span>
                  </Link>
                </p>{" "}
                <p className="text-sm text-gray-400 par mt-1">
                  {t("All Events")}
                </p>
              </div>
            </div>
          </div>
          <br />
          <div className="flex flex-wrap lg:flex-nowrap  gap-2 justify-center">
            <DailySalesChart dailySales={dailySales} />

            <OrdersTypesCharts shopId={seller._id} />
          </div>
          <ThemeProvider theme={theme}>
            <div className="lg:m-2 mt-24 p-2 bg-white rounded-3xl">
              <h3 className="text-[22px] h1ar font-bold text-gray-400 p-5">
                <strong>{t("Latest Orders")}</strong>
              </h3>

              <DataGrid
                rows={row}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                initialState={{
                  ...row.initialState,

                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </ThemeProvider>
        </div>
      )}
    </>
  );
};

export default DashboardHero;
