import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../Charts/Spinner";
import { useTranslation } from "react-i18next";
import { BsImages } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  CategorieCreateClear,
  createCategories,
} from "../../redux/actions/categories";
import {
  HeroImageCreateClear,
  createheroImage,
} from "../../redux/actions/heroImage";
function CreateHeroImage() {
  const { t } = useTranslation();
  const [isSpinner, setIsSpinner] = useState(false);
  const dispatch = useDispatch();
  const [imageShow, setImageShow] = useState([]);
  const [descAr, setDescAr] = useState("");
  const [descEn, setDescEn] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const [image, setImage] = useState("");
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const { successcreate, errorcreate, isLoadingcreate } = useSelector(
    (state) => state.heroImages
  );

  const inmageHandle = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage([file]);
      const imageUrl = [{ url: URL.createObjectURL(file) }];
      setImageShow(imageUrl);
    }
  };
  useEffect(() => {
    if (errorcreate) {
      toast.error(errorcreate);
      setIsSpinner(false);
    }
    if (successcreate) {
      toast.success(
        <span className="h1ar text-sm">
          {t("Hero Image created successfully!")}
        </span>
      );
      setIsSpinner(false);
      setTitleAr("");
      setTitleEn("");
      setDescAr("");
      setDescEn("");
      setImage("");
      setImageShow([]);
    }
    setTimeout(() => {
      dispatch(HeroImageCreateClear());
    }, 6000);
  }, [successcreate, errorcreate]);
  const changeImage = (img) => {
    if (img) {
      const tempUrl = [imageShow[0]];
      const tempImages = [img];

      tempUrl[0] = { url: URL.createObjectURL(img) };
      setImageShow(tempUrl);
      setImage(tempImages);
    }
  };

  const removeImage = () => {
    setImage([]);
    setImageShow([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSpinner(true);
    const newForm = new FormData();
    newForm.append("file", image[0]);
    newForm.append("titleAr", titleAr);
    newForm.append("titleEn", titleEn);
    newForm.append("descAr", descAr);
    newForm.append("descEn", descEn);
    dispatch(createheroImage(newForm));
  };
  return (
    <div className="w-[90%] lg:w-[50%] m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h5 className="text-[30px] font-Poppins h1ar text-center">
        {t("Create Hero Image")}
      </h5>
      {/* create categorie form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div className="pb-4">
          <label className="pb-2 h1ar">
            {t("Arabic Title")} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="titleAr"
            value={titleAr}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setTitleAr(e.target.value)}
            placeholder={t("Enter Arabic Title...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <div>
          <label className="pb-2 h1ar">
            {t("English Title")} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="titleEn"
            value={titleEn}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setTitleEn(e.target.value)}
            placeholder={t("Enter English Title...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <div>
          <label className="pb-2 h1ar">
            {t("English description")} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="descEn"
            value={descEn}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setDescEn(e.target.value)}
            placeholder={t("Enter English description...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <div>
          <label className="pb-2 h1ar">
            {t("Arabic description")} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="descAr"
            value={descAr}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none  h2ar sm:text-sm"
            onChange={(e) => setDescAr(e.target.value)}
            placeholder={t("Enter Arabic description...")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <div>
          <label className="pb-2 h1ar">
            {t("Upload Image")}
            <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            onChange={inmageHandle}
          />

          {imageShow.length > 0 ? (
            <div className="h-[180px] relative">
              <label htmlFor="0">
                <img
                  className="w-full h-full rounded-lg border"
                  src={imageShow[0].url}
                  alt=""
                  style={{
                    borderColor: currentColor,
                  }}
                />
              </label>
              <input
                onChange={(e) => changeImage(e.target.files[0])}
                type="file"
                id="0"
                className="hidden"
              />
              <span
                onClick={removeImage}
                className="p-2 z-10 cursor-pointer hover:shadow-lg hover text-white absolute top-1 right-1 rounded-full"
                style={{ backgroundColor: currentColor }}
              >
                <IoCloseSharp />
              </span>
            </div>
          ) : (
            <label
              className="flex mt-2 justify-center items-center flex-col h-[180px] cursor-pointer border rounded-lg border-dashed hover:border-indigo-500 w-full text-[#d0d2d6]"
              htmlFor="image"
              style={{
                borderColor: currentColor,
              }}
            >
              <span>
                <BsImages size={40} color={currentColor} />
              </span>
              <span
                className="par text-md"
                style={{
                  color: currentColor,
                }}
              >
                {t("Select Image")}
              </span>
            </label>
          )}

          <input
            onChange={inmageHandle}
            className="hidden"
            type="file"
            id="image"
          />

          <br />
          <div className="w-full flex justify-center">
            {isLoadingcreate ? (
              <Spinner word={t("Create Hero Image")} />
            ) : (
              <button
                type="submit"
                className="text-white focus:ring-4 font-medium rounded-xl text-md px-5 py-2.5 h1ar text-center me-2 inline-flex items-center"
                style={{
                  backgroundColor: currentColor,
                  focusRing: currentColor,
                }}
              >
                {t("Create Hero Image")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateHeroImage;
