import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import i18n from "./i18n"
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/stor";
import {I18nextProvider} from "react-i18next"
import { ContextProvider } from './contexts/ContextProvider';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js"
ReactDOM.render(
  <ContextProvider>
  <Provider store={Store}>
  <I18nextProvider i18n={i18n}>
 
    <App />
  
    </I18nextProvider>
  </Provider>
  </ContextProvider>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
reportWebVitals();