import React from 'react'
import styles from '../../styles/styles'
import ShopInfo from "../../components/Shop/ShopInfo.jsx";
import ShopProfileData from "../../components/Shop/ShopProfileData";
import ThemeSettingsButton from '../../components/ThemeSettingsButton.jsx';

const ShopHomePage = () => {
  return (<><ThemeSettingsButton/>
    <div className=" bg-main-bg dark:bg-main-dark-bg">
          <ShopProfileData  isOwner={true} />
 
 
    </div></>
  )
}

export default ShopHomePage
