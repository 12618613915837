import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { deleteUserAddress, updatUserAddress } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Cityes } from "../../static/data.js";
import styles from "../../styles/styles";
const Address = () => {
  const [open, setOpen] = useState(false);

  const [country, setCountry] = useState("المملكة العربية السعودية");

  const [city, setCity] = useState("");

  const [zipCode, setZipCode] = useState();

  const [nearestTeacher, setNearestTeacher] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [addressType, setAddressType] = useState("");

  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const addressTypeData = [
    {
      name: "Default",
    },

    {
      name: "House",
    },

    {
      name: "Office",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      addressType === "" ||
      country === "" ||
      city === "" ||
      building === "" ||
      street === "" ||
      nearestTeacher === ""
    ) {
      toast.warning(
        <span className="h1ar text-sm">{t("Please fill all the fields!")}</span>
      );
    } else {
      dispatch(
        updatUserAddress(
          country,
          city,
          building,
          street,
          nearestTeacher,
          zipCode,
          addressType
        )
      );

      setOpen(false);

      setCountry("");

      setCity("");

      setBuilding("");

      setStreet("");
      setNearestTeacher("");
      setZipCode(null);

      setAddressType("");
    }
  };

  const handleDelete = (item) => {
    const id = item._id;

    dispatch(deleteUserAddress(id));
  };

  return (
    <>
      <div className="w-full relative  items-center rtl:pl-8 ltr:pr-8">
        {open && (
          <div className="fixed w-[80%] lg:w-[50%] xl:w-[50%] h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center ">
            <div className="w-full h-[80vh] bg-white rounded shadow relative overflow-y-scroll">
              <div className="w-full flex justify-end p-3">
                <RxCross1
                  size={30}
                  className="cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>

              <h1 className="text-center text-[25px] h1ar font-Poppins">
                {t("Add New Address")}
              </h1>

              <div className="w-full">
                <form aria-required onSubmit={handleSubmit} className="w-full">
                  <div className="w-full block p-4">
                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">{t("Country")}</label>

                      <label className="block h2ar pb-2">
                        {t("المملكة العربية السعودية")}
                      </label>
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">
                        {t("Choose your City")}
                      </label>

                      <select
                        name=""
                        id=""
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                      >
                        <option className="block pb-2 h2ar " value="">
                          {t("Choose your City")}
                        </option>

                        {Cityes &&
                          Cityes.map((i) => (
                            <option value={i.title} className="h2ar" key={i.id}>
                              {i.title}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">
                        {t("Street Name")}
                      </label>

                      <input
                        type="text"
                        className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 h2ar rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                        placeholder={t("Example: Al Orouba Street")}
                        required
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                      />
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">
                        {t("Building name/number")}
                      </label>

                      <input
                        type="text"
                        className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 h2ar rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                        placeholder={t("Building name or number")}
                        required
                        value={building}
                        onChange={(e) => setBuilding(e.target.value)}
                      />
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">
                        {t("Nearest place")}
                      </label>

                      <input
                        type="text"
                        className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 h2ar rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                        placeholder={t("Example: Galleria Mall")}
                        required
                        value={nearestTeacher}
                        onChange={(e) => setNearestTeacher(e.target.value)}
                      />
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">{t("Zip Code")}</label>

                      <input
                        type="number"
                        className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 h2ar rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                        required
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>

                    <div className="w-full pb-2">
                      <label className="block h1ar pb-2">
                        {t("Address Type")}
                      </label>

                      <select
                        name=""
                        id=""
                        value={addressType}
                        onChange={(e) => setAddressType(e.target.value)}
                        className="appearance-none block w-[90%] px-3 py-2 border border-gray-300 h2ar rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                      >
                        <option
                          desa
                          value=""
                          className="block border h2ar pb-2"
                        >
                          {t("Choose your Address Type")}
                        </option>

                        {addressTypeData &&
                          addressTypeData.map((item) => (
                            <option
                              className="block h2ar pb-2"
                              key={item.name}
                              value={item.name}
                            >
                              {t(item.name)}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className=" w-full  pb-2">
                      <div className="flex w-full items-center justify-center">
                        <button type="submit" className={`${styles.buttoon}`}>
                          {t("Submit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="flex-col w-full items-center justify-between">
          <h1 className="block text-[25px] text-center mt-10 font-[600] text-[#000000ba] dark:text-gray-200 pb-2 h0ar">
            {t("My Addresses")}
          </h1>

          <div className="flex justify-center mt-14 items-center">
            <div className="flex w-full items-center justify-center">
              <button
                type="submit"
                className={`${styles.buttoon}`}
                onClick={() => setOpen(true)}
              >
                {t("Add New")}
              </button>
            </div>
          </div>
        </div>

        <br />

        {user &&
          user.addresses.map((item, index) => (
            <div
              className="w-full bg-white h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5"
              key={index}
            >
              <div className="flex items-center">
                <h5 className="pl-5 h1ar font-[600]">{t(item.addressType)}</h5>
              </div>

              <div className="pl-8 flex items-center">
                <h6 className="text-[12px] par 800px:text-[unset]">
                  {item.address1} {item.address2}
                </h6>
              </div>

              <div className="pl-8 flex items-center">
                <h6 className="text-[12px]  par 800px:text-[unset]">
                  {user && user.phoneNumber}
                </h6>
              </div>

              <div className="min-w-[10%] flex items-center justify-between pl-8">
                <AiOutlineDelete
                  size={25}
                  className="cursor-pointer"
                  onClick={() => handleDelete(item)}
                />
              </div>
            </div>
          ))}

        {user && user.addresses.length === 0 && (
          <h5 className="text-center pt-8 par dark:text-gray-300 text-[18px]">
            {t("You not have any saved address!")}
          </h5>
        )}
      </div>
    </>
  );
};
export default Address;
