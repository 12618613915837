import React, { useState, useRef } from "react";

import {
  AiOutlineArrowRight,
  AiOutlineCamera,
  AiOutlineDelete,
} from "react-icons/ai";

import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

import { FaRegFilePdf } from "react-icons/fa";

import styles from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";

import { backend_url, server } from "../../server";

import { DataGrid } from "@mui/x-data-grid";

import { Button } from "@material-ui/core";

import { Link } from "react-router-dom";

import { MdTrackChanges } from "react-icons/md";

import { RxCross1 } from "react-icons/rx";

import {
  deleteUserAddress,
  loadUser,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";

import { CSVLink } from "react-csv";

import { useReactToPrint } from "react-to-print";

import { Country, State } from "country-state-city";

import { useEffect } from "react";

import { toast } from "react-toastify";

import axios from "axios";

import { getAllOrdersOfUser } from "../../redux/actions/order";

import { useTranslation } from "react-i18next";

import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "@mui/material/styles";

import { useStateContext } from "../../contexts/ContextProvider";

import ProfileSideBar from "./ProfileSidebar";

const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);

  const [name, setName] = useState(user && user.name);

  const [email, setEmail] = useState(user && user.email);

  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);

  const [password, setPassword] = useState("");

  const [avatar, setAvatar] = useState(null);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      toast.error(<span className="h1ar text-sm">{t(error)}</span>);

      dispatch({ type: "clearErrors" });
    }

    if (successMessage) {
      toast.success(<span className="h1ar text-sm">{t(successMessage)}</span>);

      dispatch({ type: "clearMessages" });
    }
  }, [error, successMessage]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };

  const handleImage = async (e) => {
    const file = e.target.files[0];

    setAvatar(file);

    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    await axios

      .put(`${server}/user/update-avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        withCredentials: true,
      })

      .then((response) => {
        dispatch(loadUser());

        toast.success(
          <span className="h1ar text-sm">
            {t("avatar updated successfully!")}
          </span>
        );
      })

      .catch((error) => {
        toast.error(<span className="h1ar text-sm">{t(error)}</span>);
      });
  };

  return (
    <div className="w-full px-7  bg-main-bg dark:bg-main-dark-bg">
      {/* profile */}

      <>
        {" "}
        <div className="m-2 lg:m-10 p-2 md:p-10 bg-white rounded-3xl">
          <div className="flex justify-center w-full">
            <div className="relative">
              <img
                src={`${backend_url}${user?.avatar}`}
                className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                alt=""
              />

              <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                <input
                  type="file"
                  id="image"
                  className="hidden"
                  onChange={handleImage}
                />

                <label htmlFor="image">
                  <AiOutlineCamera />
                </label>
              </div>
            </div>
          </div>

          <br />

          <br />

          <div className="w-full px-5">
            <form onSubmit={handleSubmit} aria-required={true}>
              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 h1ar">{t("Full Name")}</label>

                  <input
                    type="text"
                    className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block  pb-2 h1ar">
                    {t("Email Address")}
                  </label>

                  <input
                    type="text"
                    className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 h1ar">{t("Phone Number")}</label>

                  <input
                    type="number"
                    className={`appearance-none block w-[90%] px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                <div className="pb-3 w-[100%] 800px:w-[50%]">
                  <label className="block pb-2 h1ar">
                    {t("Enter your password")}
                  </label>

                  <input
                    type="password"
                    className={`appearance-none block w-[90%]  py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm `}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex  w-full items-center justify-center">
                <button type="submit" className={`${styles.buttoon}`}>
                  {t("Update")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </>

      {/* order */}

      {/* {active === 2 && (

        <div>

          <AllOrders />

        </div>

    )}*/}

      {/*  user Address */}
    </div>
  );
};

{
  /*

const AllOrders = () => {

  const { user } = useSelector((state) => state.user);

  const { orders } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  const {t}=useTranslation()

  const componentPDF =useRef();

  useEffect(() => {

    dispatch(getAllOrdersOfUser(user._id));

  }, []);



  const columns = [

    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },



    {

      field: "status",

      headerName: "Status",

      minWidth: 130,

      flex: 0.7,

      cellClassName: (params) => {

        return params.getValue(params.id, "status") === "Delivered"

          ? "greenColor"

          : "redColor";

      },

    },

    {

      field: "itemsQty",

      headerName: "Items Qty",

      type: "number",

      minWidth: 130,

      flex: 0.7,

    },



    {

      field: "total",

      headerName: "Total",

      type: "number",

      minWidth: 130,

      flex: 0.8,

    },



    {

      field: "Preview",

      flex: 1,

      minWidth: 150,

      headerName: "",

      type: "number",

      sortable: false,

      renderCell: (params) => {

        return (

          <>

            <Link to={`/order/${params.id}`}>

              <Button className="rounded-full" 

                 style={{ background: "linear-gradient(144deg, #b91a5c, #5d0c8a 50%, #5d0c8a)" }}>

              <AiOutlineEye

              style={{color:"#fff"}}

              size={20} />

              </Button>

            </Link>

          </>

        );

      },

    },

  ];



  const row = [];



  orders &&

    orders.forEach((item) => {

      row.push({

        id: item._id,

        itemsQty: item.cart.length,

        total:t("RAS") + item.totalPrice,

        status: item.status,

      });

    });

    const genratePDF=useReactToPrint({

      content:()=>componentPDF.current,

        documentTitle:"AllOrders",

        onAfterPrint:()=>alert("Data saved in PDF")  });

  return (

    <div className="ltr:pl-8 rtl:pr-8 pt-5">

    <>

  <frameElement className="flex-col w-full items-center px-8 pt-14">

<div className="flex items-center mx-8 mt-4 ">

<button className=" rounded-md -primaryColor text-gray-100 py-1 px-3 mx-2"

onClick={genratePDF}



> 

PDF

</button>

<div className=" rounded-md -primaryColor text-gray-100 py-1 px-3 mx-2"



>

  {orders?(

<CSVLink

data={orders}>

Exel

</CSVLink>):(null)}

</div>

</div>

<br />

  <div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">

    <DataGrid

      rows={row}

      columns={columns}

      pageSize={10}

      disableSelectionOnClick

      autoHeight

    />

  </div></frameElement>

</>

  </div>

  );

};*/
}

export default ProfileContent;
