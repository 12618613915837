import React from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { FiSettings } from "react-icons/fi";
const ThemeSettingsButton = () => {

    const { currentColor,setThemeSettings } = useStateContext();
    return (
      <div className="fixed right-4 bottom-4" style={{ zIndex: '1000' }}>
        <div content="Settings" position="Top">
    
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{ background: currentColor, borderRadius: '50%' }}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <FiSettings />
            </button>
     
        </div>
      </div>
  
    );
   
  };
  export default ThemeSettingsButton;