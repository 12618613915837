import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Loader from "../components/Layout/Loader";


const Sellerability = ({ children }) => {
    const { seller } = useSelector((state) => state.seller);
    
 ;

    if (seller.status==="pending") {
      return <Navigate to={`/shop-deactive`} replace />;
      
    }
    return children;
  }

export default Sellerability;