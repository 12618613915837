import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import styles from "../../styles/styles";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { AiOutlineDelete } from "react-icons/ai";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import Spinner from "../Charts/Spinner"
const WithdrawMoney = () => {
const [spinner ,setSpinner]=useState(false)
const[create,setCreate]=useState(false)  
const { t} = useTranslation();
  const {currentColor}=useStateContext();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isWithdrawClicked, setIsWithdrawClicked] = useState(false);
  const { seller } = useSelector((state) => state.seller);
  const [paymentMethod, setPaymentMethod] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(50);
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankCountry: "",
    bankSwiftCode: null,
    bankAccountNumber: null,
    bankHolderName: "",
    bankAddress: "",
  });

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
setCreate(true)
    const withdrawMethod = {
      bankName: bankInfo.bankName,
      bankCountry: bankInfo.bankCountry,
      bankSwiftCode: bankInfo.bankSwiftCode,
      bankAccountNumber: bankInfo.bankAccountNumber,
      bankHolderName: bankInfo.bankHolderName,
      bankAddress: bankInfo.bankAddress,
    };

    setPaymentMethod(false);

    await axios
      .put(
        `${server}/shop/update-payment-methods`,
        {
          withdrawMethod,
        },
        { withCredentials: true }
      )
      .then((res) => {
setCreate(false)        
toast.success("Withdraw method added successfully!");
        dispatch(loadSeller());
        setBankInfo({
          bankName: "",
          bankCountry: "",
          bankSwiftCode: null,
          bankAccountNumber: null,
          bankHolderName: "",
          bankAddress: "",
        });
      })
      .catch((error) => {
        console.log(error.response.data.message);
setCreate(false)
      });
  };

  const deleteHandler = async () => {
    await axios
      .delete(`${server}/shop/delete-withdraw-method`, {
        withCredentials: true,
      })
      .then((res) => {
        toast.success("Withdraw method deleted successfully!");
        dispatch(loadSeller());
      });
  };

  const error = () => {
    toast.error("You not have enough balance to withdraw!");
  };


  // ...
  
  
  
  const withdrawHandler = async () => {
  setSpinner(true)
 if (isWithdrawClicked) {
      return; // إذا تم النقر بالفعل على الزر، لا تقم بأي إجراء إضافي
    }
  
    if (withdrawAmount < 50 || withdrawAmount > availableBalance) {
      toast.error("You can't withdraw this amount!");
setSpinner(false)
    } else {
      setIsWithdrawClicked(true); // تعيين قيمة true للمتغير بعد النقرة الأولى
  setSpinner(true)
      const amount = withdrawAmount;
      try {
        const res = await axios.post(
          `${server}/withdraw/create-withdraw-request`,
          { amount },
          { withCredentials: true }
        );
        toast.success("Withdraw money request is successful!");
setSpinner(false)      } catch (error) {
        // معالجة أي أخطاء في الطلب
      } finally {
        setIsWithdrawClicked(false); // إعادة تعيين قيمة المتغير للسماح بالنقر مرة أخرى بعد الانتهاء
      }
    }
  };

  const availableBalance = seller?.availableBalance.toFixed(2);

  return (
    <div className="w-full h-[90vh] p-8">
      <div className="w-full bg-white h-full rounded flex items-center justify-center flex-col rtl:h1ar ">
        <h5 className="text-[20px] pb-4">
        {t("Available Balance")}:{t("SAR")}{"("}{availableBalance}{")"}
        </h5>
        <div
          className="w-[150px] h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer text-white rtl:h0ar "
          style={{background:currentColor}}
          onClick={() => (availableBalance < 50 ? error() : setOpen(true))}
        >
          {t("Withdraw")}
        </div>
      </div>
      {open && (
        <div className="w-full h-screen z-[9999] fixed top-0 left-0 flex items-center justify-center bg-[#0000004e]">
          <div
            className={`w-[95%] 800px:w-[50%] bg-white shadow rounded-3xl ${
              paymentMethod ? "h-[80vh] overflow-y-scroll" : "h-[unset]"
            } min-h-[40vh] p-3`}
          >
            <div className="w-full flex justify-end">
              <RxCross1
                size={25}
                onClick={() => setOpen(false) || setPaymentMethod(false)}
                className="cursor-pointer"
              />
            </div>
            {paymentMethod ? (
              <div>
                <h3 className="text-[22px] font-Poppins text-center rtl:h1ar font-[600] h1ar">
                  {t("Add new Withdraw Method")}:
                </h3>
                <form onSubmit={handleSubmit}>
                  <div>
                    <label className="h1ar font-[600]">
                     {t("Bank Name")} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankName}
                      onChange={(e) =>
                        setBankInfo({ ...bankInfo, bankName: e.target.value })
                      }
                      id=""
                      placeholder={t("Enter your Bank name!")}
                      className={`${styles.input} h2ar mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label className="h1ar font-[600]" >
                     {t("Bank Country")} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      value={bankInfo.bankCountry}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankCountry: e.target.value,
                        })
                      }
                      id=""
                      required
                      placeholder={t("Enter your bank Country!")}
                      className={`${styles.input} h2ar mt-2`}
                    />
                  </div>
         
                  <div className="pt-2">
                    <label className="h1ar font-[600]" >
                     {t("Bank Account Number")}{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name=""
                      id=""
                      value={bankInfo.bankAccountNumber}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankAccountNumber: e.target.value,
                        })
                      }
                      required
                      placeholder={t("Enter your bank account number!")}
                      className={`${styles.input} h2ar mt-2`}
                    />
                  </div>
                  <div className="pt-2">
                    <label className="h1ar font-[600]">
                      {t("Bank Holder Name")}<span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      value={bankInfo.bankHolderName}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankHolderName: e.target.value,
                        })
                      }
                      id=""
                      placeholder={t("Enter your bank Holder name!")}
                      className={`${styles.input} h2ar mt-2`}
                    />
                  </div>

                  <div className="pt-2">
                    <label className="rtl:h1ar font-[600]">
                      {t("Bank Address")} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      required
                      id=""
                      value={bankInfo.bankAddress}
                      onChange={(e) =>
                        setBankInfo({
                          ...bankInfo,
                          bankAddress: e.target.value,
                        })
                      }
                      placeholder={t("Enter your bank address!")}
                      className={`${styles.input} mt-2`}
                    />
                  </div>

                  <button
                    type="submit"
                    className={`${styles.button} mb-3 text-white`}
                    style={{background:currentColor}}
                  >
                    {create===true? <Spinner color="fill-gray-600" />:t("Add")}
                  </button>
                </form>
              </div>
            ) : (
              <>
                <h3 className="text-[22px] font-Poppins rtl:h1ar">
                  {t("Available Withdraw Methods")}:
                </h3>

                {seller && seller?.withdrawMethod ? (
                  <div>
                    <div className="800px:flex w-full justify-between items-center">
                      <div className="800px:w-[50%]">
                        <h5 className="rtl:h1ar font-[600]" >
                          {t("Account Number")}:{" "}
                          {"*".repeat(
                            seller?.withdrawMethod.bankAccountNumber.length - 3
                          ) +
                            seller?.withdrawMethod.bankAccountNumber.slice(-3)}
                        </h5>
                        <h5>{t("Bank Name")}: {seller?.withdrawMethod.bankName}</h5>
                      </div>
                      <button className="bg-red-600 rounded-lg  py-1 px-2 800px:w-[50%]">
                        <AiOutlineDelete
                          size={25}
                          className="cursor-pointer"
                          onClick={() => deleteHandler()}
style={{color:"#fff"}}
                        />
                      </button>
                    </div>
                    <br />
                    <h4>{t("Available Balance")}: {availableBalance}{t("SAR")}</h4>
                    <br />
                    <div className="800px:flex w-full items-center">
                      <input
                        type="number"
                        placeholder={t("Amount...")}
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        className="800px:w-[100px] w-[full] border 800px:mr-3 p-1 rounded"
                      />
                      {spinner ===true ?(<Spinner word={t("Withdraw")}/>
              ):(<button
                type="submit"
                className="text-white h1ar  rounded-xl text-md px-5 py-2.5 text-center me-2  inline-flex items-center"
style={{backgroundColor:currentColor,
  focusRing:currentColor
}} onClick={withdrawHandler}
              >
{t("Withdraw")}
              </button>)}
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-[18px] pt-2 rtl:h2ar">
                      {t("No Withdraw Methods available")}!
                    </p>
                    <div className="w-full flex items-center">
                      <div
                        className={`${styles.button} w-[150px] text-[#fff] text-[18px] mt-4`}
                        onClick={() => setPaymentMethod(true)}
                        style={{background:currentColor}}
                      >
                        {t("Add new")}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WithdrawMoney;
