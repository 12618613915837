import axios from "axios";
import { server } from "../../server";
export const gitAllShipings = () => async (dispatch) => {
  try {
    dispatch({
      type: "gitAllShipingsRequest",
    });

    const { data } = await axios.get(`${server}/shiping/get-Allshiping`);
    dispatch({
      type: "gitAllShipingsSuccess",
      payload: data.shipings,
    });
  } catch (error) {
    dispatch({
      type: "gitAllShipingsFailed",
      payload: error.response.data.message,
    });
  }
};
export const getShiping = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getShipingRequest",
    });
    console.log("id", id);
    const { data } = await axios.get(`${server}/shiping/get-shiping/${id}`, {
      withCredentials: true,
    });
    dispatch({
      type: "getShipingSuccess",
      payload: data.shiping,
    });
  } catch (error) {
    dispatch({
      type: "getShipingFailed",
      payload: error.response.data.message,
    });
  }
};
export const getCShiping = () => async (dispatch) => {
  try {
    dispatch({
      type: "getCShipingRequest",
    });
    const { data } = await axios.get(`${server}/shiping/get-Cshiping/`, {
      withCredentials: true,
    });
    dispatch({
      type: "getCShipingSuccess",
      payload: data.Cshiping,
    });
  } catch (error) {
    dispatch({
      type: "getCShipingFailed",
      payload: error.response.data.message,
    });
  }
};
export const getEShiping = () => async (dispatch) => {
  try {
    dispatch({
      type: "getEShipingRequest",
    });

    const { data } = await axios.get(`${server}/shiping/get-Eshiping/`, {
      withCredentials: true,
    });
    dispatch({
      type: "getEShipingSuccess",
      payload: data.Eshiping,
    });
  } catch (error) {
    dispatch({
      type: "getEShipingFailed",
      payload: error.response.data.message,
    });
  }
};
export const shipingUpdateClear = () => async (dispatch) => {
  dispatch({
    type: "shipingUpdateClear",
  });
};
