import axios from "axios";
import { server } from "../../server";

////gey product for shop

export const getProductforShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getProductforShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-product-for-shop/${id}`
    );

    dispatch({
      type: "getProductforShopSuccess",

      payload: data.shopProduct,
    });
  } catch (error) {
    dispatch({
      type: "getProductforShopFailed",

      payload: error.response.data.message,
    });
  }
};

// create product
export const createProduct = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "productCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/product/create-product`,
      newForm,
      config
    );
    dispatch({
      type: "productCreateSuccess",
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: "productCreateFail",
      payload: error.response.data.message,
    });
  }
};
//filltr
export const filterProducts =
  (
    name,
    currentRatings,
    currentCategory,
    minPrice,
    maxPrice,
    pageNumber,
    pageSize
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "filterProductsRequest",
      });

      const queryParams = new URLSearchParams({
        name: name,
        ratings: currentRatings,
        category: currentCategory,
        minPrice: minPrice,
        maxPrice: maxPrice,
        page: pageNumber,
        pageSize: pageSize,
      });

      const { data } = await axios.get(
        `${server}/product/filter-products?${queryParams}`
      );
      dispatch({
        type: "filterProductsSuccess",
        payload: data.products,
        totalPages: data.totalPages,
      });
    } catch (error) {
      dispatch({
        type: "filterProductsFailed",
        payload: error.response.data.message,
      });
    }
  };
///category//////////////
export const getcategoryProducts =
  (category, pageNumber, pageSize) => async (dispatch) => {
    try {
      dispatch({
        type: "categoryProductsRequest",
      });
      const queryParams = new URLSearchParams({
        category: category,

        page: pageNumber,

        pageSize: pageSize,
      });

      const { data } = await axios.get(
        `${server}/product/category-products?${queryParams}`
      );

      dispatch({
        type: "categoryProductsSuccess",

        payload: data.products,

        totalPages: data.totalPages,
      });
    } catch (error) {
      dispatch({
        type: "categoryProductsFailed",

        payload: error.response.data.message,
      });
    }
  };

// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });

    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};
//best selling
export const bestSelling = (pageSize, pageNumber) => async (dispatch) => {
  try {
    dispatch({
      type: "bestSillngRequest",
    });

    const queryParams = new URLSearchParams({
      page: pageNumber,

      pageSize: pageSize,
    });

    const { data } = await axios.get(
      `${server}/product/best-selling?${queryParams}`
    );

    dispatch({
      type: "bestSillngSuccess",

      payload: data.products,

      totalPages: data.totalPages,
    });
  } catch (error) {
    dispatch({
      type: "bestSillngFailed",

      payload: error.response.data.message,
    });
  }
};
//featured products
export const featuredproducts = (pageSize, pageNumber) => async (dispatch) => {
  try {
    dispatch({
      type: "featuredproductsRequest",
    });

    const queryParams = new URLSearchParams({
      page: pageNumber,

      pageSize: pageSize,
    });

    const { data } = await axios.get(
      `${server}/product/featured-products?${queryParams}`
    );

    dispatch({
      type: "featuredproductsSuccess",

      payload: data.products,

      totalPages: data.totalPages,
    });
  } catch (error) {
    dispatch({
      type: "featuredproductsFailed",

      payload: error.response.data.message,
    });
  }
};

// delete product of a shop
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteProductRequest",
    });
    console.log("id", id);
    const { data } = await axios.delete(
      `${server}/product/delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteProductFailed",
      payload: error.response.data.message,
    });
  }
};

// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });

    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};
// delete product of a shop
export const AdmindeleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "AdmindeleteProductRequest",
    });

    console.log("id", id);
    const { data } = await axios.delete(
      `${server}/product/Admin-delete-shop-product/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "AdmindeleteProductSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "AdmindeleteProductFailed",
      payload: error.response.data.message,
    });
  }
};
