import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Pagination from "../components/Pagination"
import { getcategoryProducts } from "../redux/actions/product";
const CategoryProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categorydata = searchParams.get("category");
  const {categoryProducts,totalPages,isCategoryLoding} = useSelector((state) => state.products);
const [pageNumber,setPageNumber]=useState(0)
const [pageSize,setPageSize]=useState(10)
const dispatch = useDispatch();
  
  useEffect(() => {
   dispatch(getcategoryProducts(categorydata,pageNumber,pageSize));
      
     //    window.scrollTo(0,0);
  }, [categorydata,,pageNumber,pageSize]);
 
const handlePageChange = ({selected}) => {
    setPageNumber(selected);   };   
 
  
  return (
  <>
  {
    isCategoryLoding ? (
      <Loader />
    ) : (
      <div className="dark:bg-main-dark-bg bg-main-bg">
      <Header activeHeading={3} />
      <br />
      <br />

      <div className={`${styles.section}`}>
        <div className="grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {categoryProducts && categoryProducts.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        {categoryProducts && categoryProducts.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No products Found!
          </h1>
        ) : null}
<Pagination currentPage={pageNumber}  totalPages={totalPages}
onPageChange={handlePageChange}/>
      </div>
      <Footer />
    </div>
    )
  }
  </>
  );
};

export default CategoryProductsPage;
