const TopInvice=({titleAr,titleEn,value,noborder,borderl,borderr})=>{
  return(
      <div className={` ${noborder !==true && "border-b border-black"} items-center ${borderr ===true && "border-r"} ${borderl ===true && "border-l"} flex justify-between`}>
            <div className=" w-[50%] items-center p-1 flex-col justify-center">
            <h5 className="par text-sm text-center">{titleEn}</h5>
<h5 className="par text-sm text-center">{titleAr}  </h5>
</div>
                    <div className=" w-[50%]  items-center p-2 flex justify-center">
 <span className="text-sm par  text-gray-800 ">{value}</span></div>

        </div>
    )
}
export default TopInvice