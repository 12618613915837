import { React, useState } from "react";
import styles from "../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import ThemeSettingsButton from "../components/ThemeSettingsButton";
import { useTranslation } from "react-i18next";
function ForgetPasswordPage() {
  const navigate = useNavigate();
  const {t}=useTranslation()
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmail(email)
     await axios
      .post(
        `${server}/user/check-email`,
      
         {email:email}
        
      )
      .then((res) => {
        toast.success(res.data.message);
      window.location.reload(true); 
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

 return (
  <><ThemeSettingsButton/>
  <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
<div className="sm:mx-auto sm:w-full sm:max-w-md">
<Link to="/"><h4 className="my-4 text-center text-xl font-extrabold text-[#b91a5c] h2ar">{t("Return to the homepage")}</h4></Link>
  <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 h0ar">
{t("Forget password")}
  </h2>
</div>
<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
  <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700 par"
        >
          {t("Email")} 
        </label>
        <div className="mt-1">
          <input
            type="email"
            name="email"
            autoComplete="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="appearance-none text-left block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
          />
        </div>
      </div>
      <div>

        
      </div>
      
        
      <div>
        <button
          type="submit"
          className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white --btn-primary hover:bg-pink-700 h1ar"
        >
         {t("Chick")} 
        </button>
      </div>
       <div className={`${styles.noramlFlex} w-full`}>

        <Link to="/sign-up" className="text-[#b91a5c] ltr:pr-2 rtl:pl-2 text-sm par">
        {t("Sign Up")}
        </Link>
<h3 className="par"> {t("Don't have an account?")}</h3>
      </div>
    </form>
  </div>
</div>
</div></>
  );
};

export default ForgetPasswordPage