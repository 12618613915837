import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styles from '../../styles/styles'
import EventCard from "./EventCard";
import { useTranslation } from 'react-i18next';

const Events = () => {
  const {allEvents,isLoading} = useSelector((state) => state.events);  
   const{t}=useTranslation()
  return (
    <div>
     {
      !isLoading && (
        <div className={`${styles.section}`}>
      <div className={`${styles.heading}`}>
        <h1>{t("Popular Events")}</h1>
      </div>

      <div className="w-full grid">
         {
          allEvents.length !== 0 && (
            <EventCard data={allEvents && allEvents[0]} />
          )
         }
         <h4 className='text-center h1ar text-red-600'>{
           allEvents?.length === 0 && (
            t("No Events have!")
           )
          }

         </h4>
      </div>
     
    </div>
      )
     }
  </div>
  )
}

export default Events