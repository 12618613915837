import React,{useEffect} from 'react'
import Login from "../components/Login/Login.jsx"
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux"
import ThemeSettingsButton from '../components/ThemeSettingsButton.jsx';
function Loginpage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if(isAuthenticated === true){
      navigate("/");
    }
  }, [])
  return (
    <>
      <Login />
    </>
  )
}

export default Loginpage
