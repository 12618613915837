import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../server";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import styles from "../styles/styles";
import Loader from "../components/Layout/Loader";
import SmallSpinner from "../components/Charts/SmallSpinner";
import { AiOutlineEdit } from "react-icons/ai";
import Spinner from "../components/Charts/Spinner";
import axios from "axios";
import { getHeroImage } from "../redux/actions/heroImage";
function EditeHeroImagePage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { currentColor } = useStateContext();
  const [descAr, setDescAr] = useState("");
  const [descEn, setDescEn] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [isSpinnerEdit, setIsSpinnerEdit] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [titleEn, setTitleEn] = useState("");
  const { heroImage, isLoadingh } = useSelector((state) => state.heroImages);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHeroImage(id));
  }, []);
  const updateHandler = async (e) => {
    e.preventDefault();
    setIsSpinner(true);
    await axios
      .put(
        `${server}/heroImage/update-heroImage/${id}`,
        {
          titleAr,
          titleEn,
          descAr,
          descEn,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(
          <span className="h1ar text-sm">
            {t("Hero Image updated succesfully!")}
          </span>
        );

        setIsSpinner(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setIsSpinner(false);
      });
  };

  const handleImageChange = async (newImage) => {
    setIsSpinnerEdit(true);
    const formData = new FormData();

    formData.append("newImage", newImage);

    try {
      const data = await axios.put(
        `${server}/heroImage/update-heroImage-image/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          withCredentials: true,
        }
      );

      toast.success(
        <span className="h1ar text-sm">{t("تم تحديث الصورة بنجاح!")}</span>
      );

      setIsSpinnerEdit(false);

      dispatch(getHeroImage(id));
    } catch (error) {
      toast.error(error);
      setIsSpinnerEdit(false);
    }
  };
  return (
    <>
      {isLoadingh ? (
        <Loader />
      ) : (
        <div className="w-full min-h-screen flex flex-col bg-main-bg dark:bg-main-dark-bg items-center">
          <div className="mb-7 pt-3">
            <nav className="flex absolute   right-2">
              <ol role="list" class="flex items-center">
                <li class="text-left">
                  <div class="-m-1">
                    <Link
                      to="/dashboard"
                      className="rounded-md p-1 text-sm h2ar font-medium text-gray-600 dark:text-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      {" "}
                      {t("Dashboard")}{" "}
                    </Link>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>

                    <div class="-m-1">
                      <Link
                        to="/dashboard-products"
                        class="rounded-md h2ar  p-1 text-sm font-medium text-gray-600 dark:text-gray-400 focus:text-gray-900 focus:shadow hover:text-gray-800"
                      >
                        {" "}
                        {t("All Hero Image")}{" "}
                      </Link>
                    </div>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>

                    <div class="-m-1">
                      <a
                        href="#"
                        class="rounded-md p-1 h2ar  text-sm font-medium text-gray-600 dark:text-gray-200 dark:border-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                        aria-current="page"
                      >
                        {" "}
                        {t("Edit Hero Image")}{" "}
                      </a>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="flex w-full shadow-lg  bg-white rounded-lg 800px:w-[80%] flex-col justify-center my-5">
            <div className="flex px-4 flex-col items-center">
              {/*/!   images  ////////////////////////////////// */}
              <div className="w-full px-4 py-5   flex items-center justify-center">
                <div className="relative h-30">
                  <label className="aspect-square overflow-hidden rounded-lg">
                    <img
                      className="w-full h-full object-cover rounded-lg"
                      src={`${backend_url}${heroImage?.image}`}
                      alt=""
                      style={{
                        borderColor: currentColor,
                      }}
                    />
                  </label>
                  {isSpinnerEdit == true ? (
                    <div
                      className="bottom-1 p-1 absolute z-10 "
                      style={{ backgroundColor: currentColor }}
                    >
                      <SmallSpinner fill={"fill-gray-400"} />
                    </div>
                  ) : (
                    <label
                      className="bottom-1 center justify-center z-10 cursor-pointer hover:shadow-lg hover p-1 text-white absolute rounded-full"
                      style={{ backgroundColor: currentColor }}
                    >
                      <input
                        onChange={(e) => handleImageChange(e.target.files[0])}
                        type="file"
                        className="hidden"
                      />
                      <AiOutlineEdit size={25} />
                    </label>
                  )}{" "}
                </div>
              </div>
              {/* details ////////////////////////////////////////// */}
            </div>
            <form
              aria-aria-required={true}
              className="flex px-4 flex-col items-center"
              onSubmit={updateHandler}
            >
              {" "}
              <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                <div className="w-full pl-[3%]">
                  <label className="block h1ar pb-2">{t("Arabic Title")}</label>
                </div>

                <input
                  type="text"
                  placeholder={`${heroImage?.titleAr}`}
                  value={titleAr}
                  onChange={(e) => setTitleAr(e.target.value)}
                  className={`${styles.input} !w-[95%] h1ar mb-4 800px:mb-0`}
                  required
                  style={{ borderColor: "#d1d5db" }}
                  onFocus={(e) => (e.target.style.borderColor = currentColor)}
                  onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                />
              </div>
              <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                <div className="w-full pl-[3%]">
                  <label className="block h1ar pb-2">
                    {t("English Title")}
                  </label>
                </div>

                <input
                  type="text"
                  placeholder={`${heroImage?.titleEn}`}
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                  className={`${styles.input} !w-[95%] h1ar mb-4 800px:mb-0`}
                  required
                  style={{ borderColor: "#d1d5db" }}
                  onFocus={(e) => (e.target.style.borderColor = currentColor)}
                  onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                />
              </div>
              <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                <div className="w-full pl-[3%]">
                  <label className="block h1ar pb-2">
                    {t("Arabic description")}
                  </label>
                </div>

                <input
                  type="text"
                  placeholder={`${heroImage?.descAr}`}
                  value={descAr}
                  onChange={(e) => setDescAr(e.target.value)}
                  className={`${styles.input} !w-[95%] h1ar mb-4 800px:mb-0`}
                  required
                  style={{ borderColor: "#d1d5db" }}
                  onFocus={(e) => (e.target.style.borderColor = currentColor)}
                  onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                />
              </div>
              <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                <div className="w-full pl-[3%]">
                  <label className="block h1ar pb-2">
                    {t("English description")}
                  </label>
                </div>

                <input
                  type="text"
                  placeholder={`${heroImage?.descEn}`}
                  value={descEn}
                  onChange={(e) => setDescEn(e.target.value)}
                  className={`${styles.input} !w-[95%] h1ar mb-4 800px:mb-0`}
                  required
                  style={{ borderColor: "#d1d5db" }}
                  onFocus={(e) => (e.target.style.borderColor = currentColor)}
                  onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                />
              </div>
              <div className="my-5">
                {isSpinner ? (
                  <Spinner word={t("Update Categorie")} />
                ) : (
                  <button
                    type="submit"
                    className="text-white h1ar

 focus:ring-4 font-medium rounded-xl

 text-md px-5 py-2.5 text-center me-2  inline-flex items-center"
                    style={{
                      backgroundColor: currentColor,

                      focusRing: currentColor,
                    }}
                  >
                    {t("Update Hero Image")}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default EditeHeroImagePage;
