import React from "react";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Lottie from "react-lottie";
import animationData from "../Assests/animations/107043-success.json";
import styles from "../styles/styles";
import { useTranslation } from "react-i18next";
const OrderSuccessPage = () => {
  const{t}=useTranslation();
  
  return (
    <div>
      <Header />
      <Success />
      <Footer />
    </div>
  );
};

const Success = () => {
  const navigate = useNavigate();
  const{t}=useTranslation();
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
<div className="flex-col items-center justify-center">
  <Lottie options={defaultOptions} width={300} height={300} />
  <h5 className="text-center mb-14 text-[25px] text-[#000000a1]">
    😍تم تنفيذ طلبك بنجاح
  </h5>
 

  <div className="flex items-center justify-center w-full px-auto">
    <button
      className={`${styles.buttoon} px-4 py-3 mt-10`}
      onClick={() => navigate("/")}
    >
      <h5 className="text-white h1ar">
        <strong>{t("Return to the home page")}</strong>
      </h5>
    </button>
     <br />
       <br />
  </div>
</div>
  );
};

export default OrderSuccessPage;
