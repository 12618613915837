import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../Charts/Spinner";
import { createProduct } from "../../redux/actions/product";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { BsCheck, BsImages } from "react-icons/bs";
import { IoCloseSharp } from "react-icons/io5";
import { useStateContext } from "../../contexts/ContextProvider";
const CreateProduct = () => {
  const [isSpinner, setIsSpinner] = useState(false);
  const [imageShow, setImageShow] = useState([]);
  const inmageHandle = (e) => {
    const files = e.target.files;
    const length = files.length;
    if (length > 0) {
      setImages([...images, ...files]);
      let imageUrl = [];
      for (let i = 0; i < length; i++) {
        imageUrl.push({ url: URL.createObjectURL(files[i]) });
      }
      setImageShow([...imageShow, ...imageUrl]);
    }
  };

  const changeImage = (img, index) => {
    if (img) {
      let tempUrl = imageShow;
      let tempImages = images;
      tempImages[index] = img;
      tempUrl[index] = { url: URL.createObjectURL(img) };
      setImageShow([...tempUrl]);
      setImages([...tempImages]);
    }
  };

  const removeImage = (i) => {
    const filterImage = images.filter((img, index) => index !== i);

    const filterImageUrl = imageShow.filter((img, index) => index !== i);

    setImages(filterImage);

    setImageShow(filterImageUrl);
  };

  const { seller } = useSelector((state) => state.seller);

  const { successcreate, errorcrearte } = useSelector(
    (state) => state.products
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentColor, currentDir } = useStateContext();
  const [images, setImages] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [viewSize, setViewSize] = useState([]);
  const [viewColors, setViewColors] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [sku, setSku] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    if (errorcrearte) {
      if (
        errorcrearte ===
        `MongoServerError: E11000 duplicate key error collection: revo.products index: sku_1 dup key: { sku: ${sku} }`
      ) {
        toast.error("الذي أدخلتة موجود مسبقا sku ال");
        setIsSpinner(false);
        dispatch({
          type: "productCreateClear",
        });
      } else {
        toast.error(errorcrearte);

        setIsSpinner(false);
        dispatch({
          type: "productCreateClear",
        });
      }
    }

    if (successcreate) {
      toast.success(
        <span className="h1ar text-sm">
          {t("Product created successfully!")}
        </span>
      );
      setColors([]);
      setViewColors([]);
      setDescription("");
      setOriginalPrice("");
      setDiscountPrice("");
      setStock("");
      setSku("");
      setName("");
      setImages([]);
      setCategory("");
      setImageShow([]);
      setSizes([]);
      setViewSize([]);
      setIsSpinner(false);
      dispatch({
        type: "productCreateClear",
      });
    }
  }, [errorcrearte, successcreate]);

  const handleColorAdd = (color) => {
    setColors([...colors, color]);
    setViewColors("");
  };

  const handleColorDelete = (index) => {
    const updatedColors = [...colors];
    updatedColors.splice(index, 1);
    setColors(updatedColors);
  };
  const handleSizeAdd = (size) => {
    setSizes([...sizes, size]);
    setViewSize([]);
  };

  const handleSizeDelete = (index) => {
    const updatedSizes = [...sizes];
    updatedSizes.splice(index, 1);
    setSizes(updatedSizes);
  };
  const handleImageChange = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);

    setImages((prevImages) => [...prevImages, ...files]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSpinner(true);
    const newForm = new FormData();
    images.forEach((image) => {
      newForm.append("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("sku", sku);
    newForm.append("category", category);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    colors.forEach((color) => {
      newForm.append("colors", color);
    });
    sizes.forEach((size) => {
      newForm.append("sizes", size);
    });
    dispatch(createProduct(newForm));
  };
  const { categories } = useSelector((state) => state.categories);
  return (
    <div className="w-[90%] lg:w-[50%] m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <h5 className=" text-[25px] text-center h0ar">{t("Add Product")} </h5>

      {/* create product form */}

      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2 h1ar labell">{t("Product Name")}</label>
          <span className="text-red-500 h1ar">*</span>

          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 h2ar appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
            onChange={(e) => setName(e.target.value)}
            placeholder={t("Enter Product Name")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Product Description")}</label>
          <span className="text-red-500">*</span>

          <textarea
            cols="30"
            required
            rows="8"
            type="text"
            name="description"
            value={description}
            className=" mt-2  h2ar appearance-none outline-none block w-full pt-2 px-3 border
             border-gray-300 rounded-[3px] placeholder-gray-400  sm:text-sm"
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            placeholder={t("Write a description of your Product")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Sku")}</label>
          <span className="text-red-500">*</span>

          <input
            type="number"
            name="sku"
            value={sku}
            className="mt-2 h2ar ltr:valid: appearance-none block w-full px-3 h-[35px] border
             border-gray-300 rounded-[3px] placeholder-gray-400 "
            onChange={(e) => {
              setSku(e.target.value);
            }}
            placeholder={t("Write a sku of your Product")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Categories")}</label>
          <span className="text-red-500">*</span>

          <select
            className="w-full h1ar mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="Choose a category" className="h1ar">
              {t("Select Categories")}{" "}
            </option>

            {categories &&
              categories.map((i) => (
                <option value={i.title} className="h2ar" key={i.title}>
                  {currentDir == "ltr" ? i.titleEn : i.titleAr}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">{t("Tags")} </label>

          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 h2ar appearance-none block w-full h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
            onChange={(e) => setTags(e.target.value)}
            placeholder={t("Choose search tags for a product")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar"> {t("Original Price")}</label>{" "}
          <span className="text-gray-500 h2ar text-[10px] ">
            {" "}
            {t("It will be added")}
            <span className="text-red-500 h2ar text-[20px] ">
              {originalPrice ? originalPrice * 0.2 : " "}
            </span>{" "}
            {t("20% added to the price of the product as store fees")}{" "}
          </span>
          <span className="text-gray-500 h2ar text-[10px] ">
            {t(
              "In addition to that, there is a shipping fee of 10 SAR for the order"
            )}{" "}
          </span>
          <input
            type="number"
            name="price"
            value={originalPrice}
            className="center mt-2 h2ar  appearance-none block w-full px-3 h-[35px] border border-gray-300 
            rounded-[3px] placeholder-gray-400  sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder={t(
              "Please provide the price of the product before the discount"
            )}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />

        <div>
          <label className="pb-2 h1ar label">
            {t("Price (After Discount)")}
          </label>{" "}
          <span className="text-gray-500 h2ar text-[10px] ">
            {" "}
            {t("It will be added")}{" "}
            <span className="text-red-500 h2ar text-[20px] ">
              {discountPrice ? discountPrice * 0.2 : " "}
            </span>{" "}
            {t("20% added to the price of the product as store fees")}{" "}
          </span>
          <span className="text-gray-500 h2ar text-[10px] ">
            {" "}
            {t(
              "In addition to that, there is a shipping fee of 10 SAR for the order"
            )}
          </span>
          <input
            type="number"
            name="price"
            value={discountPrice}
            className="mt-2 h2ar appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder={t("Please provide the price of the product")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Stock")} <span className="text-red-500">*</span>
          </label>

          <input
            type="number"
            name="price"
            value={stock}
            className="mt-2 appearance-none h2ar block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder={t("Please enter the quantity")}
            style={{ borderColor: "#d1d5db" }}
            onFocus={(e) => (e.target.style.borderColor = currentColor)}
            onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
          />
        </div>
        {/* ------------------------------colors-------------------------- */}
        <div>
          <label className="pb-2 h1ar">{t("Colors")}</label>
          <span className="text-red-500">*</span>

          <div className=" w-full flex items-center justify-between">
            <input
              type="text"
              name="color"
              value={viewColors}
              className="mt-2 h2ar appearance-none block w-[80%] h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
              onChange={(e) => setViewColors(e.target.value)}
              placeholder={t("ُEnter Color Name")}
              style={{ borderColor: "#d1d5db" }}
              onFocus={(e) => (e.target.style.borderColor = currentColor)}
              onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
            />
            <button
              type="button"
              onClick={() => handleColorAdd(viewColors)}
              className="px-2 py-2  text-sm text-white rounded-md h1ar"
              style={{
                background: currentColor,
              }}
            >
              {t("Add Color")}
            </button>
          </div>
          <div className="grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] mt-3 lg:grid-cols-6 lg:gap-2 xl:grid-cols-8 xl:gap-2 mb-2">
            {colors.map((color, index) => (
              <div
                key={index}
                className="items-center justify-center align-center p-x2 flex-col relative"
              >
                <div
                  className="px-3 items-center border border-dashed py-2 rounded-lg "
                  style={{
                    borderColor: currentColor,
                  }}
                >
                  <span
                    onClick={() => handleColorDelete(index)}
                    className="p-1 z-10 cursor-pointer hover:shadow-lg hover text-white absolute  top-1 right-1 rounded-full"
                    style={{ backgroundColor: currentColor }}
                  >
                    <IoCloseSharp className="w-2 h-2" />
                  </span>
                  <h1 className="h1ar text-lg text-center">{color}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />
        {/* ------------------------------sizes-------------------------- */}
        <div>
          <label className="pb-2 h1ar">{t("Sizes")}</label>
          <span className="text-red-500">*</span>

          <div className=" w-full flex items-center justify-between">
            <input
              type="text"
              name="color"
              value={viewSize}
              className="mt-2 h2ar appearance-none block w-[80%] h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 "
              onChange={(e) => setViewSize(e.target.value)}
              placeholder={t("Enter Size Number")}
              style={{ borderColor: "#d1d5db" }}
              onFocus={(e) => (e.target.style.borderColor = currentColor)}
              onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
            />
            <button
              type="button"
              onClick={() => handleSizeAdd(viewSize)}
              className="px-2 py-2  text-sm text-white rounded-md h1ar"
              style={{
                background: currentColor,
              }}
            >
              {t("Add Size")}
            </button>
          </div>
          <div className="grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] mt-3 lg:grid-cols-6 lg:gap-2 xl:grid-cols-8 xl:gap-2 mb-2">
            {sizes.map((size, index) => (
              <div
                key={index}
                className="items-center justify-center align-center p-x2 flex-col relative"
              >
                <div
                  className="px-3 items-center border border-dashed py-2 rounded-lg "
                  style={{
                    borderColor: currentColor,
                  }}
                >
                  <span
                    onClick={() => handleSizeDelete(index)}
                    className="p-1 z-10 cursor-pointer hover:shadow-lg hover text-white absolute  top-1 right-1 rounded-full"
                    style={{ backgroundColor: currentColor }}
                  >
                    <IoCloseSharp className="w-2 h-2" />
                  </span>
                  <h1 className="h1ar text-lg text-center">{size}</h1>
                </div>
              </div>
            ))}
          </div>
        </div>
        <br />
        <div>
          <label className="pb-2 h1ar">
            {t("Uploade Images")}
            <span className="text-red-500">*</span>
          </label>

          <input
            type="file"
            name=""
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
          />

          <div className="grid lg:grid-cols-4 grid-cols-2 md:grid-cols-3 sm:grid-cols-2 sm:gap-4 md:gap-4 xs:gap-4 gap-3 w-full text-[#d0d2d6] mb-4">
            {imageShow.map((img, i) => (
              <div className="h-[180px] relative">
                <label htmlFor={i}>
                  <img
                    className="w-full h-full  rounded-lg border"
                    src={img.url}
                    alt=""
                    style={{
                      borderColor: currentColor,
                    }}
                  />
                </label>

                <input
                  onChange={(e) => changeImage(e.target.files[0], i)}
                  type="file"
                  id={i}
                  className="hidden"
                />

                <span
                  onClick={() => removeImage(i)}
                  className="p-2 z-10 cursor-pointer hover:shadow-lg hover text-white absolute top-1 right-1 rounded-full"
                  style={{ backgroundColor: currentColor }}
                >
                  <IoCloseSharp />
                </span>
              </div>
            ))}

            <label
              className="flex mt-2 justify-center items-center flex-col h-[180px] cursor-pointer border rounded-lg border-dashed hover:border-indigo-500 w-full text-[#d0d2d6]"
              htmlFor="image"
              style={{
                borderColor: currentColor,
              }}
            >
              <span>
                <BsImages size={40} color={currentColor} />
              </span>

              <span
                className="par text-md"
                style={{
                  color: currentColor,
                }}
              >
                {t("select image")}
              </span>
            </label>

            <input
              multiple
              onChange={inmageHandle}
              className="hidden"
              type="file"
              id="image"
            />
          </div>

          <br />

          <div className="w-full flex justify-center">
            {isSpinner ? (
              <Spinner word={t("Create product")} />
            ) : (
              <button
                type="submit"
                className="text-white focus:ring-4 font-medium rounded-xl text-md px-5 py-2.5 h1ar text-center me-2 inline-flex items-center"
                style={{
                  backgroundColor: currentColor,

                  focusRing: currentColor,
                }}
              >
                {t("Create Product")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
/*   <div>
          <label className="pb-2 h1ar">
            {t("Colors")} <span className="text-red-500">*</span>
          </label>
          <div className="grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] lg:grid-cols-6 lg:gap-2 xl:grid-cols-8 xl:gap-2 mb-2">
            {COLORS.map((item, index) => (
              <div
                key={index}
                className="items-center justify-center align-center p-x2 flex-col"
              >
                <button
                  type="button"
                  className="h-10 w-10 rounded-full  cursor-pointer"
                  style={{
                    backgroundColor: item.code,
                    border: "1px dashed ",
                    borderColor: "#000",
                  }}
                  onClick={() => handleColorChange(item.code)}
                >
                  {" "}
                  {colors.includes(item.code) ? (
                    <BsCheck
                      className="ml-2 rtl:mr-2 text-2xl"
                      style={{
                        color: item.name === "black" ? "#fff" : "#000",
                      }}
                    />
                  ) : null}
                </button>
                <p
                  className="h1ar text-md "
                  style={{
                    color: item.name === "white" ? "#000" : item.code,
                  }}
                >
                  {t(item.name)}
                </p>
              </div>
            ))}
          </div>
        </div>*/
