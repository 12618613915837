import React, { useState } from 'react';
import { FaCloudMoon } from "react-icons/fa";
import { IoSunnyOutline } from "react-icons/io5";
import { useStateContext } from "../contexts/ContextProvider";

function ToggleDark() {
  const [clicked, setClicked] = useState(false);
  const { setMode, currentMode } = useStateContext();

  const handleThemeChange = () => {
    setClicked(true);
    if (currentMode === 'Dark') {
      setMode('Light');
    } else if (currentMode === 'Light') {
      setMode('Dark');
    }
    setClicked(false);
  }

  return (
    <button
      className={`text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg 800px:text-2xl text-xl px-2 py-1.5 me-2 mb-2 dark:bg-black dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700`}
      onClick={handleThemeChange}
    >
      {currentMode === 'Dark' ? (
        <FaCloudMoon  />
      ) : (
        <IoSunnyOutline />
      )}
    </button>
  );
}

export default ToggleDark;
