import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
};

export const dailySalesReducer = createReducer(initialState, {
 
  
  
  // get all dailySales of shop
  getdailySalesShopRequest: (state) => {
    state.isLoading = true;
  },
  getdailySalesShopSuccess: (state, action) => {
    state.isLoading = false;
    state.dailySales = action.payload;
  },
  getdailySalesShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  // get all orders for admin
  AlldailySalesRequest: (state) => {
    state.isLoading = true;
  },
  AlldailySalesSuccess: (state, action) => {
    state.isLoading = false;
    state.alldailySales = action.payload;
  },
  AlldailySalesFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});

