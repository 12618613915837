import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadings: false,
  isLoadingcreate: false,
  isLoadingCs: false,
  isLoadingEs: false,
};
export const shipingReducer = createReducer(initialState, {
  gitAllShipingsRequest: (state) => {
    state.isLoading = true;
  },
  gitAllShipingsSuccess: (state, action) => {
    state.isLoading = false;
    state.shipings = action.payload;
    state.successcreate = true;
  },
  gitAllShipingsFailed: (state, action) => {
    state.isLoading = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  getShipingRequest: (state) => {
    state.isLoadings = true;
  },
  getShipingSuccess: (state, action) => {
    state.isLoadings = false;
    state.shiping = action.payload;
  },
  getShipingFailed: (state, action) => {
    state.isLoadings = false;
  },
  getCShipingRequest: (state) => {
    state.isLoadingCs = true;
  },
  getCShipingSuccess: (state, action) => {
    state.isLoadingCs = false;
    state.Cshiping = action.payload;
  },
  getCShipingFailed: (state, action) => {
    state.isLoadingCs = false;
  },
  getEShipingRequest: (state) => {
    state.isLoadingEs = true;
  },
  getEShipingSuccess: (state, action) => {
    state.isLoadingEs = false;
    state.Eshiping = action.payload;
  },
  getEShipingFailed: (state, action) => {
    state.isLoadingEs = false;
  },
});
