import React, { useEffect, useState } from "react";
import { getdailySalesOfShop } from "../../redux/actions/dailySales";
import axios from "axios";
import moment from "moment";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useTranslation } from "react-i18next";
import "moment/locale/ar";
import "moment/locale/en-au";
import { Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";

import { useStateContext } from "../../contexts/ContextProvider";
import { server } from "../../server";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const DailySalesChart = ({ dailySales }) => {
  const [textColor, setTextColor] = useState("");
  const [lightColor, setLightColor] = useState("");
  const { currentColor, currentDir, currentMode } = useStateContext();
  const { t } = useTranslation();
  useEffect(() => {
    if (currentDir === "rtl") {
      moment.locale("ar");
    } else if (currentDir === "ltr") {
      moment.locale("en-au");
    }

    if (currentColor === "#1A97F5") {
      setLightColor("rgba(26, 151, 245, 0.3)");
    } else if (currentColor === "#03C9D7") {
      setLightColor("rgba(3, 201, 215, 0.3)");
    } else if (currentColor === "#7352FF") {
      setLightColor("rgba(115, 82, 255, 0.3)");
    } else if (currentColor === "#FF5C8E") {
      setLightColor("rgba(255, 92, 142, 0.3)");
    } else if (currentColor === "#1E4DB7") {
      setLightColor("rgba(30, 77, 183, 0.3)");
    } else if (currentColor === "#FB9678") {
      setLightColor("rgba(251, 150, 120, 0.3)");
    }
  }, [currentColor, currentDir]);
  useEffect(() => {
    if (currentMode === "Dark") {
      setTextColor("#d1d5db");
    } else if (currentMode === "Light") {
      setTextColor("#4b5563");
    }
  }, [currentMode]);
  if (!dailySales) {
    return <h1>Loading...</h1>;
  }

  return (
    <div
      className="w-full 800px:w-[45%] rounded-lg dark:bg-secondary-dark-bg bg-white"
      style={{
        border: `1px solid ${currentColor}
  `,
      }}
    >
      <Line
        data={{
          labels: dailySales.map((item) => moment(item.date).format("MMM D")),
          datasets: [
            {
              label: t("Sales"),
              data: dailySales.map((item) => item.totalSales),
              fill: true,
              borderColor: currentColor,
              backgroundColor: lightColor,
              color: textColor,
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              labels: {
                color: textColor,
              },
            },
            title: {
              display: true,
              text: t("Daily Sales"),
              color: textColor,
              size: 16,
            },
          },
        }}
      />
    </div>
  );
};
export default DailySalesChart;
