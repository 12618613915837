import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isLoadingh: false,
  isLoadingcreate: false,
};
export const heroImageReducer = createReducer(initialState, {
  heroImageCreateRequest: (state) => {
    state.isLoadingcreate = true;
  },
  heroImageCreateSuccess: (state, action) => {
    state.isLoadingcreate = false;
    state.heroImage = action.payload;
    state.successcreate = true;
  },
  heroImageCreateFail: (state, action) => {
    state.isLoadingcreate = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  HeroImageCreateCleare: (state) => {
    state.successcreate = false;
    state.errorcreate = false;
    state.successcreate = false;
  },
  deleteHeroImageRequest: (state) => {
    state.isLoading = true;
  },
  deleteHeroImageSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.successcreate = true;
  },
  deleteHeroImageFailed: (state, action) => {
    state.isLoading = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  getAllHeroImageRequest: (state) => {
    state.isLoading = true;
  },
  getAllHeroImageSuccess: (state, action) => {
    state.isLoading = false;
    state.heroImages = action.payload;
    state.successcreate = true;
  },
  getAllHeroImageFailed: (state, action) => {
    state.isLoading = false;
    state.errorcreate = action.payload;
    state.successcreate = false;
  },
  getHeroImageRequest: (state) => {
    state.isLoadingh = true;
  },
  getHeroImageSuccess: (state, action) => {
    state.isLoadingh = false;
    state.heroImage = action.payload;
  },
  getHeroImageFailed: (state, action) => {
    state.isLoadingh = false;
  },
});
