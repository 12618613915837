import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { backend_url } from "../../server";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";

import {
  deleteCategorei,
  getAllCategories,
} from "../../redux/actions/categories";
import styles from "../../styles/styles";
function AllCategory() {
  const { categories, isLoading, successdelete } = useSelector(
    (state) => state.categories
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentColor, currentDir } = useStateContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getAllCategories());
  }, [successdelete]);
  const handleDelete = async (id) => {
    if (!isDeleting) {
      try {
        setIsDeleting(true); // تعيين حالة الحذف إلى true
        dispatch(deleteCategorei(id));
      } catch (error) {
        toast(error);
      } finally {
        setIsDeleting(false); // تعيين حالة الحذف إلى false بعد الانتهاء من الحذف
        dispatch(getAllCategories());
      }
    }
  };
  const columns = [
    {
      field: "productNumber",
      headerName: <span className="h1ar text-sm">{t("Id")}</span>,
      minWidth: 150,
      flex: 0.7,
    },
    {
      field: "image",
      headerName: <span className="h1ar text-sm">{t("Image")}</span>,
      minWidth: 150,
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div
            style={{
              borderRadius: "50%",
              padding: "10px",
              width: "65px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65px",
            }}
          >
            <img
              src={params.value}
              alt="coategorie Image"
              style={{
                height: "100%",
                width: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      },
    },
    {
      field: "EnglishTitle",
      headerName: <span className="h1ar text-sm">{t("English Title")}</span>,
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "ArabicTitle",
      headerName: <span className="h1ar text-sm">{t("Arabic Title")}</span>,
      minWidth: 180,
      flex: 1.4,
    },

    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: <span className="h1ar text-sm">{t("Delete")}</span>,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              style={{ background: "#ff0000" }}
              onClick={() => handleDelete(params.id)}
            >
              <AiOutlineDelete style={{ color: "#ffffff" }} size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName: <span className="h1ar text-sm">{t("Edit")}</span>,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Link to={`/EditeCategoryPage/${params.id}`}>
              <Button style={{ background: "#ff8842" }}>
                <AiOutlineEdit style={{ color: "#ffffff" }} size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  const row = [];

  categories &&
    categories.forEach((item) => {
      row.push({
        productNumber: item.categorieNumber,
        EnglishTitle: item.titleEn,
        ArabicTitle: item.titleAr,
        image: `${backend_url}${item.image && item.image}`,
        id: item._id,
      });
    });
  const theme = createTheme({
    direction: currentDir,
  });
  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <frameElement className="flex-col w-full items-center px-8 pt-14">
          <div className="flex items-center mx-8 mt-4 "></div>
          <br />{" "}
          <ThemeProvider theme={theme}>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
              <Link to={`/createCategorie`} className="mb-5">
                <button className={`${styles.buttoon}`}>{t("Add New")}</button>
              </Link>
              <DataGrid
                rows={row}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                initialState={{
                  ...row.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </ThemeProvider>
        </frameElement>
      )}
    </>
  );
}

export default AllCategory;
