import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllHeroImage } from "../../../redux/actions/heroImage";
import { backend_url } from "../../../server";
import Loader from "../Loader";
const MobilSlider = () => {
  const { t } = useTranslation();
  const { heroImages, isLoading } = useSelector((state) => state.heroImages);
  const [currentSlid, setCurrentSlid] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllHeroImage());
  }, []);
  console.log("heroImaages", heroImages);
  const sliderLength = heroImages?.length;
  const autoScroll = true;
  let slideInterval;
  const intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlid(currentSlid === sliderLength - 1 ? 0 : currentSlid + 1);
  };
  const prevSlide = () => {
    setCurrentSlid(currentSlid === 0 ? sliderLength - 1 : currentSlid - 1);
  };

  useEffect(() => {
    setCurrentSlid(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      const auto = () => {
        slideInterval = setInterval(nextSlide, intervalTime);
      };
      auto();
    }
    return () => clearInterval(slideInterval);
  }, [currentSlid, intervalTime]);

  return (
    <>
      {" "}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex-col gap-2">
          <div clasName="Slider my-2">
            {heroImages &&
              heroImages.map((slide, index) => {
                const { image, titelAr, titleEn, descAr, descEn } = slide;
                return (
                  <div
                    key={index}
                    className={
                      index === currentSlid ? "slide current" : "slide"
                    }
                  >
                    {index === currentSlid && (
                      <>
                        <div className="p-2 w-full">
                          <img
                            src={`${backend_url}${image}`}
                            className="w-full rounded-lg shadow-md"
                            alt="slid"
                            onClick={nextSlide}
                          />
                        </div>
                      </>
                    )}
                  </div>
                );
              })}

            {/*
  <div className="flex w-full p-2 my-1">
  <div className="flex-col mr-2 ">
  <h2  className="bg-gradient-to-r from-[#5d0c8a] to-[#b91a5c] bg-clip-text text-4xl font-extrabold text-transparent sm:text-6xl">تسوق الأن</h2>
  </div>
  </div>*/}
          </div>
        </div>
      )}
    </>
  );
};
export default MobilSlider;
