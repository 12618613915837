import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxAvatar } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import  UserSpinner from "../Charts/UserSpinner";

const ShopCreate = () => {
  const [spinner ,setSpinner]=useState(false)
 const{t}=useTranslation()
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name,setName] = useState("");
  const [phoneNumber,setPhoneNumber] = useState();
  const [address,setAddress] = useState("");
  const [zipCode,setZipCode] = useState();
  const [avatar,setAvatar] = useState();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
setSpinner(true)
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const newForm = new FormData();

    newForm.append("file", avatar);
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    newForm.append("zipCode", zipCode);
    newForm.append("address", address);
    newForm.append("phoneNumber", phoneNumber);
    axios
      .post(`${server}/shop/create-shop`, newForm, config)
      .then((res) => {
        toast.success(<span className="h1ar text-sm">{t(res.data.message)}</span>);
        setName("");
        setEmail("");
        setPassword("");
        setAvatar();
        setZipCode();
        setAddress("");
        setPhoneNumber();
      })
      .catch((error) => {
        toast.error(<span className="h1ar text-sm">{t(error.response.data.message)}</span>);
     setSpinner(false) });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Link to="/"><h4 className="my-4 text-center text-xl font-extrabold text-[#b91a5c] h2ar">{t("Return to the homepage")}</h4></Link>
      <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 h0ar"> {t("Create New Shop")}
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
            <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700  par"
              >
             {t("Shop Name")}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  autoComplete="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                /></div></div>
                   <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700  par"
              >
               {t("shop Phon Number")}
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="phon-numer"
                  autoComplete="numer"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="appearance-none block text-left w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
              </div>
            </div>
                
                <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700  par"
              >
              {t("Shop Email")} 
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none text-left block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700  par"
              >
             {t("address")}
              </label>
              <div className="mt-1">
                <input
                  type="address"
                  name="address"
                  autoComplete="address"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700  par"
              >
            {t("Zip Code")}
              </label>
              <div className="mt-1">
                <input
                  type="zipcode"
                  name="zipcode"
                  autoComplete="zipcode"
                  required
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  className="appearance-none text-left block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700  par"
              >
                     {t("Password")}
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none  text-left block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>
            <div>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700"
              ></label>
              <div className="mt-2 flex items-center">
                <span className="inline-block h-8 w-8 rounded-full overflow-hidden">
                  {avatar ? (
                    <img
                      src={URL.createObjectURL(avatar)}
                      alt="avatar"
                      className="h-full w-full object-cover rounded-full"
                    />
                  ) : (
                    <RxAvatar className="h-8 w-8" />
                  )}
                </span>
                <label
                  htmlFor="file-input"
                  className="ml-5 flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  <span> {t("Upload Image")}</span>
                  <input
                    type="file"
                    name="avatar"
                    id="file-input"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleFileInputChange}
                    className="sr-only"
                  />
                </label>
              </div>
            </div>

            <div>
              {spinner ===true ?(<UserSpinner word={t("Create Shop")}/>
              ):(<button
                type="submit"
                className="text-white h1ar bg-gradient-to-r from-pink-600 via-pink-700 to-[#b91a5c] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-xl text-md px-5 py-2.5 text-center me-2 mb-2 mt-2"
              >
{t("Create Shop")}
              </button>)}
            </div>
            <div className={`${styles.noramlFlex} w-full`}>

              <Link to="/shop-login" className="text-[#b91a5c] ltr:pr-2 rtl:pl-2 text-sm par">
                 {t("Sallary Login")}
              </Link>
      <h3 className="par"> 
      {t("Do you already have an account?")} </h3>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
