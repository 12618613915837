import React from 'react'
import Footer from '../../components/Layout/Footer'
import OrderDetails from "../../components/Shop/OrderDetails";
import ThemeSettingsButton from '../../components/ThemeSettingsButton';

const ShopOrderDetails = () => {
  return (
    <><ThemeSettingsButton/>
    
         <OrderDetails />
          <Footer />
    </>
  )
}

export default ShopOrderDetails
