import axios from "axios";
import { server } from "../../server";
export const uploadActiveData = (newForm) => async (dispatch) => {
    try {
      dispatch({
        type: "uploadActiveData",
      });
  
      const config = { headers: { "Content-Type": "multipart/form-data" } };
  
      const { data } = await axios.post(
        `${server}/activedata/upload-active-data`,
        newForm,
        config
      );
      dispatch({
        type: "activedatasendSuccess",
        payload:data.activedata,
      });
    } catch (error) {
      dispatch({
        type: "activedatasendFail",
        payload: error.response.data.message,
      });
    }
  };
  export const getAllActiveData= () => async (dispatch) => {
    try {
      dispatch({
        type: "getAllActiveDataRequest",
      });
  
      const { data } = await axios.get(
        `${server}/activedata/get-all-active-data`
      );
  
      dispatch({
        type: "getAllActiveDataSuccess",
        payload: data.activedatas,
      });
    } catch (error) {
      dispatch({
        type: "getAllActiveDataFailed",
        payload: error.response.data.message,
      });
    }
  };
  