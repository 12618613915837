import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { useTranslation } from "react-i18next";
import { useStateContext } from "../../contexts/ContextProvider";
import { backend_url } from "../../server";

const DropDown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentDir } = useStateContext();
  const submitHandle = (i) => {
    navigate(`/products?category=${i.titleEn}`);
    setDropDown(false);
    window.location.reload();
  };
  return (
    <div className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm">
      {categoriesData &&
        categoriesData.map((i, index) => (
          <div
            key={index}
            className={`${styles.noramlFlex}`}
            onClick={() => submitHandle(i)}
          >
            <img
              src={`${backend_url}${i.image}`}
              style={{
                width: "25px",
                height: "25px",
                objectFit: "contain",
                marginLeft: "10px",
                userSelect: "none",
              }}
              alt=""
            />
            <h3 className="m-3 h2ar cursor-pointer select-none">
              {currentDir == "ltr" ? i.titleEn : i.titleAr}
            </h3>
          </div>
        ))}
    </div>
  );
};

export default DropDown;
