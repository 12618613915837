import React,{useState,useEffect} from "react"
import Header from "../components/Layout/Header.jsx"
import SearchBar from "../components/Layout/SearchBar.jsx"
import Hero from "../components/Layout/Hero.jsx"
import Categories from "../components/Route/Categories/Categories"
import BestDeals from "../components/Route/BestDeals/BestDeals";
import BestSillngSlider from "../components/Route/BestSillngSlider/BestSillngSlider";
import BottomNav from "../components/Layout/BottomNav"
import FeaturedProduct from "../components/Route/FeaturedProduct/FeaturedProduct"
import Events from "../components/Events/Events"
import Footer from "../components/Layout/Footer"
import EventMobile from "../components/Layout/slider/EventMobile"
import ThemeSettingsButton from "../components/ThemeSettingsButton.jsx";
const HomePage= () =>{
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // تحديث عرض الشاشة عندما يتغير حجم الشاشة
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // إضافة مستمع لحجم الشاشة
    window.addEventListener("resize", handleResize);

    // إزالة المستمع عندما يتم تفكيك المكون
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return(<>
   
    <div className=" bg-main-bg dark:bg-main-dark-bg">
    <Header activeHeading={1} />
    <SearchBar />
    <Hero />

    <Categories />
    <BestDeals />
            {windowWidth >= 800 ? null: <BestSillngSlider/>
      }
    <Events />
    <FeaturedProduct />
    <Footer />
    </div>
<BottomNav  mobilactiveHeading={1} />
</>
    );
}
export default HomePage;
