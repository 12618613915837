import React, { useEffect } from 'react'
import ShopLogin from "../components/Shop/ShopLogin.jsx"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThemeSettingsButton from '../components/ThemeSettingsButton.jsx';
function ShopLoginPage() {
    const navigate = useNavigate();
    const { isSeller,isLoading } = useSelector((state) => state.seller);
  
    useEffect(() => {
      if(isSeller === true){
        navigate(`/dashboard`);
      }
    }, [isLoading,isSeller])
  return (
    <><ThemeSettingsButton/>
    <ShopLogin/></>
  )
}

export default ShopLoginPage