import { useEffect, useState } from "react";
import "./App.css";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { useStateContext } from "./contexts/ContextProvider";
import { FiSettings } from "react-icons/fi";

import {
  Loginpage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProductsPage,
  BestSellingPage,
  EventsPage,
  FAQPage,
  ProductDetailsPage,
  ProfilePage,
  CheckoutPage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  ThemeSettings,
  CategoryProductsPage,
  OrderSuccessPage,
  UserInbox,
  PaymentPage,
  OrderDetailsPage,
  TrackOrderPage,
  PaymentCashOnDelevary,
  ResetPassword,
  ForgetPasswordPage,
  CartPage,
  AddressPage,
  TrackerOrderPage,
  ChangePasswordPage,
  AllUserRefundOrdersPage,
} from "./routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/stor";
import { loadSeller, loadUser } from "./redux/actions/user";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute.js";
import ProtectedRoute from "./routes/ProtectedRoute";
import Sellerability from "./routes/Sellerability";

import SellerProtectedRoute from "./routes/SellerProtectedRoute";
import {
  ShopHomePage,
  ShopDashboardPage,
  ShopCreateProduct,
  ShopAllProducts,
  ShopAllEvents,
  ShopCreateEvents,
  ShopInboxPage,
  ShopAllCoupouns,
  ShopAllOrders,
  ShopAllRefunds,
  ShopWithDrawMoneyPage,
  ShopSettingsPage,
  ShopOrderDetails,
  ShopPreviewPage,
  Sellerdeactive,
  ActiveDataPage,
  ShopForgetPasswordPage,
  ShopResetPassword,
  EditProductPage,
  Invoice,
} from "./routes/ShopRoutes";

import {
  WithdrawDetailsPage,
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardProducts,
  AdminDashboardEvents,
  AdminDashboardWithdraw,
  AdminSellerActivedataPage,
  AdminActiveDataDetails,
  AdminHeroPage,
  CategoryPage,
  EditeCategoryPage,
  CreateCategorie,
  EditeHeroImagePage,
  CreateHeroImagePage,
  AdminShipingPage,
  AdminEditShiping,
} from "./routes/AdminRoutes";

import { getAllProducts } from "./redux/actions/product";
import { getAllEvents } from "./redux/actions/event";
import { useTranslation } from "react-i18next";
import Sellerupdatestate from "./components/Shop/Sellerupdatestate.jsx";
import { getAllCategories } from "./redux/actions/categories.js";
const App = () => {
  const { i18n } = useTranslation();
  const {
    setCurrentLang,
    currentLang,
    currentDir,
    setCurrentDir,
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");

    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  useEffect(() => {
    const currentThemedir = localStorage.getItem("themeDir");
    const currentThemelang = localStorage.getItem("themeLang");
    if (currentThemedir && currentThemelang) {
      setCurrentDir(currentThemedir);
      setCurrentLang(currentThemelang);
    }
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());
    Store.dispatch(getAllProducts());
    Store.dispatch(getAllCategories());
    Store.dispatch(getAllEvents());
  }, []);

  return (
    <frameElement
      className={currentMode === "Dark" ? "dark" : ""}
      dir={currentDir === "rtl" ? "rtl" : "ltr"}
    >
      <Suspense fullback={null}>
        <BrowserRouter>
          <frameElement className="dark:bg-main-dark-bg">
            {themeSettings && <ThemeSettings />}
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Loginpage />} />
              <Route path="/sign-up" element={<SignupPage />} />
              <Route
                path="/activation/:activation_token"
                element={<ActivationPage />}
              />
              <Route
                path="/seller/activation/:activation_token"
                element={<SellerActivationPage />}
              />
              <Route path="/forgetpassword" element={<ForgetPasswordPage />} />
              <Route
                path="/shopforgetpassword"
                element={<ShopForgetPasswordPage />}
              />
              <Route
                path="/resetpassword/:activation_token"
                element={<ResetPassword />}
              />
              <Route
                path="/shopresetpassword/:activation_token"
                element={<ShopResetPassword />}
              />
              <Route
                path="/category-products"
                element={<CategoryProductsPage />}
              />
              <Route path="/products" element={<ProductsPage />} />
              <Route path="/product/:id" element={<ProductDetailsPage />} />
              <Route path="/best-selling" element={<BestSellingPage />} />
              <Route path="/events" element={<EventsPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route
                path="/checkout"
                element={
                  <ProtectedRoute>
                    <CheckoutPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <ProfilePage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/cartpage"
                element={
                  <ProtectedRoute>
                    <CartPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/payment"
                element={
                  <ProtectedRoute>
                    <PaymentPage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/paymentCash"
                element={
                  <ProtectedRoute>
                    <PaymentCashOnDelevary />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/user/order/:id"
                element={
                  <ProtectedRoute>
                    <OrderDetailsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inbox"
                element={
                  <ProtectedRoute>
                    <UserInbox />
                  </ProtectedRoute>
                }
              />

              <Route path="/order/success" element={<OrderSuccessPage />} />
              <Route
                path="/user/track/order/:id"
                element={
                  <ProtectedRoute>
                    <TrackOrderPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/TrackerOrderPage/"
                element={
                  <ProtectedRoute>
                    <TrackerOrderPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/AllUserRefundOrdersPage/"
                element={
                  <ProtectedRoute>
                    <AllUserRefundOrdersPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/ChangePasswordPage/"
                element={
                  <ProtectedRoute>
                    <ChangePasswordPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user/AddressPage/"
                element={
                  <ProtectedRoute>
                    <AddressPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />

              {/* shop Routes */}
              <Route path="/shop-create" element={<ShopCreatePage />} />
              <Route path="/shop-login" element={<ShopLoginPage />} />
              <Route
                path="/shop/:id"
                element={
                  <SellerProtectedRoute>
                    <ShopHomePage />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <SellerProtectedRoute>
                    <ShopSettingsPage />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <SellerProtectedRoute>
                    <ShopDashboardPage />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/sellerupdatestate"
                element={
                  <SellerProtectedRoute>
                    <Sellerupdatestate />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/active-shop-data"
                element={
                  <SellerProtectedRoute>
                    <ActiveDataPage />
                  </SellerProtectedRoute>
                }
              />

              <Route
                path="/dashboard-create-product"
                element={
                  <SellerProtectedRoute>
                    <Sellerability>
                      <ShopCreateProduct />
                    </Sellerability>
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/shop-deactive"
                element={
                  <SellerProtectedRoute>
                    <Sellerdeactive />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/Invoice/:id"
                element={
                  <SellerProtectedRoute>
                    <Invoice />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-orders"
                element={
                  <SellerProtectedRoute>
                    <ShopAllOrders />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-refunds"
                element={
                  <SellerProtectedRoute>
                    <ShopAllRefunds />
                  </SellerProtectedRoute>
                }
              />

              <Route
                path="/order/:id"
                element={
                  <SellerProtectedRoute>
                    <ShopOrderDetails />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-products"
                element={
                  <SellerProtectedRoute>
                    <ShopAllProducts />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-create-event"
                element={
                  <SellerProtectedRoute>
                    <Sellerability>
                      <ShopCreateEvents />
                    </Sellerability>
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-events"
                element={
                  <SellerProtectedRoute>
                    <ShopAllEvents />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-coupouns"
                element={
                  <SellerProtectedRoute>
                    <Sellerability>
                      <ShopAllCoupouns />
                    </Sellerability>
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/dashboard-withdraw-money"
                element={
                  <SellerProtectedRoute>
                    <ShopWithDrawMoneyPage />
                  </SellerProtectedRoute>
                }
              />
              <Route
                path="/edit-product/:id"
                element={
                  <SellerProtectedRoute>
                    <EditProductPage />
                  </SellerProtectedRoute>
                }
              />

              <Route
                path="/dashboard-messages"
                element={
                  <SellerProtectedRoute>
                    <Sellerability>
                      <ShopInboxPage />
                    </Sellerability>
                  </SellerProtectedRoute>
                }
              />

              {/* Admin Routes */}
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-users"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardUsers />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-sellers"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardSellers />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-orders"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardOrders />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-products"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardProducts />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/activedata/:id"
                element={
                  <ProtectedAdminRoute>
                    <AdminActiveDataDetails />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-events"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardEvents />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-withdraw-request"
                element={
                  <ProtectedAdminRoute>
                    <AdminDashboardWithdraw />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-withdraw/:id"
                element={
                  <ProtectedAdminRoute>
                    <WithdrawDetailsPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-Seller-active"
                element={
                  <ProtectedAdminRoute>
                    <AdminSellerActivedataPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/admin-hero-image"
                element={
                  <ProtectedAdminRoute>
                    <AdminHeroPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/CategoryPage"
                element={
                  <ProtectedAdminRoute>
                    <CategoryPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/EditeCategoryPage/:id"
                element={
                  <ProtectedAdminRoute>
                    <EditeCategoryPage />
                  </ProtectedAdminRoute>
                }
              />

              <Route
                path="/createCategorie/"
                element={
                  <ProtectedAdminRoute>
                    <CreateCategorie />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/EditeHeroImagePage/:id"
                element={
                  <ProtectedAdminRoute>
                    <EditeHeroImagePage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/createHeroImage/"
                element={
                  <ProtectedAdminRoute>
                    <CreateHeroImagePage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/AdminShipingPage/"
                element={
                  <ProtectedAdminRoute>
                    <AdminShipingPage />
                  </ProtectedAdminRoute>
                }
              />
              <Route
                path="/AdminEditShiping/:id"
                element={
                  <ProtectedAdminRoute>
                    <AdminEditShiping />
                  </ProtectedAdminRoute>
                }
              />
            </Routes>
            <ToastContainer
              className="absolute tostt z-50"
              position={currentDir === "rtl" ? "top-left" : "top-right"}
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={currentDir === "rtl" ? true : false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme={currentMode === "Dark" ? "dark" : "light"}
            />
          </frameElement>
        </BrowserRouter>
      </Suspense>
    </frameElement>
  );
};

export default App;
