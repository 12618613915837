import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import logo from "../../Assests/images/Revo One-svg.svg";
import { CiShop } from "react-icons/ci";
import { IoCreateOutline } from "react-icons/io5";
import { IoPieChartSharp } from "react-icons/io5";
import BottomNav from "./BottomNav";
import { LuLogIn } from "react-icons/lu";
import { getAllCategories } from "../../redux/actions/categories";
import {
  AiOutlineArrowRight,
  AiOutlineHeart,
  AiOutlineLogin,
  AiOutlineMessage,
  AiOutlineSearch,
} from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { MdOutlineCancel, MdOutlineTrackChanges } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import Navmobile from "./Navmobile";
import { useTranslation } from "react-i18next";
import ToggleLanguages from "../ToggleLanguages";
import ToggleDark from "../ToggleDark";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { useDispatch, useSelector } from "react-redux";
import { backend_url } from "../../server";
import { useStateContext } from "../../contexts/ContextProvider";
import { RxCross1 } from "react-icons/rx";
import { FiShoppingCart } from "react-icons/fi";
import { Buttoon } from "../Charts";
import { TbAddressBook } from "react-icons/tb";

import axios from "axios";

import { server } from "../../server";

import { toast } from "react-toastify";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
const Header = ({ activeHeading }) => {
  const navigate = useNavigate();
  const { categories } = useSelector((state) => state.categories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
  }, []);
  const { handleClick, isClicked } = useStateContext();
  const { t } = useTranslation();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);

  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [activeMobileNave, setActiveMobilNav] = useState(false);
  const [dropDown, setDropDown] = useState(false);

  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
    <div content={title} position="BottomCenter">
      <button
        type="button"
        onClick={() => customFunc()}
        style={{ color }}
        className="relative text-xl rounded-full p-2 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
        />
        {icon}
      </button>
    </div>
  );

  const NavItem = ({ title, icon, color, dotColor }) => (
    <div content={title} position="BottomCenter">
      <button
        type="button"
        style={{ color }}
        className="relative text-xl rounded-full p-3 hover:bg-light-gray"
      >
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex rounded-full h-2 w-2 ltr:right-2 rtl:left-2 top-2"
        />
        {icon}
      </button>
    </div>
  );
  const logoutHandler = () => {
    axios

      .get(`${server}/user/logout`, { withCredentials: true })

      .then((res) => {
        toast.success(res.data.message);

        window.location.reload(true);

        navigate("/login");
      })

      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });
  return (
    <>
      <div className={`${styles.section}  `} dir="ltr">
        <div
          className="hidden  800px:h-[50px] mb-[10px] 800px:flex items-center justify-between"
          dir="ltr"
        >
          {open && (
            <div className={`fixed w-full z-20 h-full top-0`}>
              <div className="fixed h-[60%] rounded-md shadow-md sidebar ltr:left-0  rtl:right-0 w-64 dark:text-gray-200 transition-transform  dark:bg-[#484B52] bg-white flex flex-col  top-10 left-0 z-10 overflow-y-scroll">
                <div className="w-full justify-between flex pr-3">
                  <div
                    onClick={() => setOpen(false)}
                    className="flex top-3 left-3"
                  >
                    <Buttoon
                      icon={<MdOutlineCancel />}
                      color="rgb(153, 171, 180)"
                      bgHoverColor="light-gray"
                      size="2xl"
                      borderRadius="50%"
                    />
                  </div>
                </div>

                <div className="flex-col w-full justify-center">
                  {isAuthenticated ? (
                    <>
                    <div className="mb-10 items-center justify-center flex flex-col w-full">
                      <img
                        src={`${backend_url}${user.avatar}`}
                        alt=""
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
                      />{" "}
                      <p className="text-center mt-2 par dark:text-white ">{user.name}</p>
                    </div>
                      <div class=" w-full px-2 overflow-y-auto">
                        <ul class="space-y-4 font-medium">
                          {user && user?.role === "Admin" && (
                            <Link to="/admin/dashboard">
                              <li
                                className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                              >
                                <span> {t("Admin Dashboard")}</span>
                                <IoPieChartSharp />
                              </li>
                            </Link>
                          )}
                          <Link to="/Profile">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Profile")}</span>
                              <TbAddressBook />
                            </li>
                          </Link>
                          <Link to="/user/TrackerOrderPage/">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Track Order")}</span>
                              <MdOutlineTrackChanges />
                            </li>
                          </Link>
                          <Link to="/user/AllUserRefundOrdersPage/">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Refunds")}</span>
                              <HiOutlineReceiptRefund />
                            </li>
                          </Link>
                          <Link to="/user/ChangePasswordPage/">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Change Password")}</span>
                              <RiLockPasswordLine />
                            </li>
                          </Link>
                          <Link to="/user/AddressPage/">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Address")}</span>
                              <TbAddressBook />
                            </li>
                          </Link>
                          <Link to="/inbox">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Inbox")}</span>
                              <AiOutlineMessage />
                            </li>
                          </Link>
                          <Link to="/shop-create">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span>{t("Become Seller")}</span>
                              <CiShop />
                            </li>
                          </Link>
                          <li
                            className={`w-full flex items-center px-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            onClick={logoutHandler}
                          >
                            <span> {t("Log out")}</span>
                            <AiOutlineLogin />
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>

                <div class=" w-full px-2 overflow-y-auto">
                  <ul class="space-y-4 font-medium">
                    {!isAuthenticated ? (
                      <Link to="/login">
                        <li
                          className={`w-full flex flex items-center p-2 text-gray-900 rounded-lg h1ar dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                        >
                          <span> {t("Login")}</span>
                          <LuLogIn />
                        </li>
                      </Link>
                    ) : null}
                    {!isAuthenticated ? (
                      <Link to="/sign-up">
                        <li
                          className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                        >
                          <span> {t("Sign Up")}</span>
                          <IoCreateOutline />
                        </li>
                      </Link>
                    ) : null}

                    <Link to="/shop-create">
                      <li
                        className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                      >
                        <span>{t("Become Seller")}</span>
                        <CiShop />
                      </li>
                    </Link>
                  </ul>
                </div>

                <br />
                <br />
                <br />
              </div>
            </div>
          )}
          <div className="flex justify-center items-center gap2">
            <BiMenuAltLeft
              size={35}
              className="mt-2 ltr:mr-2 "
              onClick={() => setOpen(true)}
            />
            <Link
              to="/"
              className="items-center gap-3 ml-3 mt-3 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} alt="" /> <span>Revoone</span>
            </Link>
          </div>
          {/* search box */}
          <div className="w-[40%] relative 800px:mt-[10px]">
            <input
              type="text"
              placeholder={t("Search Product...")}
              value={searchTerm}
              onChange={handleSearchChange}
              className="h-[40px] w-full px-2 border-[#b91a5c] border-[2px] rounded-md"
            />

            <AiOutlineSearch
              size={30}
              className="absolute ltr:right-2 rtl:left-2 top-1.5 cursor-pointer "
            />

            {searchData && searchData.length !== 0 && searchTerm !== " " ? (
              <div
                className={`${
                  searchTerm !== ""
                    ? " absolute min-h-[30vh] bg-slate-50 shadow-sm-2 z-[9] p-4"
                    : "hidden"
                }`}
              >
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className="w-full flex items-start-py-3">
                          <img
                            src={`${backend_url}${i.images[0]}`}
                            alt=""
                            className="w-[40px] h-[40px] mr-[10px]"
                          />
                          <h1>{i.name}</h1>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className="flex mt-3">
            <ToggleDark />
            <ToggleLanguages />
          </div>
          <div className="w-[150px] --btn-main h-[50px] flex items-center justify-center rounded-xl cursor-pointer mt-[10px]">
            <Link to={`${isSeller ? "/dashboard" : "/shop-create"}`}>
              {isSeller ? (
                <h1 className="text-[#fff] flex h1ar items-center">
                  {t("Go Dashboard")}{" "}
                  <IoIosArrowForward className=" ml-1" />
                </h1>
              ) : (
                <h1 className="text-[#fff] h1ar flex items-center">
                  {" "}
                  {t("Become Seller")}
                  <IoIosArrowForward className="ml-1   rtl:transform rtl:scale-x-[-1]" />
                </h1>
              )}
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`${
          active === true ? "shadow-sm fixed top-0 left-0 z-10" : null
        } transition hidden 800px:flex items-center justify-between w-full bg-[#000115] h-[70px]`}
      >
        <div
          className={`${styles.section} relative ${styles.noramlFlex} justify-between`}
        >
          {/* categories */}
          <div onClick={() => setDropDown(!dropDown)}>
            <div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
              <BiMenuAltLeft size={30} className="absolute top-3  left-2 " />
              <button
                className={`h-[100%] h0ar w-full flex
 justify-between mt-[10px] items-center ltr:pl-10  rtl:pr-10 bg-white font-sans text-lg font-[500] select-none rounded-t-md`}
              >
                {t("categories")}
              </button>
              <IoIosArrowDown
                size={20}
                className="absolute right-2 top-4 cursor-pointer"
                onClick={() => setDropDown(!dropDown)}
              />
              {dropDown ? (
                <DropDown
                  categoriesData={categories}
                  setDropDown={setDropDown}
                />
              ) : null}
            </div>
          </div>
          {/* navitems */}
          <div className={`${styles.noramlFlex}`}>
            <Navbar active={activeHeading} />
          </div>

          <div className="flex">
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                <button
                  type="button"
                  onClick={() => setOpenWishlist(true)}
                  className="relative text-xl rounded-full p-3 hover:bg-light-gray"
                >
                  <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
                  <AiOutlineHeart color="rgb(255 255 255 / 83%)" />
                </button>

                <span className="absolute right-0 top-0 rounded-full bg-[#b91a5c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {" "}
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>
            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                <NavButton
                  title="Cart"
                  customFunc={() => handleClick("cart")}
                  color="rgb(255 255 255 / 83%)"
                  icon={<FiShoppingCart />}
                />
                <span className="absolute right-0 top-0 rounded-full bg-[#b91a5c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {" "}
                  {cart && cart.length}
                </span>
              </div>
            </div>

            <div className={`${styles.noramlFlex}`}>
              <div className="relative cursor-pointer mr-[15px]">
                {isAuthenticated ? (
                  <div className="w-[50px] h-[50px]">
                    <Link to="/profile">
                      <img
                        src={`${backend_url}${user.avatar}`}
                        alt=""
                        className="w-[50px] h-[50px] rounded-full border-[3px] border-[#0eae88]"
                      />
                    </Link>
                  </div>
                ) : (
                  <div className="flex gap-1 ">
                    <p className="text-gray-200 flex ">
                      {t("Login")}
                      <AiOutlineArrowRight
                        color="rgb(255 255 255 / 83%)"
                        className=" m-1 rtl:transform rtl:scale-x-[-1]"
                      />{" "}
                    </p>
                    <Link to="/login">
                      <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {/*cartlpoppop*/}
            {isClicked.cart && <Cart className="sidebar" />}
            {/*wishlistpoppop*/}
            {openWishlist ? (
              <Wishlist setOpenWishlist={setOpenWishlist} />
            ) : null}
          </div>
        </div>
      </div>
      {/* mobile header*/}

      {/* mobile header */}
      <div
        className={`${active === true ? "shadow-sm  top-0 left-0 z-10" : null}
      w-full h-[60px] bg-[#fff] dark:bg-black z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div
          className="w-full flex items-center px-2  dark:text-gray-200 justify-between"
          style={{ direction: "ltr" }}
        >
          <div className="flex left-0">
            <div>
              <BiMenuAltLeft
                size={35}
                className="mt-2 ltr:mr-2 "
                onClick={() => setOpen(true)}
              />
            </div>
            <div className="flex gap-2   items-center">
              <Link
                to="/"
                className="items-center gap-1 mt-2  flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
              >
                <img src={logo} alt="" /> <span>Revoone</span>
              </Link>
            </div>
          </div>
          <div className="flex right-0">
            <div className="flex gap-1 mt-3">
              <ToggleDark />
              <ToggleLanguages />

              <div
                className="relative "
                onClick={() => setOpenWishlist(true) || setOpen(false)}
              >
                <AiOutlineHeart
                  size={25}
                  className="mt-1 text-gray-400 text-xl dark:text-white pr-1"
                />
                <span class="absolute right-0 top-0 rounded-full bg-[#b91a5c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px]  leading-tight text-center">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>
          </div>
          {/* cart popup */}

          {/* wishlist popup */}
          {openWishlist ? <Wishlist setOpenWishlist={setOpenWishlist} /> : null}
        </div>

        {/*  ************************************************header sidebar ********************************************************************* /*/}
        {open && (
          <div className={`fixed w-full  z-20 h-full top-0 left-0 `}>
            <div className="fixed h-[60%] rounded-md shadow-md sidebar ltr:right-0 rtl:left-0 w-64 dark:text-gray-200 transition-transform  dark:bg-[#484B52] bg-white flex flex-col  top-10 left-0 z-10 overflow-y-scroll">
              <div className="w-full justify-between flex pr-3">
                <div
                  onClick={() => setOpen(false)}
                  className="flex top-3 left-3"
                >
                  <Buttoon
                    icon={<MdOutlineCancel />}
                    color="rgb(153, 171, 180)"
                    bgHoverColor="light-gray"
                    size="2xl"
                    borderRadius="50%"
                  />
                </div>
              </div>

              <div className="flex-col w-full justify-center">
                {isAuthenticated ? (
                  <>
                    <div className="mb-10 items-center justify-center flex flex-col w-full">
                      <img
                        src={`${backend_url}${user.avatar}`}
                        alt=""
                        className="w-[60px] h-[60px] rounded-full border-[3px] border-[#0eae88]"
                      />{" "}
                      <p className="text-center mt-2 par dark:text-white ">{user.name}</p>
                    </div>
                    <div class=" w-full px-2 overflow-y-auto">
                      <ul class="space-y-4 font-medium">
                        {user && user?.role === "Admin" && (
                          <Link to="/admin/dashboard">
                            <li
                              className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                            >
                              <span> {t("Admin Dashboard")}</span>
                              <IoPieChartSharp />
                            </li>
                          </Link>
                        )}
                        <Link to="/Profile">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Profile")}</span>
                            <TbAddressBook />
                          </li>
                        </Link>
                        <Link to="/user/TrackerOrderPage/">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Track Order")}</span>
                            <MdOutlineTrackChanges />
                          </li>
                        </Link>
                        <Link to="/user/AllUserRefundOrdersPage/">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Refunds")}</span>
                            <HiOutlineReceiptRefund />
                          </li>
                        </Link>
                        <Link to="/user/ChangePasswordPage/">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Change Password")}</span>
                            <RiLockPasswordLine />
                          </li>
                        </Link>
                        <Link to="/user/AddressPage/">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Address")}</span>
                            <TbAddressBook />
                          </li>
                        </Link>
                        <Link to="/inbox">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span> {t("Inbox")}</span>
                            <AiOutlineMessage />
                          </li>
                        </Link>
                        <Link to="/shop-create">
                          <li
                            className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          >
                            <span>{t("Become Seller")}</span>
                            <CiShop />
                          </li>
                        </Link>
                        <li
                          className={`w-full flex items-center px-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                          onClick={logoutHandler}
                        >
                          <span> {t("Log out")}</span>
                          <AiOutlineLogin />
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>

              <div class=" w-full px-2 overflow-y-auto">
                <ul class="space-y-4 font-medium">
                  {!isAuthenticated ? (
                    <Link to="/login">
                      <li
                        className={`w-full flex flex items-center p-2 text-gray-900 rounded-lg h1ar dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                      >
                        <span> {t("Login")}</span>
                        <LuLogIn />
                      </li>
                    </Link>
                  ) : null}
                  {!isAuthenticated ? (
                    <Link to="/sign-up">
                      <li
                        className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                      >
                        <span> {t("Sign Up")}</span>
                        <IoCreateOutline />
                      </li>
                    </Link>
                  ) : null}

                  <Link to="/shop-create">
                    <li
                      className={`w-full flex items-center p-2 text-gray-900 h1ar rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group justify-between`}
                    >
                      <span>{t("Become Seller")}</span>
                      <CiShop />
                    </li>
                  </Link>
                </ul>
              </div>

              <br />
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Header;
