import React, { useEffect, useState } from "react";
import {useStateContext} from "../contexts/ContextProvider"
import { useTranslation } from "react-i18next";
import { Buttoon } from "./Charts";
import QrReader from 'react-qr-reader';
import { MdOutlineCancel } from "react-icons/md";
const QrCodeScanner =({setShowQr,qrScanHandeler})=>{
  const { t } = useTranslation();
  const [resultt, setResultt] = useState('');
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(true);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const scanHandeler=(result)=>{
    setResultt(result)

    setShowQr(false)
  }
  
  const handleScan = async (scanData) => {
    
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
    qrScanHandeler(scanData)
      setStartScan(false);
      setLoadingScan(false);
      setShowQr(false)
      
      
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const handleClose=()=>{
    setStartScan(false);
    
    setLoadingScan(false);
    setShowQr(false)
  }
  return(

<div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
          <div className="800px:w-[50%] h-min w-[90%] flex-col items-center justify-center bg-[#fff] shadow rounded-md p-3">
 <button
   className={` text-2xl p-3  hover:drop-shadow-xl h1ar hover:bg-light-gray`}
                  style={{
                  color:"rgb(153, 171, 180)",
                  borderRadius:"50%" }}
                  onClick={handleClose}
                ><MdOutlineCancel /></button>
            
      <div className=" w-full flex-col justify-center items-center">
          <h4 className="pt-3
         h1ar text-center text-xl">
            {" "}
            {t("Scan the code located at the top of the invoice")}
          </h4>
      {startScan && (
        <div className="w-full  flex flex-wrap justify-center items-center">
          
          <select className="my-5" onChange={(e) => setSelected(e.target.value)}>
            <option className="par" value={"environment"}>{t("Back Camera")}</option>
            <option className="par" value={"user"}>{t("Front Camera")}</option>
          </select>
          <QrReader
            facingMode={selected}
            delay={500}
            onError={handleError}
            onScan={handleScan}
            // chooseDeviceId={()=>selected}
            style={{ width: "300px" }}
          />
        </div>
      )}
      <p>{resultt}</p></div></div>
    </div>
    )
}
export default QrCodeScanner
