import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#FF5C8E');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [currentDir, setCurrentDir] = useState('rtl');
  const [currentLang, setCurrentLang] = useState('ar');
const [currentCategory,setCurrentCategory]=useState('')
const [currentRatings,setCurrentRatings]=useState('')

const setCategory=(category)=>{

  setCurrentCategory(category);

  localStorage.setItem('categoryFilter', category);

}
const setRatings=(ratings)=>{

  setCurrentRatings(ratings);

  localStorage.setItem('ratingsFilter', ratings);}
  const setMode = (Mode) => {
    setCurrentMode(Mode);
    localStorage.setItem('themeMode', Mode);
  };
  const setDir = (lang) => {
    setCurrentDir(lang);
    localStorage.setItem('themeDir', lang);
    
    const newlang=(lang ==="ltr"?"en":"ar")
    
    setCurrentLang(newlang);
    localStorage.setItem('themeLang',newlang);
   };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };

  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={{currentRatings,setRatings,currentCategory,setCategory,setCurrentLang,currentLang,setCurrentDir,currentDir,setDir, currentColor, currentMode, activeMenu, screenSize, setScreenSize, handleClick, isClicked, initialState, setIsClicked, setActiveMenu, setCurrentColor, setCurrentMode, setMode, setColor, themeSettings, setThemeSettings }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
