import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineMinus,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FaBoxArchive } from "react-icons/fa6";
import { ImBarcode } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { backend_url, server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { IoCloseSharp } from "react-icons/io5";
import { BsCheck } from "react-icons/bs";

const ProductDetails = ({ data }) => {
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const [active, setActive] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error(        <span className="h1ar text-sm">{t("Product stock limited!")}</span>);
      } else {
        const cartData = {
          ...data,
          qty: count,
          selectedColors: colors,
          selectedSizes: sizes,
        };
        dispatch(addTocart(cartData));
        toast.success(        <span className="h1ar text-sm">{t("Item added to cart successfully!")}</span>);
      }
    }
  };
  const handleColorChange = (color) => {
    // قم بفحص ما إذا كان اللون محدد بالفعل وإزالته إذا كان موجودًا
    if (colors.some((c) => c === color)) {
      const updatedColors = colors.filter((c) => c !== color);
      setColors(updatedColors);
    } else if (colors.length < count) {
      // إضافة اللون المحدد إلى المصفوفة
      setColors([...colors, color]);
    }
  };
  const handleSizeChange = (size) => {
    // قم بفحص ما إذا كان اللون محدد بالفعل وإزالته إذا كان موجودًا
    if (sizes.some((c) => c === size)) {
      const updatedColors = sizes.filter((c) => c !== size);
      setSizes(updatedColors);
    } else if (sizes.length < count) {
      // إضافة اللون المحدد إلى المصفوفة
      setSizes([...sizes, size]);
    }
  };
  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data.shop._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error(        <span className="h1ar text-sm">{t("Please login to create a conversation")}</span>);
    }
  };
  const incrementCount = (data) => {
    if (data.stock - 1 < count) {
      toast.error(        <span className="h1ar text-sm">{t("Product stock limited!")}</span>);
    } else {
      setCount(count + 1);
    }
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
      if (colors.length >= count) {
        const updatedColors = colors.slice(0, -1); // حذف آخر عنصر من القائمة
        setColors(updatedColors);
      }
      if (sizes.length >= count) {
        const updatedSizes = sizes.slice(0, -1); // حذف آخر عنصر من القائمة
        setSizes(updatedSizes);
      }
    }
  };
  return (
    <>
      <div class="bg-main  dark:bg-main-dark-bg py-12 sm:py-16">
        {data ? (
          <div class="container mx-auto  dark:bg-main-dark-bg  px-4">
            <nav class="flex">
              <ol role="list" class="flex items-center">
                <li class="text-left">
                  <div class="-m-1">
                    <Link
                      to="/"
                      className="rounded-md p-1 text-sm h2ar font-medium text-gray-600 dark:text-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      {" "}
                      {t("Home")}{" "}
                    </Link>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>
                    <div class="-m-1">
                      <Link
                        to="/products"
                        class="rounded-md h2ar  p-1 text-sm font-medium text-gray-600 dark:text-gray-400 focus:text-gray-900 focus:shadow hover:text-gray-800"
                      >
                        {" "}
                        {t("Products")}{" "}
                      </Link>
                    </div>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>
                    <div class="-m-1">
                      <a
                        href="#"
                        class="rounded-md p-1 h2ar  text-sm font-medium text-gray-600 dark:text-gray-200 dark:border-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                        aria-current="page"
                      >
                        {" "}
                        {t("Product Details")}{" "}
                      </a>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
            <div class=" lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
              <div class="lg:col-span-3 lg:row-end-1">
                <div class="lg:flex lg:items-start">
                  <div class="lg:order-2 lg:ml-5">
                    <div class="max-w-xl overflow-hidden rounded-lg">
                      <img
                        class="h-full w-full max-w-full object-cover"
                        src={`${backend_url}${data && data.images[select]}`}
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                    <div className="flex-wrap flex-row  items-start lg:flex-col">
                      {data &&
                        data.images.map((i, index) => (
                          <button
                            type="button"
                            className={`flex-0  aspect-square mb-3 ltr:mr-2 rtl:ml-2  h-20 overflow-hidden rounded-lg border-2  ${
                              select === index
                                ? "border-mypink"
                                : "border-gray-900 dark:border-gray-400"
                            } text-center`}
                            onClick={() => setSelect(index)}
                          >
                            <img
                              className="h-full w-full object-cover"
                              src={`${backend_url}${i}`}
                              alt={`Image ${index}`}
                            />
                          </button>
                        ))}
                    </div>
                  </div>
                </div>
              </div>

              <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
                <h1 class="sm: text-2xl h0ar font-bold text-gray-900 dark:text-gray-200 sm:text-3xl">
                  {data.name}
                </h1>
                <p class="sm: text-md par font-bold text-gray-700 dark:text-gray-200 mt-3  sm:text-md mx-2">
                  {data.description}
                </p>
                <br />
                <p class="sm: text-sm par font-bold text-red-700 dark:text-gray-200 sm:text-sm ">
                  {t("Product Rating")}
                </p>
                <Ratings rating={data?.ratings} />

                <div className="flex flex-wrap items-center mt-6 justify-between pr-3 space-y-4 border-t pt-4 sm:flex-row sm:space-y-0">
                  <div className="flex items-center border-1 mt-5 border-color rounded">
                    <p
                      className="p-2  dark:border-gray-600 border-color text-red-600 "
                      onClick={decrementCount}
                    >
                      <AiOutlineMinus />{" "}
                    </p>
                    <p className="py-2 px-5 border-r-1 border-l-1 text-xl border-color  dark:border-gray-600 text-green-600">
                      {" "}
                      {count}
                    </p>
                    <p
                      className="p-2  border-color dark:border-gray-600 text-green-600"
                      onClick={() => incrementCount(data)}
                    >
                      <AiOutlinePlus />
                    </p>
                  </div>

                  <div class="mt-3 flex select-none flex-wrap items-center gap-1">
                    <label class="">
                      <button
                        className="dark:text-gray-400 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar"
                        onClick={
                          click
                            ? () => removeFromWishlistHandler(data)
                            : () => addToWishlistHandler(data)
                        }
                      >
                        {t("Add to wishlist")}{" "}
                        {click ? (
                          <AiFillHeart
                            className="cursor-pointer"
                            color={click ? "red" : "#333"}
                            title="Remove from wishlist"
                          />
                        ) : (
                          <AiOutlineHeart
                            className="cursor-pointer"
                            color={click ? "red" : "#333"}
                            title="Add to wishlist"
                          />
                        )}
                      </button>
                    </label>
                    <label class="">
                      <p
                        className=" dark:text-gray-400 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar"
                        onClick={() => addToCartHandler(data._id)}
                      >
                        {t("Add to card")}{" "}
                        <AiOutlineShoppingCart className="ml-1" />
                      </p>
                    </label>
                  </div>
                </div>
                <br />
                <p className="flex items-center gap-1  par font-regular text-gray-900  dark:text-gray-200 sm:text-sm ">
                  <span>
                    <FaBoxArchive style={{ color: "#D32F2F" }} />
                  </span>{" "}
                  {t("Stock")} {data.stock}
                </p>
                <br />
                <p className="sm:text-sm items-center  flex par font-regular gap-1 text-gray-900 dark:text-gray-200">
                  <span>
                    <ImBarcode style={{ color: "#D32F2F" }} />
                  </span>{" "}
                  {t("SKU")} {data.sku}
                </p>
                <div>
                  <label className="pb-2 h1ar mb-4">{t("Colors")}</label>
                  <div className="mt-4 grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] lg:grid-cols-6 800px:gap-4 lg:gap-2 xl:grid-cols-8 xl:gap-4 mb-2">
                    {data.colors?.map((color, index) => (
                      <div
                        key={index}
                        className="items-center justify-center align-center p-x2 flex-col relative"
                      >
                        {/** colorssssssss     ----------------------------------------------- */}
                        <div
                          className="px-3 items-center border border-mypink border-dashed w-auto py-2 rounded-lg "
                          onClick={() => handleColorChange(color)}
                          style={{
                            overflow: "hidden",
                            height: "fit-content",

                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <h1 className="h1ar text-gray-600 dark:text-gray-200 text-lg text-center">
                            {color}
                          </h1>
                          {colors.includes(color) && (
                            <BsCheck
                              className="ml-2 rtl:mr-2 text-2xl"
                              style={{
                                color: "#000",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  {/** -------------------------------------------------sizes---------------------------------------------------------- */}
                  <label className="pb-2 h1ar mb-4">{t("Sizes")}</label>
                  <div className="mt-4 grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] lg:grid-cols-6 800px:gap-4 lg:gap-2 xl:grid-cols-8 xl:gap-4 mb-2">
                    {data.sizes?.map((size, index) => (
                      <div
                        key={index}
                        className="items-center justify-center align-center p-x2 flex-col relative"
                      >
                        <div
                          className="px-3 items-center border border-mypink border-dashed py-2 rounded-lg "
                          onClick={() => handleSizeChange(size)}
                        >
                          <h1 className="h1ar text-gray-600 dark:text-gray-200 text-lg text-center">
                            {size}
                          </h1>
                          {sizes.includes(size) && (
                            <BsCheck
                              className="ml-2 rtl:mr-2 text-2xl"
                              style={{
                                color: "#000",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                  <div class="flex items-end">
                    <h1 class="text-3xl h1ar dark:text-gray-200 font-bold">
                      {data.originalPrice === 0
                        ? data.originalPrice * count
                        : data.discountPrice * count + " " + t("SAR")}
                    </h1>
                    <span className={`${styles.price}`}>
                      {" "}
                      {data.originalPrice
                        ? data.originalPrice * count + " " + t("SAR")
                        : null}
                    </span>
                  </div>
                  <label class="">
                    <input
                      type="radio"
                      name="type"
                      value="Groud"
                      class="peer sr-only"
                    />
                    <p
                      onClick={handleMessageSubmit}
                      className="dark:text-gray-400 mx-5 flex items-center gap-1 rounded-lg border dark:border-gray-400 border-black px-6 py-2 font-bold h1ar"
                    >
                      {t("Send Message")} <AiOutlineMessage className="ml-1" />
                    </p>
                  </label>
                </div>

                <ul class="mt-8 space-y-2">
                  <li class="flex items-center text-left text-sm font-medium text-gray-600">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <img
                        src={`${backend_url}${data?.shop?.avatar}`}
                        alt=""
                        className="w-[50px] h-[50px] rounded-full ltr:mr-2 rtl:ml-2"
                      />
                    </Link>
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                  </li>
                  <p class="ml-2 text-sm h2ar font-medium text-gray-500">
                    {" "}
                    ({averageRating}/5) {t("Shop Ratings")}
                  </p>
                  <Ratings rating={averageRating} />
                </ul>
                <br />
              </div>
            </div>{" "}
            <ProductDetailsInfo
              data={data}
              products={products}
              totalReviewsLength={totalReviewsLength}
              averageRating={averageRating}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);
  const { t } = useTranslation();
  return (
    <div className="lg:col-span-3 bg-main dark:bg-main-dark-bg">
      <div className="border-b border-gray-300">
        <nav className="flex gap-4">
          <h5
            className={`${
              active === 1
                ? " border-b-2 dark:border-gray-200 dark:text-gray-200 border-gray-900 py-4 text-lg h1ar font-medium text-mypink hover:border-gray-400 hover:text-gray-800"
                : "inline-flex items-center dark:border-gray-400 dark:text-gray-400 border-b-2 border-transparent py-4 h1ar text-md font-medium text-gray-600"
            }  cursor-pointer `}
            onClick={() => setActive(1)}
          >
            {t("Product Details")}
          </h5>

          <h5
            className={`${
              active === 2
                ? "border-b-2  dark:border-gray-200 dark:text-gray-200 border-gray-900 h1ar py-4 text-lg font-medium text-mypink hover:border-gray-400 hover:text-gray-800"
                : "inline-flex items-center dark:border-gray-400 dark:text-gray-400  border-b-2 border-transparent h1ar py-4 text-md font-medium text-gray-600"
            } cursor-pointer  `}
            onClick={() => setActive(2)}
          >
            {t("Product Reviews")}
          </h5>

          <h5
            className={`${
              active === 3
                ? " border-b-2 dark:border-gray-200 dark:text-gray-200 border-gray-900 py-4 text-lg h1ar font-medium text-mypink hover:border-gray-400 hover:text-gray-800"
                : "inline-flex items-center dark:border-gray-400 dark:text-gray-400 border-b-2 border-transparent h1ar py-4 text-md font-medium text-gray-600"
            } cursor-pointer  `}
            onClick={() => setActive(3)}
          >
            {t("Seller Information")}
          </h5>
        </nav>
      </div>

      <div className="mt-8 flow-root rtl:h2ar sm:mt-12">
        {active === 1 ? (
          <>
            <p className="mt-4 dark:text-gray-200">{data.description}</p>
          </>
        ) : null}

        <div className="lg:col-span-3">
          {active === 1 ? (
            <>
              <p className="py-2 text-[18px] dark:text-gray-200 leading-8 pb-10 whitespace-pre-line">
                {data.description}
              </p>
            </>
          ) : null}

          {active === 2 ? (
            <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
              {data &&
                data.reviews &&
                data.reviews.map((item, index) => (
                  <div className="w-full flex my-2" key={index}>
                    <img
                      src={`${backend_url}/${item.user.avatar}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full"
                    />
                    <div className="pl-2">
                      <div className="w-full flex items-center">
                        <h1 className="font-[500] mr-3 dark:text-gray-200">
                          {item.user.name}
                        </h1>
                        <Ratings rating={data?.ratings} />
                      </div>
                      <p className="dark:text-gray-400">{item.comment}</p>
                    </div>
                  </div>
                ))}

              <div className="w-full flex justify-center dark:text-gray-200 rtl:h0ar">
                {data.reviews && data.reviews.length === 0 && (
                  <h5>{t("No Reviews have for this product")}</h5>
                )}
              </div>
            </div>
          ) : null}

          {active === 3 && (
            <div className="w-full block 800px:flex p-5">
              <div className="w-full 800px:w-[50%]">
                <Link to={`/shop/preview/${data.shop._id}`}>
                  <div className="flex items-center">
                    <img
                      src={`${backend_url}${data?.shop?.avatar}`}
                      className="w-[50px] h-[50px] rounded-full"
                      alt=""
                    />
                    <div className="pl-3 rtl:pr-3">
                      <h3
                        className={`${styles.shop_name} dark:text-gray-200 rtl:h1ar`}
                      >
                        {data.shop.name}
                      </h3>
                      <h5 className="pb-2 text-[15px] rtl:h1ar dark:text-gray-400">
                        ({averageRating}/5) {t("Ratings")}
                      </h5>
                    </div>
                  </div>
                </Link>
                <p className="pt-2 dark:text-gray-400">
                  {data.shop.description}
                </p>
              </div>
              <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col  items-end">
                <div className="text-left">
                  <h5 className="font-[600] dark:text-gray-200">
                    {t("Joined on")}:{" "}
                    <span className="font-[500] dark:text-gray-400">
                      {data.shop?.createdAt?.slice(0, 10)}
                    </span>
                  </h5>
                  <h5 className="font-[600] pt-3 rtl:h1ar dark:text-gray-200">
                    {t("Total Products")}:{" "}
                    <span className="font-[500] dark:text-gray-400">
                      {products && products.length}
                    </span>
                  </h5>
                  <h5 className="font-[600] pt-3 rtl:h1ar dark:text-gray-200">
                    {t("Total Reviews")}:{" "}
                    <span className="font-[500] dark:text-gray-400">
                      {totalReviewsLength}
                    </span>
                  </h5>
                  <Link to="/">
                    <div
                      className={`!rounded-[4px] !h-[39.5px] mt-3 dark:text-gray-200`}
                    >
                      <h4 className="text-white rtl:h1ar">{t("Visit Shop")}</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
{
  /*  <div>
<label className="pb-2 h1ar mb-4">{t("Colors")}</label>
<div className="mt-4 grid grid-cols-4 gap-1 md:grid-cols-6 md:gap-[25px] lg:grid-cols-6 lg:gap-2 xl:grid-cols-8 xl:gap-2 mb-2">
  {COLORS.filter((item) =>
    data.colors.includes(item.code)
  ).map((item, index) => (
    <div
      key={index}
      className="items-center justify-center align-center p-x2 flex-col"
    >
      <button
        type="button"
        className="h-10 w-10 rounded-full  cursor-pointer"
        style={{
          backgroundColor: item.code,
          border: "1px dashed ",
          borderColor: "#000",
        }}
        onClick={() => handleColorChange(item.code)}
      >
        {colors.includes(item.code) && (
          <BsCheck
            className="ml-2 rtl:mr-2 text-2xl"
            style={{
              color: item.name === "black" ? "#fff" : "#000",
            }}
          />
        )}
      </button>
      <p
        className="h1ar text-md "
        style={{
          color: item.name === "white" ? "#000" : item.code,
        }}
      >
        {t(item.name)}
      </p>
    </div>
  ))}
</div>
</div>*/
}
