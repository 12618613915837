import React, { useRef } from "react";

import { AiOutlineArrowRight } from "react-icons/ai";

import { PiMicrosoftExcelLogoFill } from "react-icons/pi";

import { FaRegFilePdf } from "react-icons/fa";

import styles from "../../styles/styles";

import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";

import { Link } from "react-router-dom";

import { CSVLink } from "react-csv";

import { useReactToPrint } from "react-to-print";

import { useEffect } from "react";

import { getAllOrdersOfUser } from "../../redux/actions/order";

import { useTranslation } from "react-i18next";

import { ThemeProvider } from "@mui/material/styles";

import { createTheme } from "@mui/material/styles";

import { useStateContext } from "../../contexts/ContextProvider";

const AllUserRefundOrders = () => {
  const { user } = useSelector((state) => state.user);

  const { orders } = useSelector((state) => state.order);

  const dispatch = useDispatch();

  const { currentDir } = useStateContext();

  const { t } = useTranslation();

  const componentPDF = useRef();

  useEffect(() => {
    dispatch(getAllOrdersOfUser(user._id));
  }, []);

  const eligibleOrders =
    orders && orders.filter((item) => item.status === "Processing refund");

  const theme = createTheme({
    direction: currentDir,
  });

  const columns = [
    {
      field: "orderNumber",
      headerName: <span className="h1ar text-sm">{t("Order ID")}</span>,
      minWidth: 150,
      flex: 0.7,
    },

    {
      field: "status",

      headerName: <span className="h1ar text-sm">{t("Status")}</span>,

      minWidth: 130,

      flex: 0.7,

      renderCell: (params) => {
        const statusColor =
          params.value === "Delivered"
            ? "#1def68"
            : "#ff8842" && params.value === "Processing"
            ? "#ef1d2d"
            : "#ff8842";

        const statusStyle = {
          display: "inline-block",

          width: "12px",

          height: "12px",

          borderRadius: "50%",

          backgroundColor: statusColor,

          marginRight: "5px",

          fontWeight: "bold",

          marginRight: 6,

          marginLeft: 6,
        };

        return (
          <span>
            <span style={statusStyle}></span>

            <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
          </span>
        );
      },
    },

    {
      field: "itemsQty",

      headerName: <span className="h1ar text-sm">{t("Items Qty")}</span>,

      type: "number",

      minWidth: 130,

      flex: 0.7,
    },

    {
      field: "total",

      headerName: <span className="h1ar text-sm">{t("Total")}</span>,

      type: "number",

      minWidth: 130,

      flex: 0.8,
    },

    {
      field: " ",

      flex: 1,

      minWidth: 150,

      headerName: <span className="h1ar text-sm">{t("Order Details")}</span>,

      type: "number",

      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <Link to={`/user/order/${params.id}`}>
              <div className="flex w-full items-center justify-center">
                <button className={`${styles.buttoon}`}>
                  <AiOutlineArrowRight size={20} />
                </button>
              </div>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  eligibleOrders &&
    eligibleOrders.forEach((item) => {
      row.push({
        orderNumber: item.orderNumber,
        itemsQty: item.cart.length,
        total: "RAS" + item.totalPrice,
        status: item.status,
        id: item._id,
      });
    });

  const genratePDF = useReactToPrint({
    content: () => componentPDF.current,

    documentTitle: "AllOrders",

    onAfterPrint: () => alert("Data saved in PDF"),
  });

  return (
    <div className="flex-col relative w-full items-center px-8 pt-14">
      <div className="flex items-center mx-8 mt-4 ">
        <div
          onClick={genratePDF}
          className="flex gap-2 w-full items-center  justify-center"
        >
          <button
            className={`${styles.buttoon}items-center inline-flex items-center`}
            style={{
              direction: "ltr",
            }}
          >
            {t("PDF")}
            <span className="inline ml-1 w-4 h-4 ">
              <FaRegFilePdf />
            </span>
          </button>

          <button
            className={`${styles.buttoonExel}inline-flex items-center flex  `}
            style={{
              direction: "ltr",
            }}
          >
            {eligibleOrders ? (
              <CSVLink data={eligibleOrders}>{t("Exel")}</CSVLink>
            ) : null}
            <span className="inline ml-1 w-4 h-4">
              <PiMicrosoftExcelLogoFill />
            </span>
          </button>
        </div>
      </div>

      <br />

      <ThemeProvider theme={theme}>
        <div
          ref={componentPDF}
          className="m-2 md:m-10 mt-24 p-2 overflow-auto md:p-10 bg-white rounded-3xl"
        >
          <DataGrid
            rows={row}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            initialState={{
              ...row.initialState,

              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 20, 50]}
          />
        </div>
      </ThemeProvider>
    </div>
  );
};
export default AllUserRefundOrders;
