import React from "react";
import styles from "../../../styles/styles";
import { AiOutlineFolderAdd, AiOutlineGift, AiOutlineShoppingCart } from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineCancel, MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import { VscNewFile } from "react-icons/vsc";
import { BiMessageSquareDetail } from "react-icons/bi";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import logo from "../../../Assests/images/Revo One-svg.svg"
import { useStateContext } from "../../../contexts/ContextProvider.js"
import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
const links = [
  {
    title: "Dashboard",
    links: [
      {
        name:"Ecommerce",
        icon: <FiShoppingBag />,
        linkSide:"dashboard"
      },
    ],
  },

  {
    title:"Orders",
    links: [
      {
        name:"all Order",
        icon: <AiOutlineShoppingCart />,
        linkSide:"dashboard-orders"
      }
    ],
  },
  {
    title:"Products",
    links: [
      {
        name:"All Products",
        icon: <FiPackage />,
        linkSide:"dashboard-products"
      },
      {
        name:"Create Product",
        icon: <AiOutlineFolderAdd  />,
        linkSide:"dashboard-create-product"
      },
    
    ],},
    {
    title:"Events",
    links: [
      {
        name:"All Events",
        icon: <MdOutlineLocalOffer/>,
        linkSide:"dashboard-events"
      },
      {
        name:"Create Event",
        icon: <VscNewFile   />,
        linkSide:"dashboard-create-event"
      },
    
    ],
  },
  {
    title:"Other",
    links: [
      {
        name:"Withdraw Money",
        icon: <CiMoneyBill/>,
        linkSide:"dashboard-withdraw-money"
      },
      {
        name:"Shop Inbox",
        icon: <BiMessageSquareDetail  />,
        linkSide:"dashboard-messages"
      },
      {
        name:"Discount Codes",
        icon: <AiOutlineGift  />,
        linkSide:"dashboard-coupouns"
      },
      {
        name:"Refunds",
        icon: <HiOutlineReceiptRefund  />,
        linkSide:"dashboard-refunds"
      },
      {
        name:"Setting",
        icon: <CiSettings  />,
        linkSide:"settings"
      },
    ],
  },

  
];





function DashboardSideBar() {
  const { currentColor, activeMenu, setActiveMenu, screenSize } = useStateContext();
  const { t} = useTranslation();
  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2';
 
  return (
    
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
    {activeMenu && (
      <>
        <div className="flex justify-between items-center">
          <Link to="/" onClick={handleCloseSideBar} className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900">
           <img src={logo} alt="" />  <span>Revoone</span>
          </Link>
          <div content="Menu" position="BottomCenter">
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              style={{ color: currentColor }}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
            >
              <MdOutlineCancel />
            </button>
          </div>
        </div>
        <div className="mt-10 ">
          {links.map((item) => (
            <div key={item.title}>
              <p className="text-gray-400 h0ar dark:text-gray-400 m-3 mt-4 ">
              {t(item.title)}
              </p>
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.linkSide}`}
                  key={link.name}
                  onClick={handleCloseSideBar}
                  style={({ isActive }) => ({
                    backgroundColor: isActive ? currentColor : '',
                  })}
                  className={({ isActive }) => (isActive ? activeLink : normalLink)}
                >
                  {link.icon}
                  <span className="h1ar ">{t(link.name)}</span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
        
      </>
    )}
  </div>


  );
}

export default DashboardSideBar;
