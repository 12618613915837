import React from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import UserOrderDetails from "../components/UserOrderDetails";
import ThemeSettingsButton from '../components/ThemeSettingsButton';
import BottomNav from "../components/Layout/BottomNav"
const OrderDetailsPage = () => {
  return (
    < >
        <Header /> 
        <UserOrderDetails />
        <Footer /> 
       <BottomNav />
    </>
  )
}

export default OrderDetailsPage
