import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { BsCartCheckFill } from "react-icons/bs";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { FaBox } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { Buttoon } from "../Charts";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { backend_url } from "../../server";
import { addTocart } from "../../redux/actions/cart";

const Wishlist = ({ setOpenWishlist }) => {
  
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
  
    removeFromWishlistHandler(data)
    setOpenWishlist(false)
  };

  return (
    <div className="fixed top-0 right-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed sidebar top-0 right-0 h-full w-[70%] 800px:w-[20%] dark:text-gray-200 dark:bg-[#484B52] bg-white flex flex-col overflow-y-auto shadow-sm">
        {wishlist && wishlist.length === 0 ? (
          <div className="w-full h-screen flex-col p-2 justify-center">
            <div
              className="flex w-full justify-start left-2 top-10"
              onClick={() => setOpenWishlist(false)}
            >
              <Buttoon
                icon={<MdOutlineCancel />}
                color="rgb(153, 171, 180)"
                bgHoverColor="light-gray"
                size="2xl"
                borderRadius="50%"
              />
            </div>
            <h5 className="justify-center px-5 h1ar text-xl center py-12">
              {t("Wishlist Items is empty!")}
            </h5>
          </div>
        ) : (
          <>
            <div>
              <div
                className="flex w-full justify-start left-2 top-10"
                onClick={() => setOpenWishlist(false)}
              >
                <Buttoon
                  icon={<MdOutlineCancel />}
                  color="rgb(153, 171, 180)"
                  bgHoverColor="light-gray"
                  size="2xl"
                  borderRadius="50%"
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <AiOutlineHeart size={25} />
                <h5 className="pl-2 text-[20px] h1ar font-[500]">
                  {wishlist && wishlist.length} {t("items")}
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {wishlist &&
                  wishlist.map((item, index) => (
                    <CartSingle
                      key={index}
                      data={item}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({
  
  data,
  removeFromWishlistHandler,
  addToCartHandler,
}) => {
  const [value, setValue] = useState(1);
  const totalPrice = data.discountPrice * value;
const { t } = useTranslation();
  return (
    <div className="border-b">
            <div className="justify-end right-2 pt-1 px-4 flex  top-0">  

    
            <div className="mx-2 bg-green-100  rounded-full border border-green-500" onClick={() => addToCartHandler(data)}>
                          <Buttoon
            icon={<BsCartPlus />}
            color="green"
            bgHoverColor=""
            size="xl"
            borderRadius="50%"
            
          /></div>
      
            <div  className="border border-red-500 bg-red-100  rounded-full" onClick={() => removeFromWishlistHandler(data)}>
              <Buttoon
            icon={<AiOutlineDelete />}
            color="red"
            bgHoverColor=""
            size="xl"
            borderRadius="50%"
            
          /></div></div>
      <div className="w-full py-2 px-2 flex items-center">


        <img
          src={`${backend_url}${data?.images[0]}`}
          alt=""
          className="rounded-lg border-1 h-24 mx-4 w-24"
        />

        <div className="ltr:mr-1 h1ar rtl:ml-1">
          <h1>{data.name}</h1>
          <h4 className="font-[600] pt-3 text-md text-[#d02222] h2ar">
            {t("SAR")} {totalPrice}
          </h4>
        </div>

      </div>
    </div>
  );
};

export default Wishlist;
