import React from "react";
import CartDetails from "../components/cart/CartDetails";

function CartPage() {
  return (
    <div>
      <CartDetails />
    </div>
  );
}

export default CartPage;
