import React, { useEffect, useState } from "react";
import {bestSelling} from "../redux/actions/product";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import Footer from "../components/Layout/Footer";
import Pagination from "../components/Pagination"
import { useDispatch, useSelector } from "react-redux";
const BestSellingPage = () => {
  const [pageNumber,setPageNumber]=useState(0)
const [pageSize,setPageSize]=useState(10)
  const {bestSellingProducts,isLoading,totalPages} = useSelector((state) => state.products);
  
const dispatch = useDispatch();
  useEffect(() => {
   dispatch(bestSelling( pageSize,pageNumber));
  }, [pageSize,pageNumber]);
const handlePageChange = ({selected}) => {
    setPageNumber(selected);
  };
  return (
   <>
   {
    isLoading ? (
      <Loader />
    ) : (
       <div className="dark:bg-main-dark-bg bg-main-bg">
      <Header activeHeading={2} />
      <br />
      <br />
      <div className={`${styles.section}`}>
       <div className="grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {bestSellingProducts && bestSellingProducts.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        {bestSellingProducts && bestSellingProducts.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No products Found!
          </h1>
        ) : null}
      
      <Pagination currentPage={pageNumber}
 totalPages={totalPages}
onPageChange={handlePageChange}
/>
      </div>
      <Footer />
    </div>
    )
   }
   </>
  );
};

export default BestSellingPage;
