import React, { useEffect, useState,useRef } from "react";
import styles from "../../styles/styles";
import { BsFillBagFill } from "react-icons/bs";
import TopInvice from "../../components/Form/TopInvice"
import {useReactToPrint} from "react-to-print";
import logo from "../../Assests/images/Revo One-svg.svg";
import { useStateContext } from "../../contexts/ContextProvider";
import { Link, useNavigate, useParams,useRoutes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Loader from "../../components/Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { getCShiping, getEShiping } from "../../redux/actions/shipings";
const Invoice =({route})=>{
  const {currentColor,currentDir}=useStateContext();
  const componentPDF =useRef();
  const { id } = useParams();
  const { Cshiping, Eshiping, isLoadingCs, isLoadingEs } = useSelector(
    (state) => state.shipings
  );
  
  const dispatch = useDispatch();
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
   const data=orders && orders.find((item) => item._id == id)
  }, []);
  useEffect(() => {
    dispatch(getEShiping());
    dispatch(getCShiping());
  }, []);
  const{t}=useTranslation()
  const data=orders && orders.find((item) => item._id == id)
  const theme = createTheme({
    direction:"ltr"
    });
  const columns = [
    { field: "productNumber", headerName: (
<div className="flex-col">
<h5 className="h1ar text-sm">product Id</h5>
<h5 className="h1ar text-sm">معرف المنتج</h5>
</div>
), minWidth: 150, flex: 0.7 },

    {
      field: "name",
      headerName: (
        <div className="flex-col">
<h5 className="h1ar text-sm">Product Name</h5>
<h5 className="h1ar text-sm">إسم المنتج</h5>
</div>),
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor = params.value === "Delivered" ? "#1def68" : "#ff8842" && params.value === "Processing"?"#ef1d2d":"#ff8842" ;
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
          marginRight:6,
          marginLeft:6
        };
        return (
          <span>
          <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
        </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: (
              <div className="flex-col">
<h5 className="h1ar text-sm">Items Qty</h5>
<h5 className="h1ar text-sm">الكمية</h5>
</div>),
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "discountPrice",
      headerName:  (
                <div className="flex-col">
<h5 className="h1ar text-sm">Price</h5>
<h5 className="h1ar text-sm">السعر</h5>
</div>),
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "total",
      headerName:  (
                <div className="flex-col">
<h5 className="h1ar text-sm">Total</h5>
<h5 className="h1ar text-sm">الإجمالي</h5>
</div>),
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
  ];
  
  const row = [];
  data &&
    data.cart.forEach((item) => {
      row.push({
        productNumber: item.productNumber,
        itemsQty: item.qty,
        total: t("RAS") + item.discountPrice *item.qty,
        discountPrice:t("RAS") + item.discountPrice,
        name: item.name,
        id:item._id
      });
    });
  
  const genratePDF=useReactToPrint({
  content:()=>componentPDF.current,
documentTitle:"AllOrders",
onAfterPrint:()=>alert("Data saved in PDF")

});

  return(    <>
      {isLoading ? (
        <Loader />
      ) : (
      
      <frameElement dir={"ltr"} className="flex-col w-full items-center px-8 pt-14">
        <div className="flex justify-center items-center mx-8 mt-4 mb-5">
        <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
        onClick={genratePDF}
        style={{background:currentColor}}
        > 
{t("Export and print")}
        </button>
     
        </div>
        <div className="pt-4" ref={componentPDF}>
        
    {/* ////////imag and qr ////////*/}
        <div className="flex items-center justify-between px-4">
                    <div
              className="items-center gap-3  text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} alt="" /> <span>Revoone</span></div>
<div className=" flex items-center justify-center border p-1 border-black rounded-md">
    <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}> <QRCode size={256} style={{ height: "auto", maxWidth: "100%", width: "100%" }} value={data?.qrCode} viewBox={`0 0 256 256`} /> </div>
        <div className=" w-[50%] items-center p-1  flex-col justify-center">
            <p className="par text-sm text-center">Scan the code  to confirm the receipt</p>
            <p className="par text-sm text-center">إمسح الكود لتأكيد التسليم</p>
</div>
    </div>

        </div>
{/* user name and date   */}

<div className="w-full flex  pt-4 px-10  lg:flex-nowrap gap-3">
       
        <div className=" flex  w-full  lg:w-[40%] flex-col mb-3 lg:ml-3 ">
          <div className={`items-center  flex justify-between border border-black rounded-t-lg`}>
            <div className=" w-[50%] items-center p-1  flex-col justify-center">
            <h5 className="par text-sm text-center">invoice num</h5>
<h5 className="par text-sm text-center">رقم الفاتوره  </h5>
</div>
                    <div className=" w-[50%]  items-center p-2 flex justify-center">
 <span className="text-sm par  text-gray-800 ">{data?.orderNumber}</span></div>

        </div>
        
        
        
                  <div className={`items-center  flex justify-between border border-black rounded-b-lg`}>
            <div className=" w-[50%] items-center p-1  flex-col justify-center">
            <h5 className="par text-sm text-center">Placed on</h5>
<h5 className="par text-sm text-center">تاريخ الطلب  </h5>
</div>
                    <div className=" w-[50%]  items-center p-2 flex justify-center">
 <span className="text-sm par  text-gray-800 ">{data?.createdAt?.slice(0, 10)}</span></div>

        </div>
        </div> 
        
        
        
        
        
        
                <div className="border flex-col w-full lg:w-[50%] border-black rounded-lg border-1">
         <div className=" w-full items-center p-1 flex-col justify-center">
            <h5 className="h1ar text-md text-center">Shipping Information</h5>
<h5 className="h1ar text-md text-center">معلومات الشحن  </h5>
</div>
   <TopInvice
        titleAr={"إسم العميل "}
        titleEn={"customer name"}
        value={`${data?.user.name}`}

        />
   <TopInvice
        titleAr={"رقم الهاتف"}
        titleEn={"Phone Number"}
        value={`${data?.user?.phoneNumber}`}
        />
           <TopInvice
        titleAr={"المدينة"}
        titleEn={"City"}
        value={`${data?.shippingAddress.city}`}
        />
        <TopInvice
        titleAr={"إسم الشارع"}
        titleEn={"Street Name"}
        value={`${data?.shippingAddress.street}`}
        />
   <TopInvice
        titleAr={"إسم/رقم المبنى"}
        titleEn={"Building n/nu"}
        value={`${data?.shippingAddress.building}`}
        />
   <TopInvice
        titleAr={"أقرب معلم"}
        titleEn={"Nearest place"}
        value={`${data?.shippingAddress.nearestTeacher}`}
         noborder={true}
        />

        </div>
        </div>
        
        <br />
        <ThemeProvider theme={theme}>
        <div  className="md:my-5 lg:my-10 p-2 lg:px-10 md:p-5 bg-white rounded-3xl">
          <Table
          columns={columns}
          row={row}
          
/>
        
        </div></ThemeProvider>
        <div className="mx-10">
              <div className="border rounded-2xl border-black">
            {data &&
        data?.cart.map((item, index) => (
                      <div className="border-b border-black items-center flex justify-between">
            <div className=" w-[50%]  items-center p-2 flex-col justify-center">
            <h5 className="h1ar text-sm text-center">Product{index+1}</h5>
<h5 className="h1ar text-sm text-center">{index+1} المنتج</h5> </div>

                    <div className=" w-[50%]  items-center p-2 flex justify-center">  <span className="text-md h2ar  ">
            {item.discountPrice}
          </span>
</div></div>
          ))}       
          
          
          
          
          
          
            <div className="border-b border-black items-center  flex justify-between">
            <div className=" w-[50%] border-black items-center p-2 flex-col justify-center">
<h5 className="h1ar text-sm text-center">Items Qty</h5>
<h5 className="h1ar text-sm text-center">الكمية</h5> </div>
                    <div className=" w-[50%]  items-center p-2 flex justify-center">  <span className="text-md h2ar  ">
            {data?.cart.length}
          </span>
</div></div>

            <div className="border-b border-black items-center  flex justify-between">
            <div className=" w-[50%]  items-center p-2 flex-col justify-center">
   <h5 className="h1ar text-sm text-center">Shiping</h5>
<h5 className="h1ar text-sm text-center">مصاريف الشحن</h5></div>
      <div className=" w-[50%]  items-center p-2 flex justify-center">  <span className="text-md h2ar">
            {data?.paymentInfo.type =="Cash On Delivery" ? Cshiping?.shipingAmount :Eshiping?.shipingAmount }
          </span>
</div></div>
      <div className=" items-center  flex justify-between">
            <div className=" w-[50%]  items-center p-2 flex-col justify-center">
            <h5 className="h1ar text-sm text-center">Total Price</h5>
<h5 className="h1ar text-sm text-center">السعر الإجمالي</h5>
</div>
                    <div className=" w-[50%]  items-center p-2 flex justify-center">  <span className="text-md h2ar  ">
            {data?.totalPrice.toFixed(2)} {t("SAR")}
          </span>
</div>
      </div>
      </div></div>
      </div>
        </frameElement>
        
      )}

    </>)
}


const Table = ({ columns, row }) => {
  
  return (
    <div className="w-full flex items-center justify-center" style={{ overflowX: 'auto' }}>
      <table className=" border border-black rounded-md p-1 w-full">
        <thead className="border-b border-black">
          <tr>
            {columns.map((column) => (
              <th key={column.field}>{column.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {row && row.map((row) => (
            <tr key={row.id}>
              {columns.map((column) => (
                <td key={`${row.id}-${column.field}`}>
                <div className="flex  justify-center items-center">
                <span className="text-center">
                {row[column.field]}
                </span></div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



export default Invoice