import React from 'react'

import ShopProfileData from "../../components/Shop/ShopProfileData";

const ShopPreviewPage = () => {
  return (<>
     <div className ="w-full h-full bg-main-bg dark:bg-main-dark-bg ">
   
         <ShopProfileData  isOwner={false} />
</div>

</>
  )
}

export default ShopPreviewPage
