import React from "react";
import ReactPaginate from "react-paginate";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const Pagination = ({ currentPage, totalPages, setPageNumber }) => {
  const showNextButton = currentPage !== totalPages - 1;
  const showPrevButton = currentPage !== 0;

  const changePageNumber = (selectedPage) => {
    setPageNumber(selectedPage);
  };

  return (
    <div className="py-3">
      <ReactPaginate
        previousLabel={
          showPrevButton ? (
            <span className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-black  dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ">
              <BsChevronLeft className="w-3 h-3 rtl:rotate-180" />
            </span>
          ) : null
        }
        nextLabel={
          showNextButton ? (
            <span className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:balck  dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
              <BsChevronRight className="w-3 h-3 rtl:rotate-180" />
            </span>
          ) : null
        }
        pageClassName="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-black  dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(selected) => changePageNumber(selected.selected)}
        forcePage={currentPage}
        containerClassName={
          "flex  items-center -space-x-px h-10 text-base  items-center   justify-center"
        }
        activeClassName={
          "px-4 h-10 leading-tight  bg-pink-50 text-pink-700 border-pink-700  dark:border-gray-700 hover:bg-pink-100  hover:text-pink-900  dark:bg-gray-700  dark:text-white z-10  text-gray-200 "
        }
      />
    </div>
  );
};

export default Pagination;
