import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { backend_url, server } from "../../server";
import { useParams } from "react-router-dom";

import { BsImages } from "react-icons/bs";
import { AiOutlineCamera, AiOutlinePlusCircle } from "react-icons/ai";
import { getProductforShop } from "../../redux/actions/product";
import Loader from "../../components/Layout/Loader";
import Spinner from "../../components/Charts/Spinner";
import SmallSpinner from "../../components/Charts/SmallSpinner";
import styles from "../../styles/styles";
import axios from "axios";
import { loadSeller } from "../../redux/actions/product";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useStateContext } from "../../contexts/ContextProvider";
const EditProductPage = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const { shopProduct, isSPLoading } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const { currentColor, currentDir } = useStateContext();
  const [isSpinner, setIsSpinner] = useState(false);
  const [isSpinnerAdd, setIsSpinnerAdd] = useState(false);
  const [isSpinnerEdit, setIsSpinnerEdit] = useState(false);
  const [isSpinnerDelete, setIsSpinnerDelete] = useState(false);
  const [newImage, setNewImage] = useState("");
  const { seller } = useSelector((state) => state.seller);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [sku, setSku] = useState("");
  const [select, setSelect] = useState(0);
  const [stock, setStock] = useState("");
  const { t } = useTranslation();
  const [allCategory, setAllCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [imageShow, setImageShow] = useState([]);
  useEffect(() => {
    dispatch(getProductforShop(id));
  }, [dispatch]);

  useEffect(() => {
    setName(shopProduct?.name);
    setCategory(shopProduct?.category);
    setOriginalPrice(shopProduct?.originalPrice);
    setDiscountPrice(shopProduct?.discountPrice);
    setDescription(shopProduct?.description);
    setStock(shopProduct?.stock);
    setSku(shopProduct?.sku);
    setImageShow(shopProduct?.images);
  }, [shopProduct]);

  const inmageHandle = (e) => {
    const files = e.target.files;
    const length = files.length;
    if (length > 0) {
      setImages([...images, ...files]);
      let imageUrl = [];
      for (let i = 0; i < length; i++) {
        imageUrl.push({ url: URL.createObjectURL(files[i]) });
      }
      setImageShow([...imageShow, ...imageUrl]);
    }
  };
  const handleNewImageChange = (files) => {
    const selectedImage = files[0];

    setNewImage(selectedImage);
  };

  ////add image
  const handleAddNewImage = async () => {
    setIsSpinnerAdd(true);
    const formData = new FormData();
    formData.append("newImage", newImage);
    try {
      await axios.put(`${server}/product/add-product-image/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      toast.success(
        <span className="h1ar text-sm">{t("تمت إضافة الصورة بنجاح!")}</span>
      );
      setIsSpinnerAdd(false);
      dispatch(getProductforShop(id));
      setImageShow(shopProduct.image);
      setNewImage("");
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSpinnerAdd(false);
    }
  };

  /////Edit image

  const handleImageChange = async (img, newImage) => {
    setIsSpinnerEdit(true);

    const formData = new FormData();

    formData.append("img", img);

    formData.append("newImage", newImage[0]);

    try {
      await axios.put(
        `${server}/product/update-product-image/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          withCredentials: true,
        }
      );

      toast.success(
        <span className="h1ar text-sm">{t("تم تحديث الصورة بنجاح!")}</span>
      );

      setIsSpinnerEdit(false);

      dispatch(getProductforShop(id));
      setImageShow(shopProduct.image);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSpinnerEdit(false);
    }
  };
  const { categories } = useSelector((state) => state.categories);

  //////deleteImage

  const handleDeleteImage = async (img, newImage, deleteImage) => {
    setIsSpinnerDelete(true);

    const formData = new FormData();
    formData.append("img", img);
    formData.append("newImage", newImage);
    formData.append("deleteImage", deleteImage);
    try {
      await axios.put(
        `${server}/product/delete-product-image/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },

          withCredentials: true,
        }
      );

      toast.success(
        <span className="h1ar text-sm">{t("تم حذف الصورة بنجاح!")}</span>
      );

      setIsSpinnerDelete(false);
      dispatch(getProductforShop(id));
      setImageShow(shopProduct.image);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSpinnerDelete(false);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    setIsSpinner(true);

    await axios
      .put(
        `${server}/product/update-shop-product/${id}`,
        {
          name,
          description,
          category,
          originalPrice,
          discountPrice,
          sku,
          stock,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success(
          <span className="h1ar text-sm">
            {t("product updated succesfully!")}
          </span>
        );

        setIsSpinner(false);
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setIsSpinner(false);
      });
  };

  return (
    <>
      {isSPLoading ? (
        <Loader />
      ) : (
        <div className="w-full min-h-screen flex flex-col bg-main-bg dark:bg-main-dark-bg items-center">
          <div className="mb-7 pt-3">
            <nav className="flex absolute   right-2">
              <ol role="list" class="flex items-center">
                <li class="text-left">
                  <div class="-m-1">
                    <Link
                      to="/dashboard"
                      className="rounded-md p-1 text-sm h2ar font-medium text-gray-600 dark:text-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      {" "}
                      {t("Dashboard")}{" "}
                    </Link>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>

                    <div class="-m-1">
                      <Link
                        to="/dashboard-products"
                        class="rounded-md h2ar  p-1 text-sm font-medium text-gray-600 dark:text-gray-400 focus:text-gray-900 focus:shadow hover:text-gray-800"
                      >
                        {" "}
                        {t("All Products")}{" "}
                      </Link>
                    </div>
                  </div>
                </li>

                <li class="text-left">
                  <div class="flex items-center">
                    <span class="mx-2 text-gray-400">/</span>

                    <div class="-m-1">
                      <a
                        href="#"
                        class="rounded-md p-1 h2ar  text-sm font-medium text-gray-600 dark:text-gray-200 dark:border-gray-200 focus:text-gray-900 focus:shadow hover:text-gray-800"
                        aria-current="page"
                      >
                        {" "}
                        {t("Edit Product")}{" "}
                      </a>
                    </div>
                  </div>
                </li>
              </ol>
            </nav>
          </div>

          <div className="w-full px-4 py-2 justify-center flex">
            <div className="flex w-full shadow-lg  bg-white rounded-lg 800px:w-[80%] flex-col justify-center my-5">
              {/*  Images  */}

              {/*  */}

              <div className="w-full px-4 py-5   flex items-center justify-center">
                <div class="flex flex-row">
                  <div className="grid lg:grid-cols-4 grid-cols-2 gap-1 md:grid-cols-3 sm:grid-cols-2 sm:gap-4 md:gap-4 xs:gap-4 gap-3 w-full text-[#d0d2d6] mb-4">
                    {imageShow &&
                      imageShow.length > 0 &&
                      imageShow.map((img, i) => (
                        <div className="relative h-30" key={i}>
                          <label className="aspect-square overflow-hidden rounded-lg">
                            <img
                              className="w-full h-full object-cover rounded-lg"
                              src={`${backend_url}${img}`}
                              alt=""
                              style={{
                                borderColor: currentColor,
                              }}
                            />
                          </label>
                          <input
                            onChange={(e) =>
                              handleImageChange(img, e.target.files)
                            }
                            type="file"
                            id={i}
                            className="hidden"
                          />
                          {isSpinnerDelete ? (
                            <div className="top-1 z-10 absolute right-1">
                              <SmallSpinner fill={"fill-red-500"} />
                            </div>
                          ) : (
                            <span
                              onClick={() => handleDeleteImage(img, null, true)}
                              className="p-1 z-10 cursor-pointer hover:shadow-lg hover text-white absolute top-1 right-1 bg-red-500 rounded-full"
                            >
                              <IoCloseSharp />
                            </span>
                          )}
                          {isSpinnerEdit ? (
                            <div
                              className="bottom-1 p-1 absolute z-10 "
                              style={{ backgroundColor: currentColor }}
                            >
                              <SmallSpinner fill={"fill-gray-400"} />
                            </div>
                          ) : (
                            <label
                              htmlFor={i}
                              className="bottom-1 center justify-center z-10 cursor-pointer hover:shadow-lg hover p-1 text-white absolute rounded-full"
                              style={{ backgroundColor: currentColor }}
                            >
                              <AiOutlineEdit size={25} />
                            </label>
                          )}{" "}
                        </div>
                      ))}

                    {newImage ? (
                      <div
                        className="relative flex justify-center items-center flex-col h-30 cursor-pointer border rounded-lg border-dashed hover:border-indigo-500 mb-1 w-full text-[#d0d2d6] "
                        style={{
                          borderColor: currentColor,
                        }}
                      >
                        <img
                          className="h-full w-full object-contain"
                          src={URL.createObjectURL(newImage)}
                        />

                        {isSpinnerAdd ? (
                          <div className="bottom-2 absolute z-10">
                            <SmallSpinner fill={"fill-green-500"} />
                          </div>
                        ) : (
                          <label
                            className="bottom-2 center justify-center z-10 cursor-pointer hover:shadow-lg bg-green-500 rounded-lg p-2 text-white text-md h1ar absolute rounded-full"
                            htmlFor="handelNewImage"
                          >
                            {t("Save")}
                          </label>
                        )}
                      </div>
                    ) : (
                      <label
                        className="flex justify-center items-center flex-col h-30 cursor-pointer border rounded-lg border-dashed hover:border-indigo-500 mb-1 w-full text-[#d0d2d6]"
                        htmlFor="newImageInput"
                        style={{
                          borderColor: currentColor,
                        }}
                      >
                        <span>
                          <BsImages size={20} color={currentColor} />
                        </span>

                        <span
                          className="par text-md"
                          style={{ color: currentColor }}
                        >
                          {t("Select Image")}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div>
                <button
                  onClick={handleAddNewImage}
                  type="file"
                  id="handelNewImage"
                  className="hidden"
                ></button>

                <input
                  onChange={(e) => handleNewImageChange(e.target.files)}
                  type="file"
                  id="newImageInput"
                  className="hidden"
                />
              </div>

              {/* shop info */}

              <form
                aria-aria-required={true}
                className="flex px-4 flex-col items-center"
                onSubmit={updateHandler}
              >
                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block h1ar pb-2">
                      {t("Product Name")}
                    </label>
                  </div>

                  <input
                    type="name"
                    placeholder={`${shopProduct?.name}`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`${styles.input} !w-[95%] h1ar mb-4 800px:mb-0`}
                    required
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block h1ar pb-2">
                      {t("Product Description")}
                    </label>
                  </div>

                  <textarea
                    type="name"
                    placeholder={`${
                      shopProduct?.description
                        ? shopProduct.description
                        : t("Enter your product description")
                    }`}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={`${styles.input} h1ar !w-[95%] outline-none border mb-4 800px:mb-0`}
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block h1ar pb-2">{t("Category")}</label>
                  </div>

                  <select
                    className="w-full h1ar mt-2 border h-[35px] rounded-[5px]"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    <option value="Choose a category" className="h1ar">
                      {t("Select Categories")}{" "}
                    </option>

                    {categories &&
                      categories.map((i) => (
                        <option value={i.title} className="h2ar" key={i.title}>
                          {currentDir == "ltr" ? i.titleEn : i.titleAr}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="pb-2 h1ar">{t("sku")}</label>
                    <span className="text-red-500">*</span>
                  </div>

                  <input
                    type="number"
                    name="sku"
                    value={sku}
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    onChange={(e) => {
                      setSku(e.target.value);
                    }}
                    placeholder={t("Write a sku of your Product")}
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block pb-2 h1ar">
                      {t("Original Price")}
                    </label>{" "}
                    <span className="text-gray-500 h2ar text-[10px] ">
                      {" "}
                      {t("It will be added")}{" "}
                      <span className="text-red-500 h2ar text-[20px] ">
                        {discountPrice ? discountPrice * 0.2 : " "}
                      </span>{" "}
                      {t("20% added to the price of the product as store fees")}{" "}
                    </span>{" "}
                    <span className="text-gray-500 h2ar text-[10px] ">
                      {t(
                        "In addition to that, there is a shipping fee of 10 SAR for the order"
                      )}{" "}
                    </span>
                  </div>

                  <input
                    type="number"
                    placeholder={shopProduct?.originalPrice}
                    value={originalPrice}
                    onChange={(e) => setOriginalPrice(e.target.value)}
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block pb-2 h1ar">
                      {" "}
                      {t("Price (After Discount)")}
                    </label>{" "}
                    <span className="text-gray-500 h2ar text-[10px] ">
                      {" "}
                      {t("It will be added")}{" "}
                      <span className="text-red-500 h2ar text-[20px] ">
                        {discountPrice ? discountPrice * 0.2 : " "}
                      </span>{" "}
                      {t("20% added to the price of the product as store fees")}{" "}
                    </span>{" "}
                    <span className="text-gray-500 h2ar text-[10px] ">
                      {t(
                        "In addition to that, there is a shipping fee of 10 SAR for the order"
                      )}{" "}
                    </span>
                  </div>

                  <input
                    type="number"
                    placeholder={shopProduct?.discountPrice}
                    value={discountPrice}
                    onChange={(e) => setDiscountPrice(e.target.value)}
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
                  <div className="w-full pl-[3%]">
                    <label className="block h1ar pb-2">{t("Stock")} </label>
                  </div>

                  <input
                    type="number"
                    placeholder={shopProduct?.stock}
                    value={stock}
                    onChange={(e) => setStock(e.target.value)}
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    style={{ borderColor: "#d1d5db" }}
                    onFocus={(e) => (e.target.style.borderColor = currentColor)}
                    onBlur={(e) => (e.target.style.borderColor = "#d1d5db")}
                  />
                </div>

                <div className="pb-2">
                  {isSpinner ? (
                    <Spinner word={t("Update Product")} />
                  ) : (
                    <button
                      type="submit"
                      className="text-white h1ar

 focus:ring-4 font-medium rounded-xl

 text-md px-5 py-2.5 text-center me-2  inline-flex items-center"
                      style={{
                        backgroundColor: currentColor,

                        focusRing: currentColor,
                      }}
                    >
                      {t("Update Product")}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditProductPage;
