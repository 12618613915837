import React from 'react'
import Header from '../components/Layout/Header'
import Footer from '../components/Layout/Footer'
import TrackOrder from "../components/Profile/TrackOrder";
import ThemeSettingsButton from '../components/ThemeSettingsButton';

const TrackOrderPage = () => {
  return (
    <><ThemeSettingsButton/>
      <Header/>
        <TrackOrder />
        <Footer />
    </>
  )
}

export default TrackOrderPage