import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import Loader from "../Layout/Loader";
import { getAllSellers } from "../../redux/actions/sellers";
import { useStateContext } from "../../contexts/ContextProvider";
import { Buttoon } from "../Charts";
import { BsBoxSeam } from "react-icons/bs";
import { CiMoneyBill } from "react-icons/ci";
import { getAllProducts } from "../../redux/actions/product";
import { loadAdmin } from "../../redux/actions/admin";
import { MdBorderClear, MdOutlineLocalOffer } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
const AdminDashboardMain = () => {
  const { user } = useSelector((state) => state.user);
  const { admin } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { currentColor, currentDir } = useStateContext();
  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );
  const { sellers } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(loadAdmin());
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
    dispatch(getAllProducts());
  }, [dispatch]);

  const theme = createTheme({
    direction: currentDir,
  });
  const Balance = admin?.availableBalance.toFixed(2);

  const columns = [
    {
      field: "id",
      headerName: <span className="h1ar text-sm ">{t("Order ID")}</span>,
      minWidth: 150,
      flex: 0.7,
    },

    {
      field: "status",
      headerName: <span className="h1ar text-sm ">{t("Status")}</span>,
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor =
          params.value === "Delivered"
            ? "#1def68"
            : "#ff8842" && params.value === "Processing"
            ? "#ef1d2d"
            : "#ff8842";
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
          marginRight: 6,
          marginLeft: 6,
        };
        return (
          <span>
            <span style={statusStyle}></span>
            <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
          </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: <span className="h1ar text-sm ">{t("Items Qty")}</span>,
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName: <span className="h1ar text-sm ">{t("Total")}</span>,
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: <span className="h1ar text-sm ">{t("Order Date")}</span>,
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
  ];

  const row = [];
  adminOrders &&
    adminOrders.forEach((item) => {
      row.push({
        id: item.orderNumber,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + t("SAR"),
        status: item?.status,
        createdAt: item?.createdAt.slice(0, 10),
      });
    });

  return (
    <>
      {adminOrderLoading ? (
        <Loader />
      ) : (
        <div className="w-full items-center px-8 pt-14">
          <div className="flex flex-wrap lg:flex-nowrap justify-center">
            <div className="bg-white dark:text-gray-600 dark:bg-white  h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div
                  style={{ backgroundColor: currentColor }}
                  className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  {" "}
                  <CiMoneyBill size={30} />{" "}
                </div>

                <br />
                <div>
                  <h3 className={"font-bold h0ar text-gray-400"}>
                    {t("Account Balance")}
                  </h3>
                  <h5 className="text-2xl h1ar">
                    {t("SAR")}
                    {Balance}
                  </h5>
                </div>
              </div>
              {/*<div className=""> </div>*/}
            </div>
            <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{ color: "#03C9D7", backgroundColor: "#E5FAFB" }}
                  className="text-2xl opacity-0.9 rounded-full   p-4 hover:drop-shadow-xl"
                >
                  <MdBorderClear />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {adminOrders && adminOrders.length}
                  </span>

                  <Link to="/admin-orders">
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {t("View Orders")}
                    </span>
                  </Link>
                </p>{" "}
                <p className="text-sm text-gray-400 par mt-1">
                  {t("All Orders")}
                </p>
              </div>

              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{
                    color: "rgb(255, 244, 229)",
                    backgroundColor: "rgb(254, 201, 15)",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <BsBoxSeam />
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">
                    {products && products.length}
                  </span>

                  <Link to="/admin-products">
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {t("View Products")}
                    </span>
                  </Link>
                </p>
                <p className="text-sm text-gray-400 par mt-1">
                  {t("All Products")}
                </p>
              </div>

              <div className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl">
                <button
                  type="button"
                  style={{
                    color: "rgb(228, 106, 118)",
                    backgroundColor: "rgb(255, 244, 229)",
                  }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  <MdOutlineLocalOffer />
                </button>
                <p className="mt-3">
                  <Link to="/admin-sellers">
                    <span className="text-lg font-semibold">
                      {sellers && sellers.length}
                    </span>
                    <span
                      className={`text-sm text-green-600 h2ar ltr:ml-2 rtl:mr-2`}
                    >
                      {"View sellers"}
                    </span>
                  </Link>
                </p>{" "}
                <p className="text-sm text-gray-400 par mt-1">
                  {"All View Sellers"}
                </p>
              </div>
            </div>
          </div>

          <br />
          {/*  <div className="flex flex-wrap lg:flex-nowrap justify-center ">  <ColomChart />
      < Chart className="rounded-2xl "/></div>*/}

          <h3 className="text-[22px] font-bold text-gray-400 pb-2 h0ar">
            {t("Latest Orders")}
          </h3>
          <ThemeProvider theme={theme}>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
              <DataGrid
                rows={row}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                initialState={{
                  ...row.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </ThemeProvider>
        </div>
      )}
    </>
  );
};

export default AdminDashboardMain;
