
import axios from "axios";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import React, { useEffect, useState,useRef } from "react";
import { server } from "../../server";
import { Button } from "@material-ui/core"; import { DataGrid } from "@mui/x-data-grid";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";
import { toast } from "react-toastify";
import {CSVLink} from "react-csv";
import {useReactToPrint} from "react-to-print";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
const AllWithdraw = () => {                   const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus,setWithdrawStatus] = useState('Processing');
  const componentPDF =useRef();
  const {t}= useTranslation()
  const {currentColor,currentDir}=useStateContext();
  useEffect(() => {
    axios
      .get(`${server}/withdraw/get-all-withdraw-request`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.withdraws);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName: (<span className="h1ar text-sm">{t("Withdraw Id")}</span>), minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName:(<span className="h1ar text-sm">{ t("Shop Name")}</span>),
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "shopId",
      headerName: (<span className="h1ar text-sm">{t("Shop Id")}</span>),
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "amount",
      headerName:(<span className="h1ar text-sm">{ t("Amount")}</span>),
      minWidth: 100,
      flex: 0.6,
    },
        {
      field: "SellerAvailableBalance",
      headerName:(<span className="h1ar text-sm">{ t("Seller Available Balance")}</span>),
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "status",
      headerName:(<span className="h1ar text-sm">{ t("status")}</span>),
      type: "text",
      minWidth: 80,
      flex: 0.5,
    },
    {field: " ",
      headerName: (<span className="h1ar text-sm">{t("Update Status")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.6,                                                                  renderCell: (params) => {
    return (
             <Link to={`/admin-withdraw/${params.id}`}>
              <Button className="rounded-full"
              style={{background:currentColor,
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>                  );
      },
    },
  ];

  const handleSubmit = async () => {
    await axios
      .put(`${server}/withdraw/update-withdraw-request/${withdrawData.id}`,{
        sellerId: withdrawData.shopId,
      },{withCredentials: true})
      .then((res) => {
        toast.success("Withdraw request updated successfully!");
        setData(res.data.withdraws);
      });
  };

  const row = [];

data &&
  data.forEach((item) => {
    row.push({
      id: item._id,
      shopId: item.seller._id,
      name: item.seller.name,
      SellerAvailableBalance: item.seller.availableBalance.toFixed(1),
      amount: t("RAS") + item.amount,
      status: item.status,
      createdAt: item.createdAt.slice(0, 10),
    });
  });
    const genratePDF=useReactToPrint({

      content:()=>componentPDF.current,

    documentTitle:"AllWithdraw",
    onAfterPrint:()=>alert("Data saved in PDF")  });
  return (
    <>
        <frameElement className="flex-col w-full items-center px-8 pt-14">
      <div className="flex items-center mx-8 mt-4 ">
      <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
      onClick={genratePDF}
      style={{background:currentColor}}
      >
PDF
      </button>
      <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
       style={{background:currentColor}}
      >
        {data?(
      <CSVLink
      data={data}>
Exel
      </CSVLink>):(null)}
      </div>
      </div>
      <br />
      <ThemeProvider theme={theme}>

<div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,                                                                                                                                                             pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider>


        </frameElement>
    </>
  );
};                                                                                                                                                                               
export default AllWithdraw;
