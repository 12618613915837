import React from 'react'
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader'
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar'
import AllRefundOrders from "../../components/Shop/AllRefundOrders";
import { useStateContext } from '../../contexts/ContextProvider';
import ThemeSettingsButton from '../../components/ThemeSettingsButton';
const ShopAllRefunds = () => {
  const { activeMenu} = useStateContext();
  return (
    <><ThemeSettingsButton/>
    <div className="flex justify-between w-full">
    {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
           <DashboardSideBar  />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <DashboardSideBar  />
        </div>
      )}   <div className={
        activeMenu
          ? 'dark:bg-main-dark-bg items-start bg-main-bg min-h-screen lg:ltr:ml-72 lg:rtl:mr-72 w-full  '
          : 'bg-main-bg dark:bg-main-dark-bg items-start w-full min-h-screen  '
      }
    >
 <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
    <DashboardHeader /></div>
        <div className="w-full justify-center flex">
            <AllRefundOrders />
        </div></div>
      </div>
</>
  )
}

export default ShopAllRefunds