import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { deleteProduct } from "../../redux/actions/product";
import Loader from "../Layout/Loader";
import { useReactToPrint } from "react-to-print";
import { backend_url } from "../../server";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
const AllProducts = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { success, error, deleteSuccess, deleteError } = useSelector(
    (state) => state.products
  );
  const { products, isLoading } = useSelector((state) => state.products);
  const { seller } = useSelector((state) => state.seller);
  const componentPDF = useRef();
  const { currentColor, currentDir } = useStateContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getAllProductsShop(seller._id));
  }, [dispatch]);
  useEffect(() => {
    if (deleteSuccess) {
      window.location.reload();
    }
    if (deleteError) {
      toast(deleteError);
    }
  }, [dispatch, deleteError, deleteSuccess]);
  const handleDelete = async (id) => {
    if (!isDeleting) {
      try {
        setIsDeleting(true); // تعيين حالة الحذف إلى true
        dispatch(deleteProduct(id));
        dispatch(getAllProductsShop(seller._id));
      } catch (error) {
        toast(error);
      } finally {
        setIsDeleting(false); // تعيين حالة الحذف إلى false بعد الانتهاء من الحذف
      }
    }
  };
  const theme = createTheme({
    direction: currentDir,
  });
  const columns = [
    { field: "productNumber", headerName:  (<span className="h1ar text-sm">{t("Product Id")}</span>), minWidth: 150, flex: 0.7 },
    {
      field: "image",
      headerName:  (<span className="h1ar text-sm">{t("Image")}</span>),
      minWidth: 150,
      flex: 0.7,
      renderCell: (params) => {
        return (
          <div
            style={{
              borderRadius: "50%",
              padding: "10px",
              width: "65px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "65px",
            }}
          >
            <img
              src={params.value}
              alt="Product Image"
              style={{
                height: "100%",
                width: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: (<span className="h1ar text-sm">{ t("Name")}</span>),
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName:  (<span className="h1ar text-sm">{t("Price")}</span>),
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName:  (<span className="h1ar text-sm">{t("Stock")}</span>),
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "sku",
      headerName:  (<span className="h1ar text-sm">{t("sku")}</span>),
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: "sold",
      headerName:  (<span className="h1ar text-sm">{t("Sold out")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },

    {
      field: "Preview",
      flex: 1,
      minWidth: 150,
      headerName:  (<span className="h1ar text-sm">{t("Preview")}</span>),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}`}>
              <Button
                className="rounded-full"
                style={{ background: currentColor }}
              >
                <AiOutlineEye style={{ color: "#fff" }} size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },

    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName: (<span className="h1ar text-sm">{ t("Delete")}</span>),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              style={{ background: "#ff0000" }}
              onClick={() => handleDelete(params.id)}
            >
              <AiOutlineDelete style={{ color: "#ffffff" }} size={20} />
            </Button>
          </>
        );
      },
    },
    {
      field: "Edit",
      flex: 0.8,
      minWidth: 120,
      headerName:  (<span className="h1ar text-sm">{t("Edit")}</span>),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {" "}
            <Link to={`/edit-product/${params.id}`}>
              <Button style={{ background: "#ff8842" }}>
                <AiOutlineEdit style={{ color: "#ffffff" }} size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  products &&
    products.forEach((item) => {
      row.push({
        productNumber: item.productNumber,
        name: item.name,
        image: `${backend_url}${item.images && item.images[0]}`,
        price: t("SAR") + item.discountPrice,
        Stock: item.stock,
        sku:item.sku,
        sold: item?.sold_out,
        id:item._id
      });
    });

  const genratePDF = useReactToPrint({
    content: () => componentPDF.current,
    documentTitle: "AllProducts",
    onAfterPrint: () => alert("Data saved in PDF"),
  });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <frameElement className="flex-col w-full items-center px-8 pt-14">
          <div className="flex items-center mx-8 mt-4 ">
            <button
              className=" rounded-md text-gray-100 py-1 px-3 mx-2"
              onClick={genratePDF}
              style={{ background: currentColor }}
            >
              PDF
            </button>
            <div
              className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
              style={{ background: currentColor }}
            >
              {products ? <CSVLink data={products}>Exel</CSVLink> : null}
            </div>
          </div>
          <br />
          <ThemeProvider theme={theme}>
            <div
              ref={componentPDF}
              className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl"
            >
              <DataGrid
                rows={row}
                columns={columns}
                disableSelectionOnClick
                autoHeight
                initialState={{
                  ...row.initialState,
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          </ThemeProvider>
        </frameElement>
      )}
    </>
  );
};

export default AllProducts;
