import React, { useEffect } from 'react'
import ShopCreate from "../components/Shop/ShopCreate"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ThemeSettingsButton from '../components/ThemeSettingsButton';
function ShopCreatePage() {
  const navigate = useNavigate();
  const { isSeller,seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if(isSeller === true){
      navigate(`/shop/${seller._id}`);
    }
  }, [])
  return (
    <><ThemeSettingsButton/>
    <ShopCreate/></>
  )
}

export default ShopCreatePage