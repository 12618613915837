import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader";
import { server } from "../../server";
import { toast } from "react-toastify";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import Spinner from "../Charts/Spinner"
const AllCoupons = () => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [coupouns,setCoupouns] = useState([]);
  const [minAmount, setMinAmout] = useState(null);
  const [maxAmount, setMaxAmount] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [value, setValue] = useState(null);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const {currentColor,currentDir}=useStateContext();
  const{t}=useTranslation()
  const dispatch = useDispatch();
const [spinner ,setSpinner]=useState(false)
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${server}/coupon/get-coupon/${seller._id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setIsLoading(false);
        setCoupouns(res.data.couponCodes);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [dispatch]);
 
  const theme = createTheme({
    direction:currentDir
    });
  const handleDelete = async (id) => {
    axios.delete(`${server}/coupon/delete-coupon/${id}`,{withCredentials: true}).then((res) => {
      toast.success("Coupon code deleted succesfully!")
    })
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
setSpinner(true)
    await axios
      .post(
        `${server}/coupon/create-coupon-code`,
        {
          name,
          minAmount,
          maxAmount,
          selectedProducts,
          value,
          shopId: seller._id,
        },
        { withCredentials: true }
      )
      .then((res) => {
       toast.success("Coupon code created successfully!");
       setOpen(false);
       window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
setSpinner(false)
      });
  };

  const columns = [
    { field: "id", headerName: t("Id"), minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName:t("Coupon Code"),
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: t("Value"),
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Delete",
      flex: 0.8,
      minWidth: 120,
      headerName:t("Delete"),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
                 <Button style={{background:"#ff0000",
        }} onClick={() => handleDelete(params.id)}>
              <AiOutlineDelete style={{color:"#ffffff"}} size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];

  coupouns &&
  coupouns.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: item.value + " %",
        sold: 10,
      });
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className="flex-col items-center mx-8 mt-4 ">
            <div
              className={`w-[150px] h1ar h-[40px] my-3 flex items-center justify-center rounded-xl cursor-pointer px-3 mr-3 mb-3`}
              style={{background:currentColor}}
              onClick={() => setOpen(true)}
            >
              <span className="text-white">{t("Create Coupon")}</span>
            </div>
       
          <ThemeProvider theme={theme}>

             <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider>
          {open && (
            <div className="fixed top-0 left-0 w-full h-screen bg-[#00000062] z-[20000] flex items-center justify-center">
              <div className="w-[90%] 800px:w-[40%] h-[80vh] bg-white rounded-md shadow p-4">
                <div className="w-full flex justify-end">
                  <RxCross1
                    size={30}
                    className="cursor-pointer"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <h5 className="text-[30px] font-Poppins text-center">
                  {t("Create Coupon code")}
                </h5>
                {/* create coupoun code */}
                <form onSubmit={handleSubmit} aria-required={true}>
                  <br />
                  <div>
                    <label className="ltr:placeholder:after:first-letter: pb-2 h1ar">
                      {t("Name")} <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      required
                      value={name}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("Enter your coupon code name...")}
                    />
                  </div>
                  <br />
                  <div>
                    <label className="pb-2 h1ar">
                      {t("Discount Percentenge")}{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="value"
                      value={value}
                      required
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => setValue(e.target.value)}
                      placeholder={t("Enter your coupon code value...")}
                    />
                  </div>
                  <br />
                  <div>
                    <label className="pb-2 h1ar">{t("Min Amount")}</label>
                    <input
                      type="number"
                      name="value"
                      value={minAmount}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => setMinAmout(e.target.value)}
                      placeholder={t("Enter your coupon code min amount...")}
                    />
                  </div>
                  <br />
                  <div>
                    <label className="pb-2 h1ar">{t("Max Amount")}</label>
                    <input
                      type="number"
                      name="value"
                      value={maxAmount}
                      className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      onChange={(e) => setMaxAmount(e.target.value)}
                      placeholder={t("Enter your coupon code max amount...")}
                    />
                  </div>
                  <br />
                  <div>
                {  /*  <label className="pb-2 h1ar">{t("Selected Product")}</label>
                    <select
                      className="w-full mt-2 border h-[35px] rounded-[5px]"
                      value={selectedProducts}
                      onChange={(e) => setSelectedProducts(e.target.value)}
                    >
                      <option value="Choose your selected products">
                        {t("Choose a selected product")}
                      </option>
                      {products &&
                        products.map((i) => (
                          <option value={i.name} key={i.name}>
                            {i.name}
                          </option>
                        ))}
                    </select>*/}
                  </div>
                  <br />
                  <div>
                     {spinner ===true ?(<Spinner word={t("Create")}/>
              ):(<button
                type="submit"
                className="text-white h1ar  rounded-xl text-md px-5 py-2.5 text-center me-2  inline-flex items-center"
style={{backgroundColor:currentColor,
  focusRing:currentColor
}}
              >
{t("Create")}
              </button>)}
                  </div>
                </form>
              </div>
            </div>
          )}
        </div></frameElement>
      )}
    </>
  );
};

export default AllCoupons;
