import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { useEffect } from "react";


import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import ThemeSettingsButton from "../ThemeSettingsButton";
const Payment = () => {
  const [orderData, setOrderData] = useState([]);
  const [open, setOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const{t}=useTranslation();
  useEffect(() => {
    const orderData = JSON.parse(localStorage.getItem("latestOrder"));
    setOrderData(orderData);
  }, []);
console.log(orderData)
  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Sunflower",
            amount: {
              currency_code: "SAR",
              value: orderData?.totalPrice,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        return orderID;
      });
  };

  const order = {
    cart: orderData?.cart,
    shippingAddress: orderData?.shippingAddress,
    user: user && user,
    totalPrice: orderData?.totalPrice,
    paymentId:orderData?.payment_id,
  };



//frontend
const paymentId=(orderData?.payment_id)


console.log(paymentId)
  
const paymentHandler = async (e) => {
  e.preventDefault();
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
       },
    };

    

    const { data } = await axios.get(
      `${server}/payment/payment/${paymentId}`,
      config
    );
console.log(data)



      if (data.client_secret.status === "CAPTURED") {
        order.paymentInfo = {
          id:paymentId,
          status: "succeeded",
          type: "Tap",
        };
        await axios
        .post(`${server}/order/create-order`, order, config)
        .then((res) => {
          setOpen(false);
          navigate("/order/success");
          toast.success("Order successful!");
          localStorage.setItem("cartItems", JSON.stringify([]));
          localStorage.setItem("latestOrder", JSON.stringify([]));
          window.location.reload();
        });
      }
       else 
       {if (data.error) {
      toast.error(data.error.message);
    } 
    }
  } catch (error) {
    toast.error(error.message);
  }
};



  return (<>
    <ThemeSettingsButton/>
    <div className="w-full flex flex-col items-center py-8">
      <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
        <div className="w-full 800px:w-[65%]">
          <PaymentInfo
            user={user}
            open={open}
            setOpen={setOpen}
           createOrder={createOrder}
            paymentHandler={paymentHandler}
          />
        </div>
      </div>
    </div></>
  );
};

const PaymentInfo = ({
  user,
  open,
  setOpen,
  createOrder,
  paymentHandler,
}) => {
  const{t}=useTranslation();
  return (
    <div className="w-full 800px:w-[95%] bg-[#fff] p-5 pb-8 rounded-2xl shadow-lg ">
      {/* select buttons */}
      <div>
 
   
  <div className="flex-col justify-between">
  <p className="my-3 font-bold text-gray-400" id="msg">
  {t("Please click the button below to verify the completion of the payment process")}
  </p>
  <button 
  className={`${styles.buttoon} text-white h[45px] rounded-[5px] cursor-pointer text-[18px] font-[600] `}
  
  onClick={paymentHandler}>{t("chick")}</button>
</div></div>
     

   
    </div>
  );
};




export default Payment;