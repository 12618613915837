import React, { useState } from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import styles from "../styles/styles";
import ThemeSettingsButton from "../components/ThemeSettingsButton";
import { useTranslation } from "react-i18next";

const FAQPage = () => {
  return ( <>
  <ThemeSettingsButton/>
    <div className="h-[60vh] bg-main-bg dark:bg-main-dark-bg">

      <Header activeHeading={5} />

      <Faq />
   
      </div>
      <Footer className="bottom-0" />
      </>
  );
};

const Faq = () => {
  const [activeTab, setActiveTab] = useState(0);
const{t}=useTranslation()
  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };

  return (
    
    <div className={`${styles.section} dark:bg-main-dark-bg  my-8`}>
      <h2 className="text-3xl h0ar dark:text-gray-200 font-bold text-gray-900 mb-8">{t("FAQ")}</h2>
      <div className="mx-auto space-y-4">
        {/* single Faq */}

     

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(3)}
          >
            <span className="text-lg font-medium h1ar dark:text-gray-300 text-gray-900">
              {t("How do I track my order?")}
            </span>
            {activeTab === 3 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 3 && (
            <div className="mt-4">
              <p className="text-base h2ar dark:text-gray-400 text-gray-500">
             {t("You can track your order by clicking the tracking link in your")}
            {" "}  {t("shipping confirmation email, or by logging into your account on")}
               {" "} {t("our website and viewing the order details.")}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(4)}
          >
            <span className="text-lg h1ar dark:text-gray-300 font-medium text-gray-900">
              {t("How do I contact customer support?")}
            </span>
            {activeTab === 4 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 4 && (
            <div className="mt-4">
              <p className="text-base h2ar dark:text-gray-400 text-gray-500">
               {t("You can contact our customer support team by emailing us at")}
                {t("info@revoonestor@gmail.com, or by calling us at +966538004171")}
     
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(5)}
          >
            <span className="text-lg h1ar dark:text-gray-300 font-medium text-gray-900">
              {t("Can I change or cancel my order?")}
            </span>
            {activeTab === 5 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 5 && (
            <div className="mt-4">
              <p className="text-base h2ar dark:text-gray-400 text-gray-500">
               {t("Unfortunately, once an order has been placed, we are not able to make changes or cancellations. If you no longer want the items you have  ordered, you can return them for a refund within 7 days of delivery.")}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center h1ar dark:text-gray-300 justify-between w-full"
            onClick={() => toggleTab(6)}
          >
            <span className="text-lg h1ar dark:text-gray-300 font-medium text-gray-900">
             {t("Do you offer international shipping?")}
            </span> 
            {activeTab === 6 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 6 && (
            <div className="mt-4">
              <p className="text-base h2ar dark:text-gray-400 text-gray-500">
                {t("Currently, we only offer shipping within the Saudi Arabia.")}
              </p>
            </div>
          )}
        </div>

        <div className="border-b border-gray-200 pb-4">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => toggleTab(7)}
          >
            <span className="text-lg h1ar dark:text-gray-300 font-medium text-gray-900">
              {t("What payment methods do you accept?")}
            </span>
            {activeTab === 7 ? (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            )}
          </button>
          {activeTab === 7 && (
            <div className="mt-4">
              <p className="text-base h2ar dark:text-gray-400 text-gray-500">
                {t("We accept visa,mastercard,mada ,tap payment  method also we have cash on delivery system.")}
              </p>
            </div>
          )}
        </div>
        </div>
      </div>

  );
};

export default FAQPage;
