import React from "react";
import TrackerOrder from "../../components/Profile/TrackerOrder";
import Header from "../../components/Layout/Header";

function TrackerOrderPage() {
  return (
    <>
      <Header />

      <div className={` flex h-screen bg-main-bg dark:bg-main-dark-bg py-10`}>
        <TrackerOrder />
      </div>
    </>
  );
}

export default TrackerOrderPage;
