import axios from "axios";
import { server } from "../../server";

// create product
export const createCategories = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "CategoriesCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/categories/create-categorie`,
      newForm,
      config
    );
    dispatch({
      type: "CategoreisCreateSuccess",
      payload: data.categorie,
    });
  } catch (error) {
    dispatch({
      type: "CategoreisCreateFail",
      payload: error.response.data.message,
    });
  }
};
// delete
export const deleteCategorei = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteCategoreiRequest",
    });
    console.log("id", id);
    const { data } = await axios.delete(
      `${server}/categories/delete-categorie/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteCategoreiSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteCategoreiFailed",
      payload: error.response.data.message,
    });
  }
};
export const getCategorie = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getCategorieRequest",
    });
    console.log("id", id);
    const { data } = await axios.get(
      `${server}/categories/get-categorie/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getCategorieSuccess",
      payload: data.categorie,
    });
  } catch (error) {
    dispatch({
      type: "getCategorieFailed",
      payload: error.response.data.message,
    });
  }
};
// get all cat
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllCategoriesRequest",
    });

    const { data } = await axios.get(`${server}/categories/get-Allcategorie`);
    dispatch({
      type: "getAllCategoriesSuccess",
      payload: data.categories,
    });
  } catch (error) {
    dispatch({
      type: "getAllCategoriesFailed",
      payload: error.response.data.message,
    });
  }
};
export const CategorieCreateClear = () => async (dispatch) => {
  dispatch({
    type: "categorieCreateCleare",
  });
};
