import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  isLoadingType:true,
};

export const orderReducer = createReducer(initialState, {
  // get all orders of user
  getAllOrdersUserRequest: (state) => {
    state.isLoading = true;
  },
  getAllOrdersUserSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllOrdersUserFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  // get all orders of shop
  getAllOrdersShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllOrdersShopSuccess: (state, action) => {
    state.isLoading = false;
    state.orders = action.payload;
  },
  getAllOrdersShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
    // get  orders Types of shop
  getOrdersTypesShopRequest: (state) => {
    state.isLoadingType = true;
  },
  getOrdersTypesShopSuccess: (state, action) => {
  state.isLoadingType = false;
  state.DeliveredOrders = action.payload.DeliveredOrders;
  state.ProcessingOrders = action.payload.ProcessingOrders;
  state.OtherOrders = action.payload.OtherOrders;
  state.DeliveredPercentage = action.payload.DeliveredPercentage;
  state.ProcessingPercentage = action.payload.ProcessingPercentage;
  state.OtherPercentage = action.payload.OtherPercentage;
},
  getOrdersTypesShopFailed: (state, action) => {
    state.isLoadingType = false;
    state.error = action.payload;
  },
  //get order
getOrderOfShopRequest: (state) => {
    state.isLoading = true;
  },
  getOrderOfShopSuccess: (state, action) => {
    state.isLoading = false;
    state.order = action.payload;
  },
  getOrderOfShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // get all orders for admin
  adminAllOrdersRequest: (state) => {
    state.adminOrderLoading = true;
  },
  adminAllOrdersSuccess: (state, action) => {
    state.adminOrderLoading = false;
    state.adminOrders = action.payload;
  },
  adminAllOrdersFailed: (state, action) => {
    state.adminOrderLoading = false;
    state.error = action.payload;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});

