const styles = {
  custom_container: "w-11/12 hidden sm:block",
  heading: 'text-[27px] text-center dark:text-gray-200 h0ar font-[600] font-Roboto pb-[20px]',
  section:'w-11/12 mx-auto ',
  productTitle: 'text-[25px] font-[600] h0ar font-Roboto dark:text-gray-200 text-[#333]',
productDiscountPrice:"font-bold text-md text-[#333] h2ar 800px:text-lg  dark:text-gray-200 font-Roboto",
  price:" h2ar text-sm text-[#d55b45] ltr:pl-2 rtl:pr-2 800px:text-md mt-[-4px] line-through",  
 shop_name:"pt-3 h1ar text-[15px] text-blue-400 pb-3",
  active_indicator:"absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]",
  button: 'w-[150px] h1ar  h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer',
buttoonExel: 'text-white h1ar bg-gradient-to-r from-green-600 via-green-650 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-xl text-md px-5 py-2.5 text-center me-2 mb-2',
 buttoon: 'text-white h1ar bg-gradient-to-r from-pink-600 via-pink-700 to-[#b91a5c] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-xl text-sm px-5 py-2.5 text-center me-2 mb-2',
  cart_button: "px-2 lg:px-[20px] h-[38px] rounded-[20px] bg-[#b91a5c] flex items-center justify-center cursor-pointer",
  cart_button_text:"text-[#fff]  text-[16px]  h0ar font-[600] ",
  input:"w-full border p-1 rounded-[5px]",
  activeStatus:"w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]",
  noramlFlex:"flex items-center",
Spinner:"text-white h1ar my-3  focus:ring-4 font-medium rounded-xl text-md px-5 py-2.5 text-center me-2  inline-flex items-center",  
main_button_color:"bg-gradient-to-br from-pink-600 to-purple-700",
};

export default styles;
