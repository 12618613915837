import axios from "axios";
import { server } from "../../server";


// get all orders of seller
export const getdailySalesOfShop = (shopId) => async (dispatch) => {
  try {
    dispatch({
      type: "getdailySalesShopRequest",
    });

    const { data } = await axios.get(
      `${server}/dailySales/get-dailySales/${shopId}`
    );

    dispatch({
      type: "getdailySalesShopSuccess",
      payload: data.dailySales,
    });
  } catch (error) {
    dispatch({
      type: "getdailySalesShopFailed",
      payload: error.response.data.message,
    });
    
  }
};


// get all dailySales
export const AlldailySales= () => async (dispatch) => {
  try {
    dispatch({
      type: "AlldailySalesRequest",
    });

    const { data } = await axios.get(`${server}/dailySales/get-dailySale`);

    dispatch({
      type: "AlldailySalesSuccess",
      payload: data.dailySales,
    });
  } catch (error) {
    dispatch({
      type: "AlldailySalesFailed",
      payload: error.response.data.message,
    });
  }
};

