import axios from "axios";
import { server } from "../../server";
export const getWithdrawDetails = (withdrawId) => async (dispatch) => {
  try {
    dispatch({
      type: "getWithdrawDetailsRequest",
    });

    const { data } = await axios.get(
      `${server}/withdraw/get-withdraw-request/${withdrawId}`
    );

    dispatch({
      type: "getWithdrawDetailsSuccess",
      payload: data.withdraw,
    });
  } catch (error) {
    dispatch({
      type: "getWithdrawDetailsFailed",
      payload: error.response.data.message,
    });
  }
};
