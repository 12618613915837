import React from "react";

import { GrWorkshop } from "react-icons/gr";

import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHandbag } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";

import styles from "../../../styles/styles";

import {
  AiOutlineFolderAdd,
  AiOutlineGift,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { MdOutlineCancel, MdOutlineLocalOffer } from "react-icons/md";
import { RxDashboard } from "react-icons/rx";
import { Link } from "react-router-dom";
import { VscNewFile } from "react-icons/vsc";
import { BiMessageSquareDetail } from "react-icons/bi";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import logo from "../../../Assests/images/Revo One-svg.svg";
import { useStateContext } from "../../../contexts/ContextProvider.js";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { FaRegImage } from "react-icons/fa";

const links = [
  {
    title: "Ecommerce",
    links: [
      {
        name: "Dashboard",
        icon: <FiShoppingBag />,
        linkSide: "admin/dashboard",
      },
    ],
  },

  {
    title: "orders",
    links: [
      {
        name: "All Orders",
        icon: <AiOutlineShoppingCart />,
        linkSide: "admin-orders",
      },
    ],
  },
  {
    title: "Sellers",
    links: [
      {
        name: "All Sellers",
        icon: <GrWorkshop />,
        linkSide: "admin-sellers",
      },
    ],
  },
  {
    title: "Users",
    links: [
      {
        name: "All Users",
        icon: <HiOutlineUserGroup />,
        linkSide: "admin-users",
      },
    ],
  },
  {
    title: "Products",
    links: [
      {
        name: "All Products",
        icon: <BsHandbag />,
        linkSide: "admin-products",
      },
    ],
  },
  {
    title: "Events",

    links: [
      {
        name: "All Events",
        icon: <MdOutlineLocalOffer />,
        linkSide: "admin-events",
      },
    ],
  },

  {
    title: "Other",
    links: [
      {
        name: "Withdraw Money",
        icon: <CiMoneyBill />,
        linkSide: "admin-withdraw-request",
      },

      {
        name: "Active Shop",
        icon: <CiMoneyBill />,
        linkSide: "admin-Seller-active",
      },
      {
        name: "Setting",
        icon: <CiSettings />,
        linkSide: "profile",
      },
      {
        name: "Hero Image",
        icon: <FaRegImage />,
        linkSide: "admin-hero-image",
      },
      {
        name: "All Categoties",
        icon: <FaRegImage />,
        linkSide: "CategoryPage",
      },
      {
        name: "Create Categotie",
        icon: <FaRegImage />,
        linkSide: "createCategorie",
      },
      {
        name: "Create Hero Image",
        icon: <FaRegImage />,
        linkSide: "createHeroImage",
      },
      {
        name: "Shiping",
        icon: <CiMoneyBill />,
        linkSide: "AdminShipingPage",
      },
    ],
  },
];

const AdminSideBar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();

  const { t } = useTranslation();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="sidebar ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-20">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} alt="" /> <span>Revoone</span>
            </Link>
            <div content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </div>
          </div>
          <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {t(item.title)}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.linkSide}`}
                    key={link.name}
                    onClick={handleCloseSideBar}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? currentColor : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link.icon}
                    <span className="h1ar">{t(link.name)}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminSideBar;
