import React ,{useRef ,useEffect} from "react";
import {bestSelling} from "../../../redux/actions/product";
import {useState} from "react"

import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
const BestSillngSlider = () => {
  const scrollContainerRef = useRef(null);
  
  const {t}=useTranslation();
 const [pageNumber,setPageNumber]=useState(0)
const [pageSize,setPageSize]=useState(30)
  const {bestSellingProducts,isLoading,totalPage} = useSelector((state) => state.products);
  
const dispatch = useDispatch();
  useEffect(() => {
   dispatch(bestSelling( pageSize,pageNumber));
  }, [pageSize,pageNumber]);

  
const handleScrollLeft = () => {
    scrollContainerRef.current.scrollLeft -= 200; // تغيير قيمة السكرول لليسار
  };

  const handleScrollRight = () => {
    scrollContainerRef.current.scrollLeft += 200; // تغيير قيمة السكرول لليمين
  };

  return (
    <div>

      <div className="flex dark:bg-main-dark-bg flex-col bg-main-bg m-auto p-auto">
<h1 className="flex py-5 text-center font-bold text-xl h1ar 800px:text-4xl px-3 text-gray-800 dark:text-gray-400"
      >
        {t("Best Silling")}
      </h1>
      <div
        className="flex overflow-x-scroll pb-5 hide-scroll-bar"
        ref={scrollContainerRef}
      >
        <div
          className="flex flex-nowrap lg:ml-40 md:ml-10  ml-10 items-center "
        >
                  {bestSellingProducts &&
            bestSellingProducts.map((i, index) => {
              return (
          <div className="inline-block  px-1">
           <ProductCard data={i} key={index} />
          </div>
              );
            })}

                  {/* زر اليسار */}

     {window.innerWidth >= 800 && (
   <button className="absolute  left-4 transform -translate-y-1/2  --btn-primary rounded-full  p-2" onClick={handleScrollLeft}>
        <IoIosArrowBack />
      </button>)}
    {window.innerWidth >= 800 && (
      <button className="absolute  right-4 transform -translate-y-[50%] --btn-primary  rounded-full p-2" onClick={handleScrollRight}>
  <IoIosArrowForward />
      </button>)}
            </div>
            </div>
      </div>
    </div>
  );
};

export default BestSillngSlider;
