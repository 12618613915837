import React from 'react'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { uploadActiveData } from "../../redux/actions/activeData";
import {server} from "../../server"
import { toast } from "react-toastify";
import { loadSeller } from "../../redux/actions/user";
import { useTranslation } from 'react-i18next';
import Spinner from "../Charts/Spinner"
import { useStateContext } from "../../contexts/ContextProvider";
const ActiveData = () => {
const {currentColor}=useStateContext();
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.activedata);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
const {t}=useTranslation();
        const [personalId, setPersonalId] = useState("");
        const [file, setFile] = useState("");
        const [allImage, setAllImage] = useState(null);
        const [pdfFile, setPdfFile] = useState(null);
     const [create,setCreate]=useState(false)
useEffect(() => {

    if (success) {

      toast.success("your active data created successfully!");

      navigate("/dashboard");

      window.location.reload();

    }

    toast.error(error);
     setCreate(false)
  }, [dispatch, error, success]);





        const submitImage = async (e) => {

            e.preventDefault();
setCreate(true)
            const newForm = new FormData();

            newForm.append("shopId", seller._id);

            newForm.append("personalId", personalId);

            newForm.append("file", file);

            dispatch(uploadActiveData(newForm));

          }       
        
      
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <Link to="/dashboard"><h4 className="my-4 text-center text-xl font-extrabold text-[#b91a5c] h2ar">{t("Return to the Dashboard")}</h4></Link>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 h0ar">
           {t("Shop Active Data")}
        </h2>
      </div>
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form className="space-y-6" onSubmit={submitImage}>
  <div>
              <label
                htmlFor="personalId"
                className="block text-sm font-medium text-gray-700 text-right par"
              >
          {t("Personal Id Number")}
              </label>
              <div className="mt-1">
                <input
                  type="number"
                  name="personalId"
                  required
                  value={personalId}  
                  onChange={(e) => setPersonalId(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                />
              </div>
            </div>

    <br />
   
    <br />
    <input
      type="file"
      class="form-control"
      accept="application/pdf"
      required
      onChange={(e) => setFile(e.target.files[0])}
      className="appearance-none block text-bold text-center w-full px-3 py-2 border border-dashed border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
    />
    <br />
    {create ===true ?(<Spinner word={t("Send Data")}/>
              ):(<button
                type="submit"
 className="text-white h1ar  rounded-xl text-md px-5 py-2.5 text-center me-2  inline-flex items-center"
style={{backgroundColor:currentColor,
  focusRing:currentColor
}}
              >
{t("Send Data")}
              </button>)} </form>
         </div>
       </div></div>
  )
}

export default ActiveData
