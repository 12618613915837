import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";
import { useTranslation } from "react-i18next";
import Loader from "../../Layout/Loader";
import {featuredproducts} from "../../../redux/actions/product";
const FeaturedProduct = () => {
  const [pageNumber,setPageNumber]=useState(0)
const [pageSize,setPageSize]=useState(10)
  const {featuredProducts,isLoading,totalPages} = useSelector((state) => state.products);
const dispatch = useDispatch();
  
  useEffect(() => {
  dispatch(featuredproducts(pageSize, pageNumber));
}, [pageSize, pageNumber]);
const handlePageChange = ({selected}) => {
    setPageNumber(selected);
  };
   const{t}=useTranslation()
  return (
     <>     
    {isLoading ? (
      <Loader />
    ) : (
    <div>
      <div className={`${styles.section}`}>
        <div className={`${styles.heading}`}>
          <h1>Featured Products</h1>
        </div>
       <div className="grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
          {featuredProducts && featuredProducts.map((i, index) => <ProductCard data={i} key={index} />)}
        </div>
        {featuredProducts && featuredProducts.length === 0 ? (
          <h1 className="text-center w-full pb-[100px] text-[20px]">
            No products Found!
          </h1>
        ) : null}
      
      </div>
    </div>)}</>
  );
};

export default FeaturedProduct;
