import React from "react";
import { useSelector } from "react-redux";
import EventCard from "../components/Events/EventCard";
import Header from "../components/Layout/Header";
import BottomNav from "../components/Layout/BottomNav"
import Loader from "../components/Layout/Loader";
import ThemeSettingsButton from "../components/ThemeSettingsButton";
import { useTranslation } from "react-i18next";
import styles from "../styles/styles";
const EventsPage = () => {
  const { allEvents, isLoading } = useSelector((state) => state.events);
  const {t}=useTranslation()
  return (
  
     <>
     {
       isLoading ? (
         <Loader />
       ) : (
         <div className=" bg-main-bg dark:bg-main-dark-bg">
         <Header activeHeading={4} />
         <br />
         <br />
         <div className={`${styles.section}`}>
           <div className="grid grid-cols-1 gap-[20px] mb-12">
             {allEvents && allEvents.map((i, index) => <EventCard data={i} key={index} />)}
           </div>
           {allEvents && allEvents.length === 0 ? (
             <h1 className="text-center text-red-600 w-full pb-[100px] h0ar text-[20px]">
               {t("No Events Found!")}
             </h1>
           ) : null}
         </div>
       
       </div>
       )
     }
<BottomNav  mobilactiveHeading={3} />
     </>
  );
};

export default EventsPage;
