import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BsCartCheckFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addTocart, removeFromCart } from "../../redux/actions/cart";
import { backend_url } from "../../server";
import { Buttoon } from "../Charts";
import { AiOutlineDelete, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import { MdArrowBack, MdOutlineCancel } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import styles from "../../styles/styles";

function CartDetails() {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };
  return (
    <div className="w-full p-10 items-center ">
      <div className=" w-full rounded-lg shadow-md  dark:text-gray-200  dark:bg-[#484B52] bg-white flex flex-col overflow-y-auto  ">
        {/* ... الكود هنا */}
        {cart && cart.length === 0 ? (
          <div className="w-full  h-screen flex-col p-2  justify-center">
            <div className="flex w-full justify-end left-2  top-10">
              <Buttoon
                icon={<MdOutlineCancel />}
                color="rgb(153, 171, 180)"
                bgHoverColor="light-gray"
                size="2xl"
                borderRadius="50%"
              />
            </div>
            <h5 className="justify-center px-5 h1ar text-xl center py-12">
              {t("Cart Items is empty!")}
            </h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">

                  {" "}
                  <Link to="/">
                  <h5 className="text-[18px] text-mypink h1ar items-center gap-1 flex font-[400]">
                    {t("back to home")} <MdArrowBack className="ltr:transform ltr:scale-x-[-1] "/>
                  </h5>
</Link>
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4  justify-between`}>
                <div className={`${styles.noramlFlex}  `}>
                  <IoBagHandleOutline size={25} />
                  <h5 className="pl-2 text-[20px] h1ar font-[500]">
                    {cart && cart.length} {t("items")}
                  </h5>
                </div>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full px-2 border-t">
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 w-full lg:w-[20%] mb-3">
              {/* checkout buttons */}

              <div className="mt-3 mb-3">
                <div className="flex justify-between items-center mt-3">
                  <p className="text-gray-500 dark:text-gray-200 par">
                    {t("Total")}
                  </p>
                  <p className="font-semibold h1ar">
                    {t("SAR")} {totalPrice}
                  </p>
                </div>
              </div>
              <div className="mt-5 pb-14 w-20">
                <Link to="/checkout">
                  <Buttoon
                    color="white"
                    bgColor="#000"
                    text={t("checkout")}
                    borderRadius="10px"
                    width="full"
                  />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const CartSingle = ({ data, quantityChangeHandler, removeFromCartHandler }) => {
  const [value, setValue] = useState(data?.qty);
  const [colors, setColors] = useState(data?.selectedColors);
  const [sizes, setSizes] = useState(data.selectedSizes);
  const totalPrice = data.discountPrice * value;
  const { t } = useTranslation();
  const increment = (data) => {
    if (data.stock - 1 < value) {
      toast.error(
        <span className="h1ar text-sm">{t("Product stock limited!")}</span>
      );
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);

    if (colors != undefined && colors?.length > 0 && colors?.length >= value) {
      const updatedColors = colors.slice(0, -1); // حذف آخر عنصر من القائمة
      setColors(updatedColors);
    }
    if (sizes != undefined && sizes?.lengt > 0 && sizes?.length >= value) {
      const updatedSizes = sizes.slice(0, -1); // حذف آخر عنصر من القائمة
      setSizes(updatedSizes);
    }
    const updateCartData = {
      ...data,
      qty: value === 1 ? 1 : value - 1,
      selectedColors: colors.slice(0, -1),
      selectedSizes: sizes.slice(0, -1),
    };
    quantityChangeHandler(updateCartData);
  };

  return (
    <div className="px-1  border-b-1 border-color dark:border-gray-600 ">
      <div className="flex items-center  p-4">
        <div
          className=" shadow-lg shadow bg-gray-100/2 mx-4 rounded-full"
          onClick={() => removeFromCartHandler(data)}
        >
          <Buttoon
            icon={<AiOutlineDelete />}
            color="red"
            bgHoverColor=""
            size="md"
            borderRadius="50%"
          />
        </div>
        <img
          className="rounded-lg border-1 h-[150px] w-[150px]"
          src={`${backend_url}${data?.images[0]}`}
          alt=""
        />
        <div className=" w-full justify-between flex">
          <div className="justify-start">
            {" "}
            <p className="text-center par font-semibold center my-4 mx-2 ">
              {data.name.length > 20
                ? data.name.slice(0, 20) + "..."
                : data.name}
            </p>
            <div className="flex gap-4 mt-2 items-center">
              <p className="font-semibold text-lg">{data.price}</p>
              <div className="flex items-center border-1  border-color rounded">
                <p
                  className="p-2 ltr:border-r-1 rtl:border-l-1 dark:border-gray-600 border-color text-red-500
  rounded-l cursor-pointer"
                  onClick={() => decrement(data)}
                >
                  <HiOutlineMinus size={18} />
                </p>
                <p className="p-2">{value}</p>
                <p
                  className="p-2 ltr:border-l-1 rtl:border-r-1 dark:border-gray-600 border-color text-green-500
  rounded-r cursor-pointer"
                  onClick={() => increment(data)}
                >
                  <HiPlus size={18} />
                </p>
              </div>
            </div>
          </div>
          <div className=" flex-col">
            {" "}
            <p className="text-gray-600 dark:text-gray-400 h2ar mx-2 text-sm font-semibold">
              {t("SAR")}
              {data.discountPrice} * {value}
            </p>{" "}
            <h5 className="text-gray-900 mt-2 dark:text-gray-2 h2ar mx-2 text-lg font-semibold">
              {t("SAR")}
              {data.discountPrice * value}
            </h5>
          </div>
        </div>
      </div>
      <div className="mt-1 grid grid-cols-3 gap-1 md:grid-cols-3 md:gap-[5px] lg:grid-cols-4 800px:gap-2 lg:gap-2 xl:grid-cols-4 xl:gap-4 mb-1">
        {colors?.map((color, index) => (
          <div
            key={index}
            className="items-center justify-center align-center p-x2 flex-col relative"
          >
            {/** colorssssssss     ----------------------------------------------- */}

            <div
              className="px-0.5 items-center border border-dashed  py-1 rounded-lg "
              style={{
                overflow: "hidden",
                height: "fit-content",

                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <h1 className="h2ar text-sm text-gray-600 dark:text-gray-200 text-center">
                {color && color}
              </h1>
            </div>
          </div>
        ))}
      </div>
      <h1 className="h1ar text-sm rtl:text-right ltr:text-left text-gray-900 dark:text-gray-200 ">
        {t("Sizes")}
      </h1>
      <div className="mt-1 grid grid-cols-3 gap-1 md:grid-cols-3 md:gap-[5px] lg:grid-cols-4 800px:gap-2 lg:gap-2 xl:grid-cols-4 xl:gap-4 mb-1">
        {sizes?.map((size, index) => (
          <div
            key={index}
            className="items-center justify-center align-center p-x2 flex-col relative"
          >
            {/**  sizes   ----------------------------------------------- */}
            <div
              className="px-0.5 items-center border border-dashed  py-1 rounded-lg "
              style={{
                overflow: "hidden",
                height: "fit-content",

                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <h1 className="h2ar text-sm text-gray-600 dark:text-gray-200 text-center">
                {size}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CartItems = ({ cart, quantityChangeHandler, removeFromCartHandler }) => {
  const { t } = useTranslation();
  return (
    <div>
      {cart.map((data) => (
        <CartSingle
          data={data}
          key={data._id}
          quantityChangeHandler={quantityChangeHandler}
          removeFromCartHandler={removeFromCartHandler}
        />
      ))}
    </div>
  );
};

const CartSummary = ({ totalPrice }) => {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-2 border-t-1 border-color">
      <p className="text-xl font-semibold text-gray-600  dark:text-gray-400">
        {t("Total")}: {totalPrice}
      </p>
      <Link to="/checkout" className="block h1ar mt-4">
        <Buttoon>{t("Checkout")}</Buttoon>
      </Link>
    </div>
  );
};

export default CartDetails;
