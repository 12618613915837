   
   
   
   import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import { backend_url } from "../../server";
import styles from "../../styles/styles";

import {
  AiOutlineArrowRight,
  AiOutlineHeart,
  AiOutlineSearch,
} from "react-icons/ai";
 const SearchBar= ()  =>{
   const [searchData, setSearchData] = useState(null);
   const [searchTerm, setSearchTerm] = useState("");

    const { allProducts } = useSelector((state) => state.products);
 const { t} = useTranslation();
   const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };
   return(
 <>
            {window.innerWidth < 800 && (
                    <div className="my-2 w-[92%] h1ar m-auto h-[40px relative]">
                <input
                  type="search"
                  placeholder={t("Search Product...")                  }
                  className="h-[40px] w-full px-2 h1ar border-[#b91a5c] border-[2px] rounded-md"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />


                  {searchData && searchData.length !== 0 && searchTerm!==" " ? (
              <div className={`${searchTerm!=="" ?" absolute bg-[#fff] z-10 shadow w-full left-0 p-3":"hidden" }`}>
                {searchData &&
                  searchData.map((i, index) => {
                    return (
                      <Link to={`/product/${i._id}`}>
                        <div className="w-full flex items-start-py-3">
                          <img
                            src={`${backend_url}${i.images[0]}`}
                            alt=""
                            className="w-[40px] h-[40px] mr-[10px]"
                          />
                          <h1>{i.name}</h1>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : null}
              </div>)}
              </>
              )
              }
export default SearchBar
