import axios from "axios";
import React, { useRef, useState,useEffect } from "react";
import { MdOutlineCancel } from 'react-icons/md';
import { backend_url, server } from "../server";
import { Buttoon } from './Charts';
import { chatData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import socketIO from "socket.io-client";
import { format } from "timeago.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ENDPOINT = "http://localhost:4000/";
const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });
const Chat = () => {
  const { seller } = useSelector((state) => state.seller);
  const [conversations, setConversations] = useState([]);
  const { currentColor } = useStateContext();
  const [activeStatus, setActiveStatus] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
const{t}=useTranslation()


  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== seller?._id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };



  useEffect(() => {
    const getConversation = async () => {
      try {
        const resonse = await axios.get(
          `${server}/conversation/get-all-conversation-seller/${seller?._id}`,
          {
            withCredentials: true,
          }
        );

        setConversations(resonse.data.conversations);
      } catch (error) {
        // console.log(error);
      }
    };
    getConversation();
  }, [seller, messages]);


  useEffect(() => {
    if (seller) {
      const sellerId = seller?._id;
      socketId.emit("addUser", sellerId);
      socketId.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [seller]);



  return (
    <div className="nav-item absolute ltr:right-5 rtl:left-5 ltr:md:right-52 rtl:md:left-52 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200 h1ar">{t("All Messages")}</p>
          <button type="button" className="text-white  text-xs rounded  p-1 px-2 bg-orange">
        
          </button>
        </div>
        <Buttoon
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5 ">
      {!open && (
        <>
       
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={seller._id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
              />
            ))}
        </>
      )}
     
        <div className="mt-5 h1ar">
    
        <Link to="/dashboard-messages" >
          <Buttoon
            color="white"
            bgColor={currentColor}
            text={t("See all messages")}
            borderRadius="10px"
            width="full"
            className="h1ar"
          /></Link>
        </div>
      </div>
    </div>
  );
};
const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  online,
  setActiveStatus
}) => {
  console.log(data);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/dashboard-messages?${id}`);
    setOpen(true);
  };
  const [active, setActive] = useState(0);

  useEffect(() => {
    setActiveStatus(online);
    const userId = data.members.find((user) => user != me);

    const getUser = async () => {
      try {
        const res = await axios.get(`${server}/user/user-info/${userId}`);
        setUser(res.data.user);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  return (
    <div
    className="flex items-center gap-5 border-b-1 border-color p-3 leading-8 cursor-pointer"
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
    >
      <div className="relative">
        <img
          src={`${backend_url}${user?.avatar}`}
          alt=""
          className="rounded-full h-10 w-10"
        />
        {online ? (
          <div className="w-[12px] h-[12px] bg-green-400 rounded-full absolute top-[2px] right-[2px]" />
        ) : (
          <div className="w-[12px] h-[12px] bg-[#c7b9b9] rounded-full absolute top-[2px] right-[2px]" />
        )}
      </div>
      <div className="ltr:pl-1 rtl:pr-1">
        <p className="font-semibold dark:text-gray-200 h1ar">{user?.name}</p>
        <p className="text-gray-500 dark:text-gray-400 par text-sm">
  {data?.lastMessageId !== user?._id
    ? "send:"
    : user?.name?.split(" ")[0] + ": "}
 <span className="text-gray-500 h2ar dark:text-gray-400 text-xs">{data?.lastMessage}</span> 
</p>
      </div>
    </div>
  );
};
export default Chat;

