import React, { useState } from "react";
import styles from "../../styles/styles";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getCShiping, getEShiping } from "../../redux/actions/shipings";
import Loader from "../Layout/Loader";
const Checkout = () => {
  const { user } = useSelector((state) => state.user);
  const { cart } = useSelector((state) => state.cart);
  const [country, setCountry] = useState("المملكة العربية السعودية");
  const [city, setCity] = useState("");
  const [userInfo, setUserInfo] = useState(false);
  const [nearestTeacher, setNearestTeacher] = useState("");
  const [street, setStreet] = useState("");
  const [address1, setAddress1] = useState("");
  const [building, setBuilding] = useState("");

  const dispatch = useDispatch();
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [paymentType, setPaymentType] = useState(""); // حالة القيمة المحددة
  const [addressType, setAddressType] = useState("");
  const { Cshiping, Eshiping, isLoadingCs, isLoadingEs } = useSelector(
    (state) => state.shipings
  );
  const [shipping, setShipping] = useState(Eshiping?.shipingAmount);
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getEShiping());
    dispatch(getCShiping());
  }, []);
  
  const paymentSubmit = async () => {
    if (
      city === "" ||
      building === "" ||
      zipCode === null ||
      street === "" ||
      nearestTeacher === ""
    ) {
      toast.warning(
        <span className="par">
          {t("Please add your all shipping information!")}
        </span>
      );
    } else {
      const totalPrice = pretotalPrice.toFixed(2);
      const shippingAddress = {
        street,
        building,
        addressType,
        nearestTeacher,
        zipCode,
        country,
        city,
      };

      const amount = parseFloat(totalPrice);

      const paymentData = {
        amount: amount,
        name: user.name,
        email: user.email,
      };

      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const { data } = await axios.post(
          `${server}/payment/process`,
          paymentData,
          config
        );

        //console.log(data.client_secret.transaction.url)

        if (data.client_secret.status === "INITIATED") {
          const payment_id = data.client_secret.id;
          const orderData = {
            cart,
            totalPrice,
            subTotalPrice,
            shipping,
            discountPrice,
            shippingAddress,
            user,
            payment_id,
          };
          localStorage.setItem("latestOrder", JSON.stringify(orderData));

          window.location.replace(data.client_secret.transaction.url);
        } else {
          if (data.error) {
            toast.error(data.error.message);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }

    // update local storage with the updated orders array
  };
  const handlePaymentTypeChange = (e) => {
    const value = e.target.value;
    setPaymentType(value); // تحديث حالة القيمة المحددة
    if (value == "Card") {
      setShipping(Eshiping?.shipingAmount);
    }
    if (value == "Cash") {
      setShipping(Cshiping?.shipingAmount);
    }
  };
  const complatePayment = () => {
   
    if (paymentType === "Card") {
      paymentSubmit();
    } else if (paymentType === "Cash") {
      CashSubmit();
    } else {
      toast.warning(
        <span className="par">{t("Please chose payment Type")}</span>
      );
    }
  };
  const CashSubmit = async () => {
    
    if (
      city === "" ||
      building === "" ||
      zipCode === null ||
      street === "" ||
      nearestTeacher === ""
    ) {
      toast.warning(
        <span className="par">
          {t("Please add your all shipping information!")}
        </span>
      );
    } else {
      const shippingAddress = {
        street,
        building,
        addressType,
        nearestTeacher,
        zipCode,
        country,
        city,
      };

      const totalPrice = parseFloat(pretotalPrice);
      const orderData = {
        cart,
        totalPrice,
        subTotalPrice,
        shipping,
        discountPrice,
        shippingAddress,
        user,
      };
      const order = {
        cart: orderData?.cart,
        shippingAddress: orderData?.shippingAddress,
        user: user && user,
        totalPrice: orderData?.totalPrice,
      };

      // update local storage with the updated orders array
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      order.paymentInfo = {
        type: "Cash On Delivery",
      };

      await axios
        .post(`${server}/order/create-order`, order, config)
        .then((res) => {
          navigate("/order/success");
          toast.success(
            <span className="par">
              {t("The order was created successfully")}
            </span>
          );
          localStorage.setItem("cartItems", JSON.stringify([]));
          localStorage.setItem("latestOrder", JSON.stringify([]));
          window.location.reload();
        });
    }
  };

  const subTotalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  // this is shipping cost variable

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = couponCode;

    await axios.get(`${server}/coupon/get-coupon-value/${name}`).then((res) => {
      const shopId = res.data.couponCode?.shopId;
      const couponCodeValue = res.data.couponCode?.value;
      if (res.data.couponCode !== null) {
        const isCouponValid =
          cart && cart.filter((item) => item.shopId === shopId);

        if (isCouponValid.length === 0) {
          toast.error("Coupon code is not valid for this shop");
          setCouponCode("");
        } else {
          const eligiblePrice = isCouponValid.reduce(
            (acc, item) => acc + item.qty * item.discountPrice,
            0
          );
          const discountPrice = (eligiblePrice * couponCodeValue) / 100;
          setDiscountPrice(discountPrice);
          setCouponCodeData(res.data.couponCode);
          setCouponCode("");
        }
      }
      if (res.data.couponCode === null) {
        toast.error("Coupon code doesn't exists!");
        setCouponCode("");
      }
    });
  };

  const discountPercentenge = couponCodeData ? discountPrice : "";

  const pretotalPrice = couponCodeData
    ? (subTotalPrice + shipping - discountPercentenge)
    : (subTotalPrice + shipping)

  console.log(discountPercentenge);

  return (
    <>
      {isLoadingCs || isLoadingEs ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-col items-center py-8">
          <div className="w-[90%] 1000px:w-[70%] block 800px:flex">
            <div className="w-full 800px:w-[65%]">
              <div>
                <h4 className="block text-[18px] pb-4 rtl:h0ar  text-mypink">
                  <strong>{t("payment type")}</strong>
                </h4>
                <div className="flex gap-3">
                  {" "}
                  <label className="justify-between items-center">
                    <input
                      type="radio"
                      value="Cash"
                      checked={paymentType === "Cash"} // تحقق إذا كانت القيمة المحددة هي "Cash"
                      onChange={handlePaymentTypeChange}
                    />
                    <strong className="text-balck m-1 h1ar">
                      {t("Cash on Delivery")}
                    </strong>
                  </label>
                  <br />
                  <label className="justify-between items-center ">
                    <input
                      type="radio"
                      value="Card"
                      checked={paymentType === "Card"} // تحقق إذا كانت القيمة المحددة هي "Card"
                      onChange={handlePaymentTypeChange}
                    />
                    <strong className="text-balck h1ar">
                      {" "}
                      {t("Credit card or electronic payment")}
                    </strong>
                  </label>
                </div>
              </div>
              <ShippingInfo
                user={user}
                country={country}
                setCountry={setCountry}
                city={city}
                addressType={addressType}
                setAddressType={setAddressType}
                setCity={setCity}
                nearestTeacher={nearestTeacher}
                userInfo={userInfo}
                setNearestTeacher={setNearestTeacher}
                setUserInfo={setUserInfo}
                street={street}
                setStreet={setStreet}
                building={building}
                setBuilding={setBuilding}
                zipCode={zipCode}
                setZipCode={setZipCode}
              />
            </div>

            <div className="w-full 800px:w-[35%] 800px:mt-0 mt-8">
              <CartData
                handleSubmit={handleSubmit}
                pretotalPrice={pretotalPrice}
                shipping={shipping}
                subTotalPrice={subTotalPrice}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                paymentType={paymentType}
                discountPercentenge={discountPercentenge}
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-2">
            <button
              className={`${styles.buttoon}  px-4 py-3  mt-10`}
              onClick={complatePayment}
            >
              <h5 className="text-white h1ar">
                <strong>{t("Complete Payment")}</strong>
              </h5>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const ShippingInfo = ({
  user,
  country,
  setCountry,
  city,
  setCity,
  street,
  setStreet,
  building,
  setBuilding,
  nearestTeacher,
  setAddressType,
  setNearestTeacher,
  userInfo,
  setUserInfo,
  zipCode,
  addressType,
  setZipCode,
}) => {
  const { t } = useTranslation();
  const handleAdressTypeChange = (e) => {
    const value = e.target.value;
    setAddressType(value);
  };
  const { isLoadingCs, isLoadingEs } = useSelector((state) => state.shipings);
  return (
    <>
      {isLoadingCs || isLoadingEs ? (
        <Loader />
      ) : (
        <div className="w-full 800px:w-[95%] bg-white rounded-md p-5 pb-8">
          <h5 className="text-[18px] rtl:h1ar font-[500]">
            {t("Shipping Address")}
          </h5>
          <br />
          <form>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("Full Name")}</label>
                <input
                  type="text"
                  value={user && user.name}
                  required
                  className={`${styles.input}  par`}
                />
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("Email Address")}</label>
                <input
                  type="email"
                  value={user && user.email}
                  required
                  className={`${styles.input} par`}
                />
              </div>
            </div>

            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("Phone Number")}</label>
                <input
                  type="number"
                  required
                  value={user && user.phoneNumber}
                  className={`${styles.input}   ltr:placeholder par `}
                />
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("Zip Code")}</label>
                <input
                  type="number"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                  className={`${styles.input} par`}
                />
              </div>
            </div>

            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block pb-2 h1ar ">{t("Country")}</label>
                <div className="border py-1 items-center h-[40px] rounded-[5px]">
                  <span className="par">{country}</span>
                </div>
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("City")}</label>
                <input
                  type="text"
                  placeholder={t("Example: Riyadh, Mecca, Jeddah, Al-Ahsa")}
                  required
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  className={`${styles.input}   ltr:placeholder par `}
                />
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">{t("Street Name")}</label>
                <input
                  type="text"
                  placeholder={t("Example: Al Orouba Street")}
                  required
                  onChange={(e) => setStreet(e.target.value)}
                  value={street}
                  className={`${styles.input}   ltr:placeholder par `}
                />
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h1ar pb-2">
                  {t("Building name/number")}
                </label>
                <input
                  type="text"
                  placeholder={t("Building name or number")}
                  required
                  onChange={(e) => setBuilding(e.target.value)}
                  value={building}
                  className={`${styles.input}   ltr:placeholder par `}
                />
              </div>
            </div>
            <div className="w-full flex pb-3">
              <div className="w-full">
                <label className="block h2ar pb-2">
                  <span className="h1ar">{t("Nearest place")}</span>
                </label>
                <input
                  type="text"
                  value={nearestTeacher}
                  placeholder={t("Example: Galleria Mall")}
                  required
                  onChange={(e) => setNearestTeacher(e.target.value)}
                  className={`${styles.input}   ltr:placeholder par `}
                />
              </div>
            </div>

            <div className="w-full flex-col ">
              <label className="block rtl:h1ar pb-2">
                {t("Add delivery instructions (optional)")}
              </label>
              <div>
                <label className="justify-between items-center m-2">
                  <input
                    type="radio"
                    value="House"
                    checked={addressType === "House"} // تحقق إذا كانت القيمة المحددة هي "Cash"
                    onChange={(e) => setAddressType(e.target.value)}
                  />
                  <strong className="text-balck h1ar">
                    {t("Home (7am-9pm, all days)")}
                  </strong>
                </label>
              </div>
              <div>
                <label className="justify-between items-center m-2">
                  <input
                    type="radio"
                    value="Office"
                    checked={addressType === "Office"} // تحقق إذا كانت القيمة المحددة هي "Cash"
                    onChange={(e) => setAddressType(e.target.value)}
                  />
                  <strong className="text-balck h1ar">
                    {t("Office (delivery from Sunday to Thursday)")}
                  </strong>
                </label>
              </div>
              <div>
                <label className="justify-between items-center m-2">
                  <input
                    type="radio"
                    value="Main"
                    checked={addressType === "Main"} // تحقق إذا كانت القيمة المحددة هي "Cash"
                    onChange={(e) => setAddressType(e.target.value)}
                  />
                  <strong className="text-balck h1ar">
                    {t("Use as my main title")}
                  </strong>
                </label>
              </div>
            </div>
            <div></div>
          </form>
          <h5
            className="text-[18px] rtl:h0ar cursor-pointer text-[#b91a5c] inline-block mt-5 "
            onClick={() => setUserInfo(!userInfo)}
          >
            <strong>{t("Choose From saved address")}</strong>
          </h5>
          {userInfo && (
            <div>
              {user &&
                user.addresses.map((item, index) => (
                  <div className="w-full flex mt-1">
                    <input
                      type="checkbox"
                      className="mr-3"
                      value={item.addressType}
                      onClick={() =>
                        setCity(item.city) ||
                        setBuilding(item.building) ||
                        setNearestTeacher(item.nearestTeacher) ||
                        setZipCode(item.zipCode) ||
                        setStreet(item.street) ||
                        setAddressType(item.addressType)
                      }
                    />
                    <h2 className="h2ar">{t(item.addressType)}</h2>
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const CartData = ({
  handleSubmit,
  pretotalPrice,
  shipping,
  paymentType,
  subTotalPrice,
  couponCode,
  setCouponCode,
  discountPercentenge,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-[#fff] rounded-md p-5 pb-8">
      <div className="flex justify-between">
        <h3 className="text-[16px] font-[400] text-[#000000a4] rtl:h1ar">
          {t("subtotal")}:
        </h3>
        <h5 className="text-[18px] font-[600] rtl:h1ar">
          {t("SAR")}
          {subTotalPrice}
        </h5>
      </div>
      <br />
      <div className="flex justify-between">
      <div className="flex flex-col">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">
          {t("shipping")}:
        </h3>{paymentType ==="Cash" &&
        <span className="par text-xs text-mypink ">{t("17 Riyals have been added to the shipping costs, cash on delivery delivery fees")}</span>}
        </div>
        <h5 className="text-[18px] font-[600]">
          {t("SAR")} {shipping}
        </h5>
      </div>
      <br />
      <div className="flex justify-between border-b pb-3">
        <h3 className="text-[16px] font-[400] text-[#000000a4]">
          {t("Discount")}:
        </h3>
        <h5 className="text-[18px] font-[600]">
          -{" "}
          {discountPercentenge
            ? t("SAR") + discountPercentenge.toString()
            : null}
        </h5>
      </div>
      <h5 className="text-[18px] font-[600] text-end pt-3 rtl:h2ar">
        {t("SAR")}
        {pretotalPrice}
      </h5>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          className={`${styles.input} h-[40px] pl-2`}
          placeholder={t("Coupoun code")}
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          required
        />
        <input
          className={`w-full h-[40px] border border-[#f63b60] text-center text-[#f63b60] rounded-[3px] mt-8 cursor-pointer`}
          required
          value="Apply code"
          type="submit"
        />
      </form>
    </div>
  );
};

export default Checkout;