import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect ,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../Layout/Loader";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { AiOutlineEye } from "react-icons/ai";
import {useReactToPrint} from "react-to-print";
import {CSVLink} from "react-csv";
import { useStateContext } from "../../contexts/ContextProvider";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const AllOrders = () => {
  const componentPDF =useRef();
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
const{t}=useTranslation()
const {currentColor,currentDir}=useStateContext();
  const dispatch = useDispatch();
 

  
  const theme = createTheme({
    direction:currentDir
    });
    
  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: (<span className="h1ar text-sm">{ t("Order ID")}</span>), minWidth: 150, flex: 0.7 },

    {
      field: "status",
      headerName: (<span className="h1ar text-sm">{ t("Status")}</span>),
      minWidth: 130,
      flex: 0.7,
      renderCell: (params) => {
        const statusColor = params.value === "Delivered" ? "#1def68" : "#ff8842" && params.value === "Processing"?"#ef1d2d":"#ff8842" ;
        const statusStyle = {
          display: "inline-block",
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: statusColor,
          marginRight: "5px",
          fontWeight: "bold",
          marginRight:6,
          marginLeft:6
        };
        return (
          <span>
          <span style={statusStyle}></span>
          <span style={{ fontWeight: "bold" }}>{t(params.value)}</span>
        </span>
        );
      },
    },
    {
      field: "itemsQty",
      headerName: (<span className="h1ar text-sm">{t("Items Qty")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: "total",
      headerName:  (<span className="h1ar text-sm">{t("Total")}</span>),
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },


    {
      field: "Preview",
      headerName: (<span className="h1ar text-sm">{ t("Preview")}</span>),
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/order/${params.id}`}>
              <Button className="rounded-full" 
              style={{background:currentColor,
        
                
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item.orderNumber,
        itemsQty: item.cart.length,
        total: t("RAS") + item.totalPrice,
        status: item.status,
      });
    });

const genratePDF=useReactToPrint({
  content:()=>componentPDF.current,
documentTitle:"AllOrders",
onAfterPrint:()=>alert("Data saved in PDF")

});

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className="flex items-center mx-8 mt-4 ">
        <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
        onClick={genratePDF}
        style={{background:currentColor}}
        > 
PDF
        </button>
        <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
         style={{background:currentColor}}
        >{orders?(
        <CSVLink
        data={orders}
       
        >
Exel
        </CSVLink>):(null)}
        </div>
        </div>
        <br /> <ThemeProvider theme={theme}>

        <div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
          <DataGrid
            rows={row}
            columns={columns}
            disableSelectionOnClick
            autoHeight
            initialState={{
              ...row.initialState,
              pagination: { paginationModel: { pageSize:10 } },
            }}
            pageSizeOptions={[10, 20, 50]}
             />
        
        </div></ThemeProvider></frameElement>
      )}
    </>
  );
};

export default AllOrders;
