import React from 'react'
import ActiveData from '../../components/Shop/ActiveData'
import ThemeSettingsButton from '../../components/ThemeSettingsButton'

function ActiveDataPage() {
  return (<><ThemeSettingsButton/>
    <div><ActiveData/></div></>
  )
}

export default ActiveDataPage