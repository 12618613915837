import React from "react";
import Header from "../../components/Layout/Header";
import ChangePassword from "../../components/Profile/ChangePassword";

function ChangePasswordPage() {
  return (
    <>
      <Header />

      <div className={` flex h-screen bg-main-bg dark:bg-main-dark-bg py-10`}>
        <ChangePassword />
      </div>
    </>
  );
}

export default ChangePasswordPage;
