import React from 'react'
import { Link } from 'react-router-dom'
import { mobilnavItems } from '../../static/data'
import styles from '../../styles/styles'
import { useTranslation } from 'react-i18next';
import { FiShoppingCart } from "react-icons/fi";
import { useSelector } from "react-redux";

import { useStateContext } from'../../contexts/ContextProvider';
import Cart from "../cart/Cart"
import { backend_url } from "../../server";
const BottomNav = ({mobilactiveHeading}) => {
  const { cart } = useSelector((state) => state.cart);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const {t}=useTranslation();
  const { handleClick, isClicked } = useStateContext();
   
  return (
<><div className="800px:hidden">
<div className="fixed 800px:hidden  bottom-0 left-0 z-50 w-full h-16 bg-white/90 border-t border-gray-200 backdrop-blur-sm dark:bg-black/90 dark:border-gray-600">
    <div className="grid h-full max-w-lg grid-cols-5 gap-1 mx-auto font-medium">
             {
            mobilnavItems && mobilnavItems.map((i,index) => (
            
<Link to={i.url}>
        <button type="button" className={`${mobilactiveHeading === index + 1 ? "text-[#b91a5c] dark:text-white  h1ar":" par dark:text-gray-400   text-gray-600    group"} inline-flex flex-col h-full w-full px-2 items-center justify-center group`}>{index + 1 ===4 && isAuthenticated ?(<div className="w-[23px] top-0 h-[23px]"><img
                        src={`${backend_url}${user?.avatar}`}
                        alt=""
                        className="w-[23px] h-[23px] rounded-full border-[0.5px] border-[#0eae88]"
                      /></div>):(
           <span className="text-xl ">{i.icon}</span>)}
            <span className="text-sm">{t(i.title)}</span>
        </button>
        </Link>))}
                <button type="button" className={`${isClicked.cart ? "text-[#b91a5c]  dark:text-white h1ar":"par dark:text-gray-400  text-gray-600   group"} inline-flex flex-col h-full w-full px-2 items-center justify-center group`}
                onClick={()=>handleClick('cart')}
                >
           <span className="text-xl ">
                       <span className=" absolute  ltr:right-5 rtl:left-10 top-1 rounded-full bg-[#b91a5c] w-4 h-4 top right p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                  {" "}
                  {cart && cart?.length}
                           </span>
           <FiShoppingCart />


           </span>
            <span className="text-sm">{t("Cart")}</span>
        </button>
    </div>
</div>
           {window.innerWidth < 800 && isClicked.cart && <Cart className="  sidebar" />}
</div>
</>
  )
}

export default BottomNav
