
import React, { useEffect, useState } from 'react';
import axios from "axios";
import styles from "../styles/styles";
import ThemeSettingsButton from "../components/ThemeSettingsButton";
import { FaMoneyBill } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backend_url, server } from "../server";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawDetails } from "../redux/actions/withdraw";
import { useStateContext } from "../contexts/ContextProvider";
import Spinner from "../components/Charts/Spinner"
const WithdrawDetailsPage = () => {
  const [adminMessage,setAdminMessage]=useState("")
  const [transectionNumber,setTransectionNumber]=useState("")
  const [isClickedButton,setIsClickedButton]=useState(false)
  const [data,setData]=useState([])
  const { withdraw ,isLoading} = useSelector((state) => state.withdraw);
  const {currentColor}=useStateContext();
  const navigate = useNavigate();
  const [withdrawStatus,setWithdrawStatus] = useState('Processing');
  const [status, setStatus] = useState("");
const{t}=useTranslation()
  const { id } = useParams();
const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWithdrawDetails(id));
  }, [dispatch]);

 const handleSubmit = async () => {
  setIsClickedButton(true);
  try {
    await axios.put(
      `${server}/withdraw/update-withdraw-request/${withdraw._id}`,
      { AdminMessage:adminMessage,
        sellerId: withdraw.seller._id ,
        TransectionId:transectionNumber,
      },
      { withCredentials: true }
    );
    toast.success(<span className="h1ar text-sm ">{t("Withdraw request updated successfully!")}</span>);
    dispatch(getWithdrawDetails(id));
    setIsClickedButton(false);
  } catch (error) {
    setIsClickedButton(false);
    toast.error(<span className="h1ar text-sm ">{t("An error occurred. Please try again later.")}</span>);
  }
};
  return (<>{isLoading ?(<h1>loding..</h1>
    ):(<div className={`py-4 min-h-screen ${styles.section}`}>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center">
          <FaMoneyBill  size={25} color="crimson" />
          <h1 className=" ltr:pl-2 rtl:pr-2 h0ar text-xl">{t("Withdraw Details")}</h1>
        </div>
        <Link to="/admin-withdraw-request">
          <div
            className={`${styles.button} text-[#fff] font-[600] !h-[45px] text-[18px]`}
            style={{background:currentColor}}
          >
           {t("Withdraw List")}
          </div>
        </Link>
      </div>

      <div className="w-full flex-wrap  items-center 800px:justify-between pt-6">
        <h5 className="text-sm par text-gray-800 ">
          {t("Withdraw ID")}: {" "}<span>{withdraw._id}</span>
        </h5>
        <br />
        <h5 className="text-sm par text-gray-800 ">
          {t("Placed on")}: <span>{withdraw?.createdAt?.slice(0, 10)}</span>
        </h5>
      </div>

      {/* order items */}
      <br />
      <br />

          <div className="w-full flex items-start mb-5">
         <img
              src={`https://api.revoonestor.com/${withdraw.seller.avatar}`}
              alt=""
              className="w-[80x] h-[80px]"
            /><div className="w-full">
              <h5 className="ltr:pl-3 rtl:pr-3 text-[20px]">{withdraw?.seller?.name}</h5>

            </div>
          </div>


      <div className="border-t w-full ">
        <h5 className="pt-3 text-xl h1ar  ">
          {t("Shop Available Balance")}: <span className="text-md h2ar  ">{withdraw.seller.availableBalance.toFixed(0)}{" "}{t("SAR")}</span>
        </h5>
      </div>
      <br />
      <br />
      <div className="w-full 800px:flex items-center">
        <div className="w-full 800px:w-[60%]">
          <h4 className="pt-3  text-2xl h1ar "
          style={{
            color:currentColor
          }}>{t("Withdraw Infrmation")}:</h4>
          <h4 className="pt-3 h1ar text-xl">
        {t("Bank Name")}:{" "}<span className="text-md h2ar text-gray-700  ">{withdraw?.seller.withdrawMethod.bankName}
         </span> </h4>
          <h4 className="pt-3 h1ar text-xl">
           {t("Bank City")}:{" "}<span className="text-md h2ar  ">{withdraw.seller.withdrawMethod.bankCountry}</span></h4>
          <h4 className="pt-3 h1ar text-xl">
         {t("Bank Account Number")}:{" "}<span className="text-md h2ar  ">{withdraw.seller.withdrawMethod.bankAccountNumber}</span></h4>
          <h4 className="pt-3 h1ar text-xl">
         {t("Bank Holder Name")}:{" "}<span className="text-md h2ar  ">{withdraw?.seller.withdrawMethod.bankHolderName}</span></h4>
          <h4 className="pt-3 h1ar text-xl">
         {t("Bank Address")}:{" "}<span className="text-md h2ar  ">{withdraw?.seller.withdrawMethod.bankAddress}</span></h4>
        </div>
        <div className="w-full 800px:w-[40%]">
          <h4 className="pt-3 h1ar text-xl">
            {t("withdraw Amount")}:{" "}
          <span className="text-md h2ar  ">  {withdraw?.amount}
         </span> </h4>
        </div>
      </div>
      <br />
                <h4 className="pt-3 800px:mx-2 mb-2 800px:mb-0   text-xl h1ar "
          style={{
            color:currentColor
          }}>{t("withdraw Transection Number")}:</h4> <input
                        type="number"
                        placeholder={t("Transection Number...")}
                        value={transectionNumber}
                        onChange={(e) => setTransectionNumber(e.target.value)}
                        className="800px:w-[100px] h2ar w-[full] border 800px:mr-3 p-1 rounded"
            style={{ borderColor: '#d1d5db' }}
  onFocus={(e) => e.target.style.borderColor = currentColor}
onBlur={(e) => e.target.style.borderColor = '#d1d5db'}          />
      <br />
       <h4 className="pt-3 800px:mx-2 mb-2 800px:mb-0   text-xl h1ar "
          style={{
            color:currentColor
          }}>{t("ملاحظة")}:</h4>
<textarea
  type="number"
  placeholder={t(" إكتب ملاحظتك هنا ...")}
  value={adminMessage}
  onChange={(e) => setAdminMessage(e.target.value)}
  className="p-2.5 w-full text-sm h2ar text-gray-900 bg-gray-50 rounded-lg outline-none border"
  style={{ borderColor: '#d1d5db' }}
  onFocus={(e) => e.target.style.borderColor = currentColor}
onBlur={(e) => e.target.style.borderColor = '#d1d5db'}
/>
      <br/>
          <h4 className="pt-3  text-xl h1ar "
          style={{
            color:currentColor
          }}>{t("Update Withdraw Status")}:</h4>
        <select
        name=""
        id=""
        onChange={(e) => setWithdrawStatus(e.target.value)}
          className="w-[200px] mt-2 border h-[35px] h1ar rounded-[5px]"
        ><br />
        {
        withdraw.status === "succeed" ?(null):(
        <option className="h1ar" value={withdrawStatus}>{t(
        withdraw.status)}</option>)}
        <option className="h1ar" value={withdrawStatus}>{t("Succeed")}</option>
        </select>
        {isClickedButton?(<div className="mx-3 my-2"><Spinner word={t("Update")}/></div>):(
              <button
        type="submit"
        className={`h1ar my-2 text-white flex  focus:ring-4 font-medium rounded-lg text-md  px-5 py-2.5 text-center mx-3  inline-flex items-center`}
        style={{background:currentColor}}
        onClick={handleSubmit}
      >
        {t("Update Status")}
      </button>)}
    </div>
)}</>
  );
};

export default WithdrawDetailsPage;
