
import { useTranslation } from 'react-i18next';
import React from "react";
import{ FaTiktok} from "react-icons/fa"
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,

} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,


} from "../../static/data";
import logo from "../../static/Revo One white.svg"
import footerBaymont from "../../static/footer-payment.svg";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#000115] text-white">
      <div className="md:flex md:justify-between md:items-center sm:px-12 px-4 bg-gradient-to-r from-[#5d0c8a] to-[#b91a5c] py-2">
        <h1 className="lg:text-2xl h1ar text-xl md:mb-0 mb-6 lg:leading-normal font-semibold md:w-2/5">
          <span className="text-[#00045a] h1ar">{t("Subscribe")}</span> {t("us for get news events and offers")}
        </h1>
        <div>
          <input
            type="text"
            required
            placeholder={t("Enter your email...")}
            className="footerl
                sm:w-72 w-full h2ar sm:mr-5 mr-1 lg:mb-0 mb-4 py-1.5 rounded px-2 focus:outline-none"
          />
          <button className="footerl footerlr duration-300 px-5 py-1.5 rounded-md text-whie md:w-auto w-full">
            {t("Submit")}
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:gird-cols-3 lg:grid-cols-4 gap-y-2 gap-x-6 sm:px-8 px-5 sm:text-center">
        <ul className="px-5 text-center mt-2 sm:text-start flex sm:block flex-col items-center">
          <img
            src={logo}
            alt=""
            style={{ filter: "brightness(0) invert(1)" }}
          />
          <br />
          <p className='h2ar'> {t("Your perfect destination to find all the amazing products you need.")} </p>
          <div className="flex items-center mt-[15px]">
        
            <a href="https://www.tiktok.com/@r_revo_one">
            <FaTiktok
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></a>
            <Link to="https://instagram.com/r_revo_one?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr">
            <AiFillInstagram
              size={25}
              style={{ marginLeft: "15px", cursor: "pointer" }}
            /></Link>
          
          </div>
        </ul>

   

     

      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-1
         text-center h2ar pt-2 text-gray-400 text-sm pb-2"
      >
        <span>© 2023 MAY Design & code. All rights reserved.</span>
        <span>Terms · Privacy Policy</span>
        <div className="sm:block flex items-center justify-center w-full">
          <img
            src={footerBaymont}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
