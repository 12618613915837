import React from "react";
import AdminHeader from "../components/Admin/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import AdminDashboardMain from "../components/Admin/AdminDashboardMain";
import { useStateContext } from '../contexts/ContextProvider';
import ThemeSettingsButton from "../components/ThemeSettingsButton";
const AdminDashboardPage = () => {
const { activeMenu} = useStateContext();
  return (
  <><ThemeSettingsButton/>
        <div className="flex justify-between w-full">
        {activeMenu ? (
            <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white">
               <AdminSideBar   />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <AdminSideBar  />
            </div>
          )}   <div className={
            activeMenu
              ? 'dark:bg-main-dark-bg items-start bg-main-bg min-h-screen lg:ltr:ml-72 lg:rtl:mr-72 w-full  '
              : 'bg-main-bg dark:bg-main-dark-bg items-start w-full min-h-screen  '
          }
        >
     <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
        <AdminHeader /></div>
            <div className="w-full justify-center flex">
                <AdminDashboardMain />
            </div></div>
          </div>
    </>
  )
}



export default AdminDashboardPage;