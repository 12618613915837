import React, { useState } from "react";

import BottomNav from "../components/Layout/BottomNav";

import Header from "../components/Layout/Header";

import ProfileSideBar from "../components/Profile/ProfileSidebar";

import ProfileContent from "../components/Profile/ProfileContent";

function ProfilePage() {
  return (
    <>
      <Header />

      <div className={` flex h-screen bg-main-bg dark:bg-main-dark-bg py-10`}>
        <ProfileContent />
      </div>

      <BottomNav className="mt-2" mobilactiveHeading={4} />
    </>
  );
}

export default ProfilePage;
