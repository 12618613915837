import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";

const Ratings = ({ rating }) => {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= rating) {
      stars.push(
        <AiFillStar
          key={i}
          color="rgb(253 224 71)"
        
          className="ltr:mr-1 rtl:ml-1 h-5 w-5 text-yellow-300 cursor-pointer"
        />
      );
    } else if (i === Math.ceil(rating) && !Number.isInteger(rating)) {
      stars.push(
        <BsStarHalf 
  
          key={i}
          color="rgb(253 224 71)"
      
          className="ltr:mr-1 rtl:ml-1 h-5 w-5 text-yellow-300 cursor-pointer"
        />
      );
    } else {
      stars.push(
        <AiOutlineStar
          key={i}
          color="rgb(253 224 71)"

          className="ltr:mr-1 rtl:ml-1 h-5 w-5 text-yellow-300 cursor-pointer"
        />
      );
    }
  }
  return <div className="flex"> {stars}</div>;
};

export default Ratings;
/*h-5 w-5 text-yellow-300* */