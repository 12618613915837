import axios from "axios";
import { server } from "../../server";

// create product
export const createheroImage = (newForm) => async (dispatch) => {
  try {
    dispatch({
      type: "heroImageCreateRequest",
    });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${server}/heroImage/create-heroImage`,
      newForm,
      config
    );
    dispatch({
      type: "heroImageCreateSuccess",
      payload: data.heroImage,
    });
  } catch (error) {
    dispatch({
      type: "heroImageCreateFail",
      payload: error.response.data.message,
    });
  }
};
// delete product of a shop
export const deleteHeroImage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "deleteHeroImageRequest",
    });
    console.log("id", id);
    const { data } = await axios.delete(
      `${server}/heroImage/delete-heroImages/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: "deleteHeroImageSuccess",
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: "deleteHeroImageFailed",
      payload: error.response.data.message,
    });
  }
};

// get all heroImagee
export const getAllHeroImage = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllHeroImageRequest",
    });

    const { data } = await axios.get(`${server}/heroImage/get-AllHeroImages`);
    dispatch({
      type: "getAllHeroImageSuccess",
      payload: data.heroImages,
    });
  } catch (error) {
    dispatch({
      type: "getAllHeroImageFailed",
      payload: error.response.data.message,
    });
  }
};
export const getHeroImage = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getHeroImageRequest",
    });
    console.log("id", id);
    const { data } = await axios.get(
      `${server}/heroImage/get-heroImage/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch({
      type: "getHeroImageSuccess",
      payload: data.heroImage,
    });
  } catch (error) {
    dispatch({
      type: "getHeroImageFailed",
      payload: error.response.data.message,
    });
  }
};
export const HeroImageCreateClear = () => async (dispatch) => {
  dispatch({
    type: "HeroImageCreateCleare",
  });
};
