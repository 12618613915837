import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Route/ProductCard/ProductCard";
import styles from "../styles/styles";
import { useTranslation } from "react-i18next";
import { server } from "../server";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../components/Pagination";
import { filterProducts } from "../redux/actions/product";

import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import BottomNav from "../components/Layout/BottomNav";
import { AiOutlineSearch } from "react-icons/ai";
import Ratings from "../components/Products/Ratings";

const ProductsPage = () => {
  const {
    currentRatings,
    setRatings,
    currentCategory,
    setCategory,
    currentDir,
  } = useStateContext();
  const [pageSize, setPageSize] = useState(10);
  const { filteredProducts, isLoading, totalPages } = useSelector(
    (state) => state.products
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const { categories } = useSelector((state) => state.categories);

  const [name, setName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [resetButtonClicked, setResetButtonClicked] = useState(false);

  useEffect(() => {
    if (resetButtonClicked) {
      setCategory("");
      setMinPrice("");
      setMaxPrice("");
      setName("");
      setRatings("");
      dispatch(filterProducts("", "", "", "", "", 0, 10));
      setResetButtonClicked(false);
    }
  }, [resetButtonClicked]);

  useEffect(() => {
    dispatch(
      filterProducts(
        name,
        currentRatings,
        currentCategory,
        minPrice,
        maxPrice,
        pageNumber,
        pageSize
      )
    );
  }, [pageNumber, pageSize]);
  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleFilter = (e) => {
    e.preventDefault();
    dispatch(
      filterProducts(
        name,
        currentRatings,
        currentCategory,
        minPrice,
        maxPrice,
        "0",
        10
      )
    );
  };
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen); // تغيير حالة القائمة عند النقر
  };
  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(0);
    } else {
      setActiveTab(tab);
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setCategory("");
    setMinPrice("");
    setMaxPrice("");
    setName("");
    setRatings("");
    dispatch(
      filterProducts(
        name,
        currentRatings,
        currentCategory,
        minPrice,
        maxPrice,
        pageNumber,
        pageSize
      )
    );
    setResetButtonClicked(true);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className=" bg-main-bg dark:bg-main-dark-bg">
          <Header activeHeading={3} />
          <br />
          <br />
          <div className="flex-col items-center justify-center p-4 z-10">
            <button
              className="text-white bg-pink-700 hover:bg-pink-800 focus:ring-4 focus:outline-none focus:ring-pink-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-pink-600 h0ar dark:hover:bg-pink-700 dark:focus:ring-pink-800"
              type="button"
              onClick={handleDropdownToggle}
            >
              {t("products Filter")}
              <span>
                {isDropdownOpen ? (
                  <RiArrowDropDownLine className="text-white" size={22} />
                ) : (
                  <RiArrowDropUpLine size={22} className="text-white " />
                )}
              </span>
            </button>

            {/* Dropdown menu */}
            {isDropdownOpen && (
              <div className="z-24 w-56 lg:w-[25%]  p-3 bg-white h1ar  rounded-lg shadow dark:bg-secondary-dark-bg">
                <div className="w-full relative">
                  <input
                    type="text"
                    placeholder={t("Search Product...")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="h-[40px] w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                  />

                  <AiOutlineSearch
                    size={30}
                    className="absolute rtl:left-2 ltr:right-2 top-1.5 cursor-pointer"
                  />
                </div>

                <div className=" border-gray-200 py-2">
                  <button
                    className="flex dark:bg-black rounded-sm flex bg-gray-100/2 border-b px-2  items-center justify-between w-full"
                    onClick={() => toggleTab(1)}
                  >
                    <span className=" text-sm font-medium h1ar text-gray-900 dark:text-white">
                      {t("Category")}
                    </span>
                    {activeTab === 1 ? (
                      <RiArrowDropDownLine
                        className="text-[#b91a5c] "
                        size={25}
                      />
                    ) : (
                      <RiArrowDropUpLine
                        className=" text-[#b91a5c]"
                        size={25}
                      />
                    )}
                  </button>
                  {activeTab === 1 && (
                    <div className="mt-3">
                      <ul className="space-y-2 text-sm">
                        {categories &&
                          categories.map((index) => {
                            return (
                              <li
                                className="flex items-center me-4"
                                key={index._id}
                              >
                                <input
                                  type="radio"
                                  name="Category"
                                  checked={currentCategory === index.titleEn}
                                  className="w-4 h-4 text-pink-600 bg-gray-100 border-gray-300 accent-pink-600 rounded-md dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  value={index.titleEn}
                                  onChange={() => setCategory(index.titleEn)}
                                />{" "}
                                <label
                                  htmlFor="default-checkbox"
                                  className="ms-2 text-sm font-medium text-gray-900 h1ar dark:text-gray-300"
                                >
                                  {currentDir == "ltr"
                                    ? index.titleEn
                                    : index.titleAr}
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  )}
                </div>

                <div className=" border-gray-200 pb-2">
                  <button
                    className="flex x dark:bg-black rounded-sm 
              flex bg-gray-100/2 border-b px-2  items-center h1ar justify-between w-full"
                    onClick={() => toggleTab(2)}
                  >
                    <span className=" text-sm font-medium h1ar text-gray-900 dark:text-white">
                      {t("Price")}
                    </span>
                    {activeTab === 2 ? (
                      <RiArrowDropDownLine
                        className="text-pink-600 "
                        size={25}
                      />
                    ) : (
                      <RiArrowDropUpLine className=" text-pink-600" size={25} />
                    )}
                  </button>
                  {activeTab === 2 && (
                    <div className="flex  gap-1">
                      <div className="w-[50%]  relative">
                        <label className="py-1 dark:text-gray-400  text-sm h2ar ">
                          {t("Min Price")}
                        </label>
                        <input
                          type="number"
                          placeholder={t("50 SAR")}
                          value={minPrice}
                          onChange={(e) => setMinPrice(e.target.value)}
                          className="h-[35px] w-full px-2 border border-gray-300 rounded-md shadow-sm h1ar placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                        />
                      </div>
                      <div className="w-[50%] relative">
                        <label className="py-1 dark:text-gray-400  text-sm h2ar ">
                          {t("MAX Price")}
                        </label>
                        <input
                          type="number"
                          placeholder={t("10000 SAR")}
                          value={maxPrice}
                          onChange={(e) => setMaxPrice(e.target.value)}
                          className="h-[35px] h1ar w-full px-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-[#b91a5c] focus:border-[#b91a5c] sm:text-sm"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="border-gray-200 pb-2">
                  <button
                    className="flex flex x dark:bg-black rounded-sm h1ar flex bg-gray-100/2  px-2  items-center justify-between w-full"
                    onClick={() => toggleTab(3)}
                  >
                    <span className=" text-sm font-medium h1ar text-gray-900 dark:text-white">
                      {t("Rating")}
                    </span>
                    {activeTab === 3 ? (
                      <RiArrowDropDownLine
                        className="text-pink-600 "
                        size={25}
                      />
                    ) : (
                      <RiArrowDropUpLine className=" text-pink-600" size={25} />
                    )}
                  </button>
                  {activeTab === 3 && (
                    <div className="flex-col gap-2">
                      <div className="w-full flex flex-row  px-3 py-2">
                        <input
                          type="radio"
                          name="ratings"
                          checked={currentRatings === 5}
                          value=""
                          class="w-4 accent-pink-600 h-4 text-pink-600 bg-gray-100 border-gray-300  dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          value={5}
                          onChange={() => setRatings(5)}
                        />
                        <label for="default-radio-1">
                          <Ratings rating={5} />
                        </label>
                      </div>

                      <div className="w-full flex flex-row  px-3 py-2">
                        <input
                          id="default-radio-1"
                          checked={currentRatings === 4}
                          name="ratings"
                          type="radio"
                          value=""
                          className="w-4 accent-pink-600 h-4 text-pink-600 bg-gray-100 border-gray-300  dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          value={4}
                          onChange={() => setRatings(4)}
                        />
                        <label for="default-radio-1" className="">
                          <Ratings rating={4} />
                        </label>
                      </div>

                      <div className="w-full flex flex-row  px-3 py-2">
                        <input
                          id="default-radio-1"
                          type="radio"
                          value=""
                          name="ratings"
                          checked={currentRatings === 3}
                          class="w-4 accent-pink-600 h-4 text-pink-600 bg-gray-100 border-gray-300 focus:ring-pink-500 dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2  dark:bg-gray-700 dark:border-gray-600"
                          value={3}
                          onChange={() => setRatings(3)}
                        />
                        <label for="default-radio-1" className="">
                          <Ratings rating={3} />
                        </label>
                      </div>

                      <div className="w-full flex flex-row  px-3 py-2">
                        <input
                          id="default-radio-1"
                          type="radio"
                          name="ratings"
                          checked={currentRatings === 2}
                          className="w-4 accent-pink-600 h-4 text-pink-600 bg-gray-100 border-gray-300  dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          value={2}
                          onChange={() => setRatings(2)}
                        />
                        <label for="default-radio-1" className="">
                          <Ratings rating={2} />
                        </label>
                      </div>

                      <div className="w-full flex flex-row  px-3 py-2">
                        <input
                          id="default-radio-1"
                          type="radio"
                          name="ratings"
                          checked={currentRatings === 1}
                          class="w-4 accent-pink-600 h-4 text-pink-600 bg-gray-100 border-gray-300 dark:focus:ring-pink-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          value={1}
                          onChange={() => setRatings(1)}
                        />
                        <label for="default-radio-1" className="">
                          <Ratings rating={1} />
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex pt-4 flex-row gap-2">
                  <button
                    type="button"
                    className="text-white h1ar bg-gradient-to-r from-pink-600 via-pink-700 to-[#b91a5c] hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    onClick={handleFilter}
                  >
                    {t("Filter")}
                  </button>
                  <button
                    type="button"
                    className="text-white bg-gradient-to-r from-purple-500 via-purple-600 h1ar to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    onClick={handleReset}
                  >
                    {t("Reset")}
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className={`${styles.section}`}>
            <div className="grid grid-cols-2 gap-2 md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {filteredProducts &&
                filteredProducts.map((i, index) => (
                  <ProductCard data={i} key={index} />
                ))}
            </div>
            {filteredProducts && filteredProducts.length === 0 ? (
              <h1 className="text-center dark:text-gray-200  w-full pb-[100px] text-[20px]">
                {t("No products Found!")}
              </h1>
            ) : null}
            <Pagination
              currentPage={pageNumber}
              totalPages={totalPages}
              setPageNumber={setPageNumber}
            />
          </div>
          <Footer />
        </div>
      )}
      <BottomNav mobilactiveHeading={2} />
    </>
  );
};

export default ProductsPage;
