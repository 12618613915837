import React, { useEffect } from "react";

import { AiOutlineGift, AiOutlineMenu } from "react-icons/ai";

import { MdKeyboardArrowDown, MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
import { backend_url } from "../../../server";
import logo from "../../../Assests/images/Revo One.svg";
import { useStateContext } from "../../../contexts/ContextProvider";
import { BsChatLeft } from "react-icons/bs";
import AdminProfile from "../AdminProfile";
import Chat from "../../Chat";
   const NavButton = ({ title, customFunc, icon, color, dotColor }) => (

  <div content={title} position="BottomCenter">

    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </div>
);
   
   
   
   
   
   
   const NavItem = ({ title, customFunc, icon, color, dotColor }) => (

  <div content={title} position="BottomCenter">

    <button
      type="button"
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </div>
);
   
   
   
   
   function AdminHeader() {
     const { user, error, successMessage } = useSelector((state) => state.user);
   const { currentColor, activeMenu, setActiveMenu, handleClick, isClicked, setScreenSize, screenSize } = useStateContext();



    useEffect(() => {
      const handleResize = () => setScreenSize(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    useEffect(() => {
      if (screenSize <= 900) {
        setActiveMenu(false);
      } else {
        setActiveMenu(true);
      }
    }, [screenSize]);
  
    const handleActiveMenu = () => setActiveMenu(!activeMenu);
    return (

    <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">

    <div className="flex justify-between p-2 md:ml-6 md:mr-6 relative">

      <div className="flex">
     
        <NavButton title="Menu" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu size={25}/>} />
      </div>
  
      <div className="flex items-center">
        <div className="flex items-center mr-4">

          <Link to="/admin-events" className="800px:block hidden">
          <NavItem title="events"  color={currentColor} icon={ <MdOutlineLocalOffer size={25}/>} />
            
          </Link>
          <Link to="/admin-product" className="800px:block hidden">
          <NavItem title="products"  color={currentColor} icon={ <FiShoppingBag size={25}/>} />
            
          </Link>
          <Link to="/admin-orders" className="800px:block hidden">
          <NavItem title="orders"  color={currentColor} icon={ <FiPackage size={25}/>} /> 
          </Link>
          <div className="800px:block hidden">
          <NavButton title="Chat" dotColor="#03C9D7" customFunc={() => handleClick('chat')} color={currentColor} icon={<BsChatLeft />} />
        
          </div>
          <div content="Profile" position="BottomCenter">
          <div
            className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
            onClick={() => handleClick('userProfile')}
          >
            <img
              className="rounded-full w-8 h-8"
              src={`${backend_url}${user.avatar}`}
              alt="user-profile"
            />
            <p>
              <span className="text-gray-400 text-14">Hi,</span>{' '}
              <span className="text-gray-400 font-bold ml-1 text-14">
              {`${user.name}`}
              </span>
            </p>
            <MdKeyboardArrowDown className="text-gray-400 text-14" />
          </div>
        </div>
        </div>
      </div>
      {isClicked.chat && (<Chat />)}
      {isClicked.userProfile && (<AdminProfile isOwner={true} />)}
    </div>
    </div>
  );
};

export default AdminHeader;