import { Button } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState,useRef } from "react";
import {  AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { server } from "../../server";
import {CSVLink} from "react-csv";
import {useReactToPrint} from "react-to-print";
import { useStateContext } from "../../contexts/ContextProvider";
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
const AllEvents = () => {
  const componentPDF =useRef();
const {t}=useTranslation()
  const {currentColor,currentDir}=useStateContext();
  const [events, setEvents] = useState([]);
  useEffect(() => {
   axios.get(`${server}/event/admin-all-events`, {withCredentials: true}).then((res) =>{
    setEvents(res.data.events);
   })
  }, []);
 
  const theme = createTheme({
    direction:currentDir
    });
  const columns = [
    { field: "id", headerName: t("Product Id"), minWidth: 150, flex: 0.7 },
    {
      field: "name",
      headerName: t("Name"),
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: "price",
      headerName: t("Price"),
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: "Stock",
      headerName: t("Stock"),
      type: "number",
      minWidth: 80,
      flex: 0.5,
    },

    {
      field: "sold",
      headerName: t("Sold out"),
      type: "number",
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: t("Preview"),
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/product/${params.id}?isEvent=true`}>
            <Button className="rounded-full" 
              style={{background:currentColor,
        
                
              }}>
              <AiOutlineEye
              style={{color:"#fff"}}
              size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  events &&
    events.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        price: "RAS " + item.discountPrice,
        Stock: item.stock,
        sold: item.sold_out,
      });
    });




const genratePDF=useReactToPrint({

    content:()=>componentPDF.current,

  documentTitle:"AllEvents",
  onAfterPrint:()=>alert("Data saved in PDF")
  
  });
  return (
        <>
      <frameElement className="flex-col w-full items-center px-8 pt-14">
        <div className="flex items-center mx-8 mt-4 ">
        <button className=" rounded-md text-gray-100 py-1 px-3 mx-2"
        onClick={genratePDF}
        style={{background:currentColor}}
        > 
PDF
        </button>
        <div className=" rounded-md  text-gray-100 py-1 px-3 mx-2"
         style={{background:currentColor}}
        >
       {events?(
        <CSVLink
        data={events}
       
        >
Exel
        </CSVLink>):(null)}
        </div>
        </div>
        <br />
        <ThemeProvider theme={theme}>

<div ref={componentPDF} className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
  <DataGrid
    rows={row}
    columns={columns}
    disableSelectionOnClick
    autoHeight
    initialState={{
      ...row.initialState,
      pagination: { paginationModel: { pageSize:10 } },
    }}
    pageSizeOptions={[10, 20, 50]}
     />

</div></ThemeProvider>
        </frameElement>
    </>
  );
};

export default AllEvents;