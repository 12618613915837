import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersTypesOfShop } from "../../redux/actions/order";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useTranslation } from "react-i18next";

import { Doughnut } from "react-chartjs-2";

import { useStateContext } from "../../contexts/ContextProvider";
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
const OrdersTypesCharts = ({ shopId }) => {
  const dispatch = useDispatch();
  const {
    isLoadingType,
    DeliveredOrders,
    OtherOrders,
    ProcessingOrders,
    DeliveredPercentage,
    ProcessingPercentage,
    OtherPercentage,
  } = useSelector((state) => state.order);
  useEffect(() => {
    dispatch(getOrdersTypesOfShop(shopId));
  }, [dispatch]);
  const [lightColor, setLightColor] = useState("");
  const { currentColor, currentDir, currentMode } = useStateContext();
  const { t } = useTranslation();
  const [textColor, setTextColor] = useState("");
  useEffect(() => {
    if (currentMode === "Dark") {
      setTextColor("#d1d5db");
    } else if (currentMode === "Light") {
      setTextColor("#4b5563");
    }
  }, [currentMode]);

  return (
    <>
      {isLoadingType ? (
        <h1>loading...</h1>
      ) : (
        <div
          className="w-full 800px:w-[45%] my-2 rounded-lg dark:bg-secondary-dark-bg bg-white"
          style={{
            border: `1px solid ${currentColor}
  `,
          }}
        >
          <div className="p-3 text-white">
            <Doughnut
              className="text-white"
              data={{
                labels: [
                  `${t("Delivered Orders")}  ${DeliveredPercentage?.toFixed(
                    1
                  )}%`,
                  `${t("Processing Orders")} ${ProcessingPercentage?.toFixed(
                    1
                  )}%`,
                  `${t("Other Orders")} ${OtherPercentage?.toFixed(1)}%`,
                ],

                datasets: [
                  {
                    label: t("Number for Orders"),
                    data: [DeliveredOrders, ProcessingOrders, OtherOrders],
                    fill: true,
                    borderColor: [
                      "rgb(26, 151, 245)",
                      "rgb(255, 92, 142)",
                      "rgb(251, 150, 120)",
                    ],
                    backgroundColor: [
                      "rgb(26, 151, 245)",
                      "rgb(255, 92, 142)",
                      "rgb(251, 150, 120)",
                    ],
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    position: "top",
                    labels: {
                      color: textColor,
                    },
                  },
                  title: {
                    display: true,
                    text: t("Orders"),
                    color: textColor,
                    size: 16,
                  },
                },
              }}
            />
          </div>{" "}
        </div>
      )}
    </>
  );
};
export default OrdersTypesCharts;
