import React from 'react'
import Signup from "../components/Signup/Signup.jsx"
import { useNavigate } from 'react-router-dom';
import {useEffect} from "react";
import {useSelector} from "react-redux";



function SignupPage() {


   const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if(isAuthenticated === true){
      navigate("/");
    }
  }, [])
  return (
    <>
    <Signup/></>
  )
}

export default SignupPage
