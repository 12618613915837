import { createReducer } from "@reduxjs/toolkit";

const initialState = {
 isLoading : true,
 isAdmin:false,
};
export const adminReducer = createReducer(initialState, {
  LoadAdminRequest: (state) => {
    state.isLoading = true;
    state.isAdmin = false;
  },
  LoadAdminSuccess: (state, action) => {
    state.isLoading = false;
    state.isAdmin = true;
    state.admin = action.payload;
  },
  LoadAdminFail: (state, action) => {
    state.isLoading = false;
    state.isAdmin = true;
    state.error = action.payload;
  },
clearMessages: (state) => {
    state.successMessage = null;
  },
});
